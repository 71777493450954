html,
body {
  height: 100%;
  /* overflow: hidden; */
  position: relative;
}

/* .page-content-full-height {
  overflow-x: hidden;
  overflow-y: scroll;
} */

.test {
  width: 100px;
  height: 100px;
  background: red;
}

.test.active {
  width: 300px;
}
.font-15 {
  font-size: 15px;
}
.t3-loading-logo {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--base-bg) !important;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.t3-loading-logo img {
  margin: auto;
  width: 25%;
  animation: fadeIns 1.5s linear infinite;
}

.default-loading-logo {
  display: flex;
  /* position: fixed;
  top: 0;
  left: 0; */
  background: var(--base-bg) !important;
  /* width: 100%;
  height: 100%;
  z-index: 1; */
}

.default-loading-logo img {
  margin: auto;
  width: 25%;
  animation: fadeIns 1.5s linear infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIns {
  animation-name: fadeIns;
}

@keyframes fadeIns {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}

.t3-primary-text-color {
  color: var(--base-highlight);
}

.t3-primary-text-color-p {
  color: #98a7b599;
}

.t3-primary-text-color-yellow {
  color: #facf20;
}

.t3-primary-bg-color {
  background: var(--base-main-button);
}

.cursor-pointer {
  cursor: pointer;
}

.t3-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background: var(--nav-header-bg);
  position: fixed;
  z-index: 3;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 60px;
  /* border-bottom: 1px solid rgba(62, 72, 79, 0.3); */
  z-index: 888;
}

.t3-header > .first {
  display: flex;
  align-items: center;
  column-gap: 20px;
  width: auto;
}

.t3-header > .first.active {
  width: 240px;
}

.t3-header > .first .t3-logo {
  width: auto;
  display: block;
  height: 35px;
  cursor: pointer;
}

.t3-header > .first .t3-logo.active {
  width: auto;
  display: block;
  height: 35px;
}

.t3-header > .first .burger svg {
  color: var(--nav-hamburger);
  fill: var(--nav-hamburger)
}

.t3-header > .second {
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-weight: bold;
}

.t3-header > .second > div {
  cursor: pointer;
}

.t3-header > .second > .burger {
  padding: 8px;
  /* background-color: var(--nav-controls-bg); */
  background-color: transparent;
  border-radius: 50%;
}

.t3-header > .second .t3-header-login {
  /* box-shadow: 0 4px 8px rgba(29, 34, 37, 0.1); */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
  color: var(--nav-login-color);
  border-radius: 8px;
  background-color: var(--nav-login-btn);
  transition: all 0.2s;
}

.t3-header > .second .t3-header-login:hover {
  background-color: var(--nav-login-btn-hover);
}

.t3-header > .second .t3-header-register {
  box-shadow: 0 4px 8px rgba(29, 34, 37, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
  color: var(--base-main-button-color);
  border-radius: 8px;
  background: var(--base-main-button);
}

.t3-header .drop-down-language button {
  background-color: var(--nav-controls-bg) !important;
  border-radius: 0.5rem;
  border: none;
  padding: 6px 8px;
}

.t3-header .drop-down-language button img {
  margin-right: 4px;
}

.t3-header .dropdown-menu {
  background: var(--nav-controls-bg);
}

.t3-header .drop-down-language .dropdown-item {
  border-radius: 0px;
  color: white;
}

.t3-header .drop-down-language .dropdown-item:hover {
  background: var(--nav-controls-active) !important;
}

.t3-content-container {
  display: flex;
  /* column-gap: 20px; */
  padding-top: 60px;
  min-height: 100vh;
}

.t3-side-menu {
  position: fixed;
  left: 0px;
  top: 58px;
  z-index: 2;
  height: 100vh;
  background-color: var(--nav-side-bg);
  overflow: scroll;
  padding-bottom: 150px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.t3-side-menu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.t3-side-menu {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.t3-side-menu .left {
  width: 68px;
  position: relative;
  transition: all 0.2s;
}

.t3-side-menu .left.active {
  width: 220px;
}

.t3-side-menu .left:before {
  background-image: linear-gradient(180deg, #333, rgba(17, 20, 21, 0));
  content: " ";
  height: 12px;
  left: 0;
  opacity: 0.2;
  position: absolute;
  top: 0;
  width: 100%;
}

.t3-side-menu .left .t3-sidemenu-box .t3-sidemenu-item .first {
  width: 32px;
  /* max-width: 32px;
    min-width: 32px; */
  transition: all 0.2s;
}

.t3-side-menu .left.active .t3-sidemenu-box .t3-sidemenu-item .first {
  width: 30px;
  /* max-width: 40px;
    min-width: 40px; */
}

.t3-side-menu .left .t3-sidemenu-box .t3-sidemenu-item.sidemenu-nav-spin-wheel-bonus .first {
  width: 47px;
}

.t3-side-menu .left.active .t3-sidemenu-box .t3-sidemenu-item.sidemenu-nav-spin-wheel-bonus .first {
  width: 45px;
}

.t3-side-menu .left .t3-sidemenu-box .t3-sidemenu-item.sidemenu-nav-banner-apk .first {
  width: 47px;
}

.t3-side-menu .left.active .t3-sidemenu-box .t3-sidemenu-item.sidemenu-nav-banner-apk .first {
  width: 45px;
}

.t3-content-container .left {
  width: 82px;
  position: relative;
}

.t3-content-container .left.active {
  width: 220px;
}

.t3-sidemenu-box .t3-sidemenu-item {
  display: flex;
  align-items: center;
  column-gap: 10px;
  min-height: 40px;
  cursor: pointer;
  position: relative;
  height: 45px;
  padding-left: 15px;
  transition: all 0.2s;
  background: var(--nav-side-item);
  border-radius: 5px;
  margin: 0 5px;
}
.t3-sidemenu-item + .t3-sidemenu-item {
  margin-top: 5px;
}

.t3-sidemenu-box .t3-sidemenu-item.sidemenu-nav-spin-wheel-bonus {
  height:60px;
  background: var(--spin-wheel-button);
}

.t3-sidemenu-box .t3-sidemenu-item.sidemenu-nav-banner-apk {
  height:60px;
  background: var(--apk-banner-button);
}

.t3-sidemenu-box .t3-sidemenu-item.active {
  background: var(--nav-side-active);
}

.t3-sidemenu-box .t3-sidemenu-item.sidemenu-nav-spin-wheel-bonus.active {
  background: var(--spin-wheel-button);
}

.t3-sidemenu-box .t3-sidemenu-item.sidemenu-nav-banner-apk.active {
  background: var(--apk-banner-button);
}


.t3-sidemenu-box {
  padding: 10px 0;
}

.t3-sidemenu-box .t3-sidemenu-item h6 {
  color: var(--nav-side-color);
  font-size: 14px;
  font-weight: 600;
  width: 0px;
  opacity: 0;
  margin: 0;
  transition: all 0.2s;
}

.t3-sidemenu-box .t3-sidemenu-item.sidemenu-nav-spin-wheel-bonus h6 {
  color: var(--spin-wheel-time-color);
}

.t3-sidemenu-box .t3-sidemenu-item.sidemenu-nav-banner-apk h6 {
  color: var(--apk-banner-color);
}

/* .t3-sidemenu-box .t3-sidemenu-item.t3-s-5 h6 {
  color: #5ddb1c;
  font-size: 15px;
  font-weight: 500;
}

.t3-sidemenu-box .t3-sidemenu-item.t3-s-7 h6 {
  color: #eb5d0f;
  font-size: 15px;
  font-weight: 500;
}

.t3-sidemenu-box .t3-sidemenu-item.t3-s-8 h6 {
  color: #5ddb1c;
  font-size: 15px;
  font-weight: 500;
} */

/* .t3-sidemenu-box .t3-sidemenu-item:hover h6 {
  color: #fff;
} */

/* .t3-sidemenu-box .t3-sidemenu-item:hover:after {
  content: " ";
  height: 100%;
  width: 2px;
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: #45ff8b;
} */
.t3-sidemenu-box .t3-sidemenu-item:hover {
  background: var(--nav-side-active);
}

.t3-sidemenu-box .t3-sidemenu-item.sidemenu-nav-spin-wheel-bonus:hover {
  background: var(--spin-wheel-button);
}

.t3-sidemenu-box .t3-sidemenu-item.sidemenu-nav-banner-apk:hover {
  background: var(--apk-banner-button);
}


.t3-side-menu .left.active .t3-sidemenu-box .t3-sidemenu-item h6 {
  width: 130px;
  opacity: 1;
}

.t3-side-menu-container {
  border-bottom: var(--nav-side-border);
  border-top: var(--nav-side-border);
  padding: 15px 0 20px;
  margin: 0;
}

.t3-side-menu-container h5 {
  font-size: 14px;
  font-weight: 400;
  width: 0px;
  opacity: 0;
  height: 0px;
  color:
}

.t3-side-menu-container.active h5 {
  width: 100%;
  opacity: 1;
  height: auto;
  margin-bottom: 12px;
}

.t3-side-menu-container.active .t3-sm-box {
  opacity: 0;
  width: 0px;
  height: 0px;
}

.t3-side-menu-container .t3-sm-box {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding-left: 25px;
  opacity: 1;
  width: 100%;
  height: auto;
}

.t3-side-menu-container .t3-sm-box-active {
  opacity: 0;
  width: 0px;
  height: 0px;
}

.t3-side-menu-container.active .t3-sm-box-active {
  display: flex;
  column-gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  flex-direction: unset;
  opacity: 1;
  width: 100%;
  height: auto;
  /* -webkit-animation: fadeIn 12s;
  animation: fadeIn 12s; */
}

.t3-side-menu-container .t3-chatbox-btn {
  cursor: pointer;
  border-radius: 50px;
  position: relative;
  transition: all 0.2s;
}

.t3-side-menu-container .t3-chatbox-active {
  display: flex;
  column-gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  flex-direction: unset;
  /* opacity: 1;
  width: 100%;
  height: auto; */
  /* -webkit-animation: fadeIn 12s;
  animation: fadeIn 12s; */
}

.t3-side-menu-container.active .t3-chat-label{
  font-size: 14px;
  margin-bottom: 10px;
}

.t3-side-menu-container .t3-chat-label{
  font-size: 12px;
  margin-bottom: 8px;
}

.t3-side-menu-container svg.bg-svg-chat{
  opacity: 0;
  width: 0px;
  height: 0px; 
}

.t3-side-menu-container.active svg.bg-svg-chat{
  opacity: 1;
  width: 100%;
  height: auto;
}

.t3-side-menu-container.active svg.sm-svg-chat{
  opacity: 0;
  width: 0px;
  height: 0px; 
}

.t3-side-menu-container svg.sm-svg-chat{
  opacity: 0;
  width: 100%;
  height: auto;
}
.t3-side-menu-container svg.bg-svg-chat{
  opacity: 0;
  width: 0px;
  height: 0px; 
}

.t3-side-menu-container > div .first {
  width: 22px;
  min-width: 22px;
  max-width: 22px;
}

.t3-side-menu-container.active > div .first {
  width: 28px;
  min-width: 28px;
  max-width: 28px;
}

.t3-side-menu-container > div .t3-sm-item {
  cursor: pointer;
  margin: 0 2px;
}

.t3-content-container .right {
  width: calc(100% - 102px);
  /* height: 3000px;
    background: #333; */
  /* padding-right: 10px;
  padding-left: 10px; */
  padding-top: 12px;
  max-width: 1430px;
  margin: 0 auto;
  transition: all 0.2s;
}

.t3-content-container .right.active {
  width: calc(100% - 280px);
}

.t3-dashboard-slick {
  overflow: hidden;
  padding-top: 10px;
  margin-top: -10px;
}

.t3-dashboard-banner {
  /* padding: 0px 10px; */
  position: relative;
  margin: 0 -7px;
  padding-top: 10px;
  margin-top: -10px;
}

.t3-dashboard-banner .banner-items {
  padding: 0px 7px;
  cursor: pointer;
  outline: none;
}

.t3-dashboard-banner .banner-items img {
  border: 1px solid transparent;
  outline: none;
  border-radius: 8px;
  transition: all 0.2s;
}

@media screen and (max-width: 480px) {
  .t3-dashboard-banner .banner-items img {
    padding: 0;
  }
}

.t3-dashboard-banner .banner-items:hover img {
  /* border: 1px solid var(--base-highlight); */
  transform: translateY(-5px);
}

.t3-dashboard-banner .slick-prev {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;
  display: flex !important;
  /* width: 100%; */
  align-items: center;
  justify-content: start;
}

.t3-dashboard-banner .slick-next {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;
  display: flex !important;
  /* width: 100%; */
  align-items: center;
  justify-content: end;
}

.t3-dashboard-banner .slick-prev:before,
.t3-dashboard-banner .slick-next:before {
  font-size: 34px;
  line-height: 20px;
  color: #45ff8b;
}

.t3-announcement-container {
  display: flex;
  align-items: center;
  column-gap: 7px;
  background: var(--announcement-bg) ;
  padding: 8px 0px 8px 12px;
  border-radius: 8px;
}

@media (max-width: 900px) {
  .t3-announcement-container {
    padding-left: 0;
    column-gap: 0;
  }
}

.t3-announcement-container .first {
  width: 28px;
}

.t3-announcement-container .second {
  /* max-width: 500px; */
  width: 100%;
  display: flex;
}

.t3-categories-img {
  width: 100%;
  border-radius: 12px;
}

.t3-game-category-list {
  background: var(--game-category-tab-bg);
  border-radius: 8px;
  display: flex;
  overflow: hidden;
  padding: 3px 2px;
}

.t3-game-category-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
  display: none;
}

.t3-game-category-list::-webkit-scrollbar {
  height: 4px;
  background-color: transparent;
  display: none;
}

.t3-game-category-list::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* background-color: #45ff8b; */
  background-color: transparent;
  display: none;
}

.t3-game-category-list .t3-game-category-item {
  display: flex;
  align-items: center;
  column-gap: 5px;
  padding: 6px 15px;
  cursor: pointer;
  margin: 0 2px;
}

.t3-game-category-list .t3-game-category-item h6 {
  color: var(--base-color);
  font-size: 0.875rem;
  font-weight: bold;
  white-space: nowrap;
  margin: 0;
}

.t3-game-category-list .t3-game-category-item.active h6 {
  color: var(--game-category-tab-active-color);
  /* font-weight: bold; */
}

.t3-game-category-list .t3-game-category-item .first {
  width: 34px;
}

.t3-game-category-list .t3-game-category-item.active .first {
  opacity: 1;
}

.t3-game-category-list .t3-game-category-item:hover .first {
  opacity: 1;
}

.t3-game-category-list .t3-game-category-item:hover {
  background: var(--game-category-tab-hover);
  border-radius: 8px;
}

@media (max-width: 991px) {
  .t3-game-category-list .t3-game-category-item:hover{
    background: transparent;
  }
}

.t3-game-category-list .t3-game-category-item.active {
  background: var(--game-category-tab-active);
  border-radius: 8px;
}

.t3-game-category-box {
  display: flex;
  justify-content: flex-end;
}

.t3-custom-title, .t3-custom-second-title {
  font-size: 1.25rem;
  font-weight: bold;
  font-style: italic;
  margin-bottom: 0px;
}
.t3-custom-second-title{
  color: var(--payout-title)
}

.t3-custom-title span {
  color: var(--game-title-highlight-color);
}
.t3-custom-second-title span {
  color: var(--payout-highlight);
}

.t3-category-game-list-container {
  /* background: #282828; */
  padding: 20 0px;
}

.t3-category-game-list-container .t3-game-list-box {
  display: grid !important;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 15px;
  text-align: left;
  /* grid-auto-rows: 1fr; */
}

@media (max-width: 1279px) {
  .t3-category-game-list-container .t3-game-list-box {
    grid-template-columns: repeat(5, 1fr);
  } 
}

@media (max-width: 1023px) {
  .t3-category-game-list-container .t3-game-list-box {
    grid-template-columns: repeat(4, 1fr);
  } 
}

@media (max-width: 600px) {
  .t3-category-game-list-container .t3-game-list-box {
    grid-template-columns: repeat(3, 1fr);
  }
}

.t3-slot-games-box .t3-game-list-box {
  display: grid !important;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 15px;
  text-align: left;
  /* grid-auto-rows: 240px; */
  padding: 1px;
}

@media (max-width: 1279px) {
  .t3-slot-games-box .t3-game-list-box {
    grid-template-columns: repeat(5, 1fr);
  } 
}

@media (max-width: 1023px) {
  .t3-slot-games-box .t3-game-list-box {
    grid-template-columns: repeat(4, 1fr);
  } 
}

@media (max-width: 600px) {
  .t3-slot-games-box .t3-game-list-box {
    grid-template-columns: repeat(3, 1fr);
  }
}

.t3-game-list-box {
  display: grid !important;
  grid-template-columns: repeat(6, minmax(15%, 1fr));
  grid-gap: 15px;
  text-align: left;
  position: relative;
  /* grid-auto-rows: 1fr; */
}

.t3-game-list-item {
  cursor: pointer;
  min-height: 147px;
}

.t3-game-list-item h6 {
  font-size: 1rem;
  margin-top: 8px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 600px) {
  .t3-game-list-item h6 {
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: 5px;
  }
}

.t3-game-list-item .t3-game-list-provider-box {
  width: 65px;
}

.t3-game-list-box .t3-game-list-image-box {
  border: var(--game-item-border);
  border-radius: 12px;
  overflow: hidden;
  /* min-height: 175px;
  height: 80%; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: calc(100% - 31px);
  min-height: calc(100% - 31px); */
  transition: all 0.2s;
  aspect-ratio: 1/1;
  max-width: 200px;
  max-height: 200px;
}

.t3-game-list-box .t3-game-list-image-box.um-box {
  
}

.t3-game-list-box .t3-game-list-image-box .image,
.game-detail-description-container .game-detail-image .image {
  position: relative;
  height: 100%;
  width: 100%;
}

.t3-game-list-box .t3-game-list-image-box .image .first,
.game-detail-description-container .game-detail-image .image .first,
.game-mobile-view-info-container .game-detail-image .image .first {
  height: 100%;
  width: 100%;
  transition: all 0.2s;
}

.t3-game-list-box .t3-game-list-image-box .image .first img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.t3-game-list-box .t3-game-list-image-box .image .second {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  flex-direction: column;
  transition: all 0.2s;
}

.t3-game-list-box .t3-game-list-image-box .image .header-provider {
  width: 25%;
  position: absolute;
  height: auto !important;
  top: 0;
  right: 0;
  border-radius: 0 !important;
}

.t3-game-list-box .t3-game-list-image-box:hover .image .first {
  transform: scale(1.05);
  opacity: 0.7;
}

.t3-game-list-box .t3-game-list-image-box:hover .image .second {
  opacity: 1;
}

.t3-game-list-box .t3-game-list-image-box .image .second svg {
  width: 52px;
  transform: scale(1.3);
  transition: all 0.2s;
}

.t3-game-list-box .t3-game-list-image-box:hover .image .second svg {
  transform: scale(1);
}

.t3-game-list-title-with-arrows-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.t3-custom-slick-arrow {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.t3-custom-slick-arrow > div {
  width: 32px;
  height: 32px;
  background: var(--game-category-tab-bg);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.t3-custom-slick-arrow > div:hover {
  background: var(--game-category-tab-hover);
}

.t3-custom-slick-arrow > div svg {
  color: var(--game-title-highlight-color);
}

.t3-custom-slick-arrow > div:hover svg {
  color: #424242;
}

.t3-game-list-title-with-arrows-box .second {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.t3-game-item-count-box {
  background: var(--game-wallet-info-bg);
  padding: 8px 15px;
  border-radius: 8px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
}

.t3-game-item-count-box:hover {
  color: #fff;
}

.t3-game-item-count-box span {
  color: var(--base-highlight);
}

.t3-provider-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #99a4b0cc;
}

.t3-providers-parent-box {
  /* border-radius: 8px;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  height:52px; */
  height: 65px;
}

.t3-providers-box {
  /* display: flex;
  gap: 2px;
  overflow-y: auto; */
  /* 
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows:1fr; */
}

.t3-provider-item {
  padding: 0px 15px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 900px) {
  .t3-provider-item {
    padding: 5px;
  }
}

.t3-provider-item .first {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0px 10px;
}
.t3-provider-item .first img{
  max-height: 100%;
}

@media screen and (max-width: 900px) {
  .t3-provider-item .first {
    background-color: var(--provider-icon-bg);
    border-radius: 8px;
    padding: 5px 5px;
  } 
}

.t3-providers-box img {
  width: 70%;
}

.t3-providers-box > div {
  background: #282828;
  min-width: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.t3-live-game-slick {
  margin: 0px -5px;
  padding: 5px 0;
}

.t3-live-game-slick .slick-slide {
  padding: 0px 5px;
}

.t3-slot-games-box .slick-slide {
  padding: 0px 5px;
}

.t3-gl-title {
  color: #45ff8b;
  margin-bottom: 2em;
  font-size: 14px;
  text-align: center;
}

.t3-table-header {
  display: flex;
  justify-content: space-between;
  background: var(--theme-color-bg-table-header);
  height: 50px;
  align-items: center;
  color: #99a4b0cc;
  border-radius: 12px 12px 0 0;
}

.t3-table-header span {
  flex: 1 1;
}

.t3-table-header span:first-child {
  flex: 2 1;
  padding-left: 10px;
}

.t3-table-header span:last-child {
  padding-right: 10px;
  text-align: right;
}

.t3-scroll-container .t3-scroll-item {
  background: linear-gradient(
    90deg,
    #26583b,
    #295837 9.04%,
    #2b4d34 17.64%,
    #2a4332 73.44%,
    rgb(54 73 56 / 49%)
  );
  color: #fff;
  margin-bottom: 10px;
  margin-right: 15px;
  padding: 10px 8px;
}

.t3-scroll-container .t3-scroll-item img {
  border-radius: 50%;
  margin-right: 15px;
  width: 50px;
}

.t3-scroll-container .t3-scroll-item span {
  flex: 1 1;
}

.t3-scroll-container .t3-scroll-item span:first-child {
  flex: 2 1;
}

.t3-scroll-container .t3-scroll-item span:last-child {
  text-align: right;
}

.t3-text-red {
  color: #ec3207;
}

.t3-text-green {
  color: #5ddb1c;
}

.t3-scroll-row::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}

.t3-scroll-row::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}

.t3-scroll-row::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #45ff8b;
  transition: all 0.5s;
}

.footer-container {
  padding: 0;
  background: var(--nav-footer-bg);
}

.t3-footer {
  padding: 58px 0px 88px;
  border-top: var(--nav-footer-border);
  /* padding-left: 80px; */
  color: var(--nav-footer-color);
}

.t3-navigation-footer {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(14ch,1fr));
  gap: 2rem;
  align-content: center;
  align-items: start;
  justify-items: start;
  justify-content: start;
  margin-bottom: 5rem;
}

.t3-navigation-footer .t3-footer-nav-group {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.t3-navigation-footer .t3-footer-nav-title {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 1rem;
}

.t3-navigation-footer .t3-footer-nav-item {
  color: var(--nav-footer-color);
}

.t3-navigation-footer .t3-footer-nav-item:hover {
  color: var(--nav-footer-hover-color);
}

.t3-footer .t3-top-footer {
  display: grid;
  grid-template-columns: 60% 1fr;
  width: 100%;
  grid-gap: 20px;
}

.t3-footer .t3-top-footer .first {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 10px;
}

.t3-footer .t3-top-footer .first .t3-footer-logo-item {
  width: 88px;
  min-width: 88px;
  max-width: 88px;
}

.t3-footer .t3-top-footer .second .t3-footer-sm-box {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.t3-footer .t3-top-footer .second .t3-footer-sm-box .t3-footer-sm-item > div {
  width: 32px;
  cursor: pointer;
}

.t3-footer p {
  font-weight: 500;
  margin-bottom: 0px;
  margin-top: 15px;
}

.t3-footer .t3-navigation-footer p {
  margin-top: 0;
  font-weight: 300;
  word-break: break-word;
}

.t3-footer .t3-copyright {
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  margin-top: 50px;
  color: rgba(255, 255, 255, 0.6)
}

.page-container .content {
  transform: unset;
}

.t3-bottom-nav {
  display: none;
}

.t3-mobile-side-menu-container {
  background: var(--base-bg);
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2500;
  height: 100%;
  /* padding: 20px; */
  overflow: auto;
}

.t3-m-sm-header {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  align-items: start;
  padding: 20px;
  background: var(--nav-side-mobile-header)
}

.t3-m-sm-header .t3-logo {
  width: 170px;
}

.t3-m-sm-header .t3-close-modal {
  font-weight: bold;
  font-size: 20px;
}

.t3-m-sm-header .t3-close-modal .vicon {
  color: var(--nav-side-mobile-header-color);
  width: 25px;
  height: 25px;
}
.t3-mobile-side-menu-content{
  padding: 0 20px 20px;

}
.t3-mobile-side-menu-content .t3-sidemenu-item {
  column-gap: 20px;
  /* background-image: linear-gradient(78deg, #36393f, #2d2f35); */
  background-image: var(--nav-side-mobile-item);
  border-radius: 0.35rem;
  margin: 10px 0px;
  cursor: pointer;
  padding: 0 20px;
}

.t3-mobile-side-menu-content .t3-sidemenu-item:hover {
  background-image: var(--nav-side-mobile-item-hover);
}
.t3-mobile-side-menu-content .t3-sidemenu-item:hover h6 {
  color: var(--nav-side-mobile-item-hover-color) !important;

}

.t3-mobile-side-menu-content .t3-sidemenu-item h6 {
  opacity: 1;
  color: var(--base-color) !important;
  font-size: 1.25rem !important;
  width: 100%;
}

.t3-mobile-side-menu-content .t3-sidemenu-item.sidemenu-nav-spin-wheel-bonus h6 {
  color:var(--spin-wheel-time-color) !important;
}

.t3-mobile-side-menu-content .t3-sidemenu-item.sidemenu-nav-banner-apk h6 {
  color:var(--apk-banner-color) !important;
}

.t3-mobile-side-menu-container
  .t3-mobile-side-menu-content
  .t3-sidemenu-item
  .first {
  width: 38px;
}

.t3-mobile-side-menu-container
  .t3-mobile-side-menu-content
  .t3-sidemenu-item.sidemenu-nav-spin-wheel-bonus
  .first {
  width: 48px;
}

.t3-mobile-side-menu-content .t3-sidemenu-box {
  padding-left: 0px;
  padding-top: 30px;
}

.t3-msm-social-box h5 {
  font-size: 1.25rem;
  margin: 20px 0px;
}

.t3-msm-social-box .t3-sm-box {
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;
}

.t3-msm-social-box .t3-sm-box > div {
  cursor: pointer;
}

.t3-msm-social-box .t3-sm-box .first {
  width: 40px;
}

#t3-login-register-modal.modal-dialog {
  max-width: 825px;
}

.t3-lr-modal-header,
#t3-login-register-modal .t3-lr-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-radius: 20px 20px 0px 0px;
}

.t3-lr-modal-header .t3-close-modal .vicon,
#t3-login-register-modal .t3-lr-modal-header .t3-close-modal .vicon {
  width: 20px;
  height: 20px;
  color: var(--modal-header-close);
}

#t3-login-register-modal .t3-lr-modal-header .t3-logo {
  max-width: 120px;
}

#t3-login-register-modal .modal-body {
  padding: 0px !important;
}

.t3-lr-modal-container,
#t3-login-register-modal .t3-lr-modal-container {
  /* border-radius: 20px; */
  /* overflow: hidden; */
}

#t3-login-register-modal .modal-content {
  /* background: unset !important; */
}

.t3-lr-modal-header,
#t3-login-register-modal .t3-lr-modal-header {
  background: var(--modal-header);
}

#t3-login-register-modal .t3-lr-modal-body {
  grid-gap: 10px;
  background-color: var(--modal-body);
  display: grid;
  grid-template-columns: 40% 1fr;
  padding: 30px 20px;
  border-radius: 0px 0px 10px 10px;
}

#t3-login-register-modal .t3-lr-modal-body .left .text {
  text-shadow: 1px 2px 2px #99f1d5;
  color: #45ff8b;
  font-size: 25px;
  line-height: 1.3;
  padding-left: 15px;
}

#t3-login-register-modal .t3-lr-modal-body .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#t3-login-register-modal .fail-alert {
  background: var(--modal-container);
  color:var(--base-color);
  line-height: 14px;
  margin: 0;
  position: absolute;
  width: 38%;
  bottom: 28px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.t3-input-container label {
  color: var(--base-color);
  font-size: 12px;
  font-weight: 400;
}

.t3-input-container label.required:after {
  color: #a61d24;
  content: "*";
  display: inline-block;
  font-family: SimSun, sans-serif;
  font-size: 14px;
  line-height: 1;
  margin-left: 5px;
}

#t3-login-register-modal .t3-lr-modal-body .right .t3-lr-form {
  max-width: 380px;
  margin: auto;
  height: 100%;
  overflow-y: auto;
  overscroll-behavior: contain;
  touch-action: pan-y;
  overflow: visible;
}

#t3-login-register-modal .t3-lr-modal-body .right .t3-lr-form .t3-form-title {
  position: relative;
  /* left: -34px; */
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

#t3-login-register-modal .t3-lr-modal-body .right .t3-lr-form .t3-form-title .t3-title {
  font-size: 1.4rem;
  line-height: normal;
  color: var(--base-color);
  font-weight: 600;
  /* margin-left: 1rem; */
}

#t3-login-register-modal .t3-lr-modal-body .right .t3-lr-form .t3-form-title .t3-back-btn {
  /* position: absolute;
  left: -32px; */
  cursor: pointer;
  margin-right: 10px;
}

.t3-custom-input-text {
  border-radius: 0.5rem;
  background: var(--base-input) !important;
  border: 2px none;
  outline: none;
  height: 40px;
  padding: 0px 16px;
  font-size: 14px;
  width: 100%;
  color: var(--base-input-color) !important;
  font-weight: 300;
}

.t3-custom-input-text-captcha {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    background: var(--base-input) !important;
    border: 2px none;
    outline: none;
    height: 40px;
    padding: 0px 20px;
    font-size: 14px;
    width: 100%;
    color: var(--base-input-color) !important;
    font-weight: 300;
}

.t3-custom-input-text.disabled {
  pointer-events: none;
}
.t3-custom-input-text:read-only {
  background: #aaa !important;
}

.t3-custom-input-text.date:read-only {
  background: #fff !important;
}

.t3-custom-input-text::-webkit-input-placeholder {
  /* Edge */
  color: var(--base-input-placeholder);
}

.t3-custom-input-text:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--base-input-placeholder);
}

.t3-custom-input-text::placeholder {
  color: var(--base-input-placeholder) !important;
}

.t3-input-container .vicon {
  width: 18px;
  height: 18px;
  color: var(--base-input-placeholder);
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
}

.t3-lr-button-box {
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: center;
}

.t3-custom-light-btn {
  background: var(--base-main-button);
  box-shadow: 0 4px 8px rgba(29, 34, 37, 0.1);
  color: var(--base-main-button-color) !important;
  height: 45px;
  padding: 0px 25px;
  border-radius: 8px;
  outline: unset;
  border: 0px;
  font-weight: 700;
  text-align: center;
  line-height: 15px;
}
.btn.btn-lg.btn-primary {
  background: var(--base-main-button) !important;
  box-shadow: 0 4px 8px rgba(29, 34, 37, 0.1);
  color: var(--base-main-button-color);
  height: 45px;
  padding: 0px 25px;
  border-radius: 8px;
  outline: unset;
  border: 0px;
  font-weight: 700;
  text-align: center;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.t3-custom-light-btn:disabled {
  opacity: 0.6;
}

.t3-custom-gray-btn {
  background-color: #6b7180;
  box-shadow: 0 4px 8px rgba(29, 34, 37, 0.1);
  color: #f5f6f7;
  height: 45px;
  padding: 0px 40px;
  border-radius: 8px;
  outline: unset;
  border: 0px;
  font-weight: 700;
  text-align: center;
  line-height: 15px;
}

.t3-form-notice {
  text-align: center;
  font-size: 0.9rem;
  color: #ffc159;
  margin-top: 1rem;
}

.t3-contact-number-box {
  display: flex;
  grid-column-gap: 5px;
  column-gap: 5px;
}

.t3-contact-number-box .second {
  width: 100%;
}

.t3-contact-number-box select {
  width: 80px;
  height: 40px;
  padding: 0px 14px;
  border: 0px;
  color: var(--base-input-color) !important;
  padding-right: 5px;
  background: var(--base-input);
  border: 2px none !important;
  border-radius: 0.5rem !important;
  -webkit-appearance: none;
  position: relative;
  background-image: url(../img/new_icons/angle-down-blue.png) !important;
  background-position: 90% center;
  background-repeat: no-repeat;
  outline: unset;
}

.t3-referrer-box {
  background: rgba(45, 48, 53, 0.5);
  text-align: center;
  padding: 15px;
  border-radius: 0.5rem;
}

.t3-referrer-box .first {
  font-size: 10px;
}

.t3-referrer-box .second {
  font-size: 16px;
  color: #45ff8b;
}

#t3-login-register-modal .pincode-input-text {
  background: var(--pin-number) !important;
  border: 2px none !important;
  border-radius: 5px !important;
  color: var(--pin-number-color) !important;
}

.t3-lr-pin-box {
  text-align: center;
  min-height: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.t3-lr-pin-box .pincode-input-container {
  display: inline-block;
}

.t3-pin-title2 {
  font-size: 1rem;
  font-weight: 300;
}

.t3-lr-success {
  text-align: center;
  margin-bottom: 0px;
  height: 100%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 230px;
}

.t3-close-modal {
  cursor: pointer;
}

.t3-header-balance-deposit-box {
  display: flex;
  align-items: center;
  column-gap: 8px;
  background: var(--nav-controls-bg);
  border-radius: 0.5rem;
  /* overflow: hidden; */
  padding-left: 10px;
  color: var(--nav-controls-color);
}

.t3-refresh-btn {
  width: 15px;
}

.t3-header-apk-banner {
  display: flex;
  column-gap: 5px;
  align-items: center;
  background: var(--apk-banner-button);
  color: var(--apk-banner-color);
  border-radius: 0.5rem;
  padding: 11px 10px;
}

.t3-header-apk-banner .img {
  width: 20px;
  height: 20px;
}

.t3-header-balance-deposit-box .t3-header-deposit {
  display: flex;
  column-gap: 5px;
  align-items: center;
  background: var(--base-main-button);
  box-shadow: 0 4px 8px rgba(29, 34, 37, 0.1);
  color: var(--base-main-button-color);
  border-radius: 0.5rem;
  padding: 11px 10px;
}

.t3-header-balance-deposit-box .t3-header-deposit .vicon {
  width: 20px;
  height: 20px;
  color: var(--base-main-button-color);
}

.t3-header-profile-box {
  display: flex;
  align-items: center;
  background: var(--nav-controls-bg);
  column-gap: 5px;
  /* border-radius: 50px 40px 40px 50px; */
  border-radius: 8px;
  overflow: hidden;
  /* border-top-left-radius: 100px;
    border-bottom-left-radius: 100px; */
  padding: 3px 3px 3px 15px;
  color: var(--nav-controls-color);
}

.t3-header-profile-box > .first {
  width: 36px;
  border-radius: 8px;
  overflow: hidden;
}

.t3-header-profile-box > .second {
  font-size: 12px;
  /* font-weight: 300; */
  /* line-height: 1.2; */
}

.t3-header-profile-box > .second > div:last-child span {
  color: var(--nav-controls-color);
  font-weight: 300;
}

.t3-header-profile-box > .third .vicon {
  width: 30px;
  height: 30px;
  color: #45ff8b;
  margin-right: 10px;
  margin-left: 10px;
}

.t3-settings-container {
  max-width: 600px;
  margin: auto;
  padding: 0px 0px;
}

.t3-settings-profile-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.t3-settings-profile-box > .first {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.t3-settings-profile-box > .first .image {
  width: 42px;
  border-radius: 8px;
  overflow: hidden;
}

.t3-settings-profile-box > .first .details {
  font-weight: bold;
  font-size: 15px;
}

.t3-settings-profile-box > .first .details span {
  font-weight: 500;
  color: #99a4b0cc;
  font-size: 0.75rem;
}

.t3-settings-profile-box > .first:hover .details span {
  color: #45ff8b;
}

.t3-settings-profile-box > .second {
  display: flex;
  align-items: center;
  column-gap: 7px;
  color: #99a4b0b3;
  font-size: 0.875rem;
  cursor: pointer;
}

.t3-settings-profile-box > .second:hover {
  color: #fff;
}

.t3-settings-profile-box > .second .vicon {
  width: 18px;
  height: 18px;
  color: #99a4b0b3;
}

.t3-settings-profile-box > .second:hover .vicon {
  color: #fff;
}

.t3-settings-vip-box {
  padding: 15px 15px;
  background: var(--game-wallet-info-bg);
  border-radius: 5px;
  display: flex;
  gap:20px;
}

.t3-settings-vip-box .t3-vip-user-image-box > .t3-vip-user-image img{
  max-width: 69px;
  min-width: 69px;
}

.t3-vip-info-box{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.t3-vip-detail{
  display: flex;
  align-items: center;
}
.t3-vip-detail .first{
  padding-right: 15px;
  font-size: 14px;
  font-weight: bold;
}
.t3-vip-detail .second{
  padding-left: 15px;
  border-left: 1px solid #585858;
  font-size: 12px;
}

.t3-vip-label-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}

.t3-vip-label-box > .second {
  display: flex;
  align-items: center;
  column-gap: 10px;
  text-align: right;
}

.t3-vip-label-box > .first,
.t3-vip-label-box > .second span {
  color: var(--base-color);
}

.t3-vip-label-box .deposit span {
  color: var(--base-link);
}

.t3-vip-bar {
  background: var(--setting-progress-bg);
  height: 10px;
  width: 100%;
  border-radius: 4px;
  position: relative;
}

.t3-vip-bar-item {
  position: absolute;
  height: 100%;
  background: var(--base-highlight);
  top: 0px;
  left: 0px;
  border-radius: 4px;
  max-width: 100%;
}

.t3-vip-percentage-bar {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.t3-vip-percentage-bar > .second {
  color: #99a4b0b3;
  font-size: 12px;
}
/*
.t3-settings-withdrawal-box {
  padding: 15px 15px;
  background: #333;
  border-radius: 5px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}*/

.t3-settings-withdrawal-box .first {
  grid-area: first;
}

.t3-settings-withdrawal-box .second {
  grid-area: second;
}

.t3-settings-withdrawal-box .third {
  grid-area: third;
}

.t3-settings-withdrawal-box {
  padding: 15px 15px;
  background: var(--game-wallet-info-bg);
  border-radius: 5px;
  display: grid;
  align-items: center;
  column-gap: 10px;
  grid-template-columns: repeat(2, minmax(15%, 1fr));
  grid-template-areas:
    "first second"
    "third forth";
  row-gap: 10px;
}

.t3-settings-withdrawal-box > div {
  background: var(--game-wallet-info-item-bg);
  border-radius: 5px;
  padding: 10px 15px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  column-gap: 10px;
  flex: 1 1;
  min-height: 60px;
  /*flex-direction: column;*/
  height: 100%;
}

.t3-settings-withdrawal-box > div .details {
  color: var(--base-highlight);
  font-size: 12px;
  font-weight: bold;
}

.t3-transfer-bonus.link,
.t3-settings-withdrawal-box > div .link {
  font-size: 12px;
  color: #ffaa00;
  text-decoration: underline;
  margin-top: 5px;
}

.t3-settings-withdrawal-box > div .details span {
  color: var(--base-color);
}

.t3-settings-withdrawal-box > div .t3-custom-light-btn {
  min-width: 90px;
  padding: 0px 5px;
  font-size: 14px;
}

  .t3-settings-withdrawal-box > div .t3-custom-gold-btn {
    min-width: 90px;
    padding: 0px 5px;
    font-size: 14px;
  }

.t3-settings-menu-list .t3-settings-menu-list-item {
  /* background: var(--theme-color-bg-btn-secondary); */
  background: var(--modal-container);
  border-radius: 0.35rem;
  color: var(--base-color);
  min-height: 46px;
  padding: 10px 10px 10px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  cursor: pointer;
}

.t3-settings-menu-list .t3-settings-menu-list-item.bank {
  display: block !important;

}

.t3-settings-menu-list .menu-list-item-row{
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.t3-settings-menu-list .t3-settings-menu-list-item .title {
  margin: 0;
  font-weight: 600;
  color: var(--base-color);
}

.t3-settings-menu-list .t3-settings-menu-list-item .remark {
  font-size: 12px;
  color: var(--base-input-placeholder);
  margin-top: 2px;
  display: block;
}

.t3-settings-menu-list .t3-settings-menu-list-item:last-child {
  margin-bottom: 0px;
}

.t3-settings-menu-list .t3-settings-menu-list-item:hover {
  background: var(--base-container-bg);
  /* color: var(--theme-color-text-primary); */
}

/* .t3-settings-menu-list-item:hover svg {
  color: var(--theme-color-text-primary);
} */

.t3-settings-menu-list .t3-settings-menu-list-item > .first ,
.t3-settings-menu-list .t3-settings-menu-list-item > .menu-list-item-row .first {
  display: flex;
  align-items: center;
  column-gap: 14px;
  line-height: 1;
}

.t3-settings-menu-list .t3-settings-menu-list-item .vicon {
  width: 25px;
  height: 25px;
}

.t3-settings-logout .t3-custom-light-btn .theme-icon-size-20 {
  color: var(--base-main-button-color);
}

.t3-settings-logout .t3-custom-light-btn {
  display: flex;
  align-items: center;
  column-gap: 15px;
  height: 46px;
  margin: auto;
}

#t3-custom-modal-settings .modal-content {
  background: unset;
  border: unset;
}

#t3-custom-modal-settings .modal-body {
  padding: 0px !important;
}

.t3-custom-modal-settings-container {
  background: #27282b;
  padding: 20px 15px 2em;
  border-radius: 15px;
  min-height: 70vh;
}

.t3-cms-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.t3-cms-header > .first {
  color: var(--base-highlight);
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.t3-cms-header > .first .vicon {
  color: #ffffff73;
  cursor: pointer;
}

.t3-cms-header > .first .vicon:hover {
  color: #fff;
}

.t3-close-custom-modal .vicon {
  width: 20px;
  height: 20px;
  color: #ffffff73;
  cursor: pointer;
}

.t3-close-custom-modal:hover .vicon {
  color: #fff;
}

.t3-tips-light {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.t3-tips-light div,
.t3-tips-light svg{
 color: #FF0000;
}
.t3-settings-button-box {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

.t3-settings-button-box > div > button {
  /* width: 140px; */
  padding: 0px 25px;
}

.t3-settings-ud-vip-box {
  position: absolute;
  top: -5px;
  right: 10%;
  max-width: 65px;
}

.t3-ud-profile-box {
  text-align: center;
}

.t3-profile-circle-box {
  width: 70px;
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid #464646;
  margin: auto;
}

.t3-ud-profile-box h4 {
  line-height: 1;
}

.t3-edit-profile-btn {
  cursor: pointer;
  width: 36px;
  height: 36px;
  background: var(--base-container-body-bg);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
}

.t3-edit-profile-btn .vicon {
  color: #fff;
}

.t3-ud-statistic-box {
  background: #333333;
  border-radius: 1.25rem;
  padding: 20px;
}

.t3-ud-statistic-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.t3-ud-statistic-header > div {
  display: flex;
  align-items: center;
  column-gap: 10px;
  line-height: 1;
}

.t3-ud-statistic-header > .first {
  color: #99a4b099;
  font-size: 13px;
}

.t3-ud-statistic-header > div .vicon {
  color: #99a4b099;
}

.t3-ud-statistic-header > .second {
  color: #45ff8b;
}

.t3-ud-statistic-body {
  display: flex;
  /* align-items: center; */
  column-gap: 10px;
}

.t3-ud-statistic-body .t3-ud-statistic-body-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
  text-align: center;
  background: #18191d99;
  padding: 0px 15px;
  border-radius: 0.625rem;
  height: 85px;
}

.t3-ud-statistic-body .t3-ud-statistic-body-item > .first {
  display: flex;
  align-items: center;
  font-size: 10px;
  column-gap: 5px;
  justify-content: center;
}

.t3-ud-statistic-body .t3-ud-statistic-body-item > .first .vicon {
  color: #f0be0b;
}

.t3-ud-statistic-body .t3-ud-statistic-body-item > .second {
  font-weight: bold;
  font-size: 15px;
  margin-top: 5px;
}

.t3-modal-bg-dark {
  background: #17181b;
}

.t3-settings-ep-image {
  max-width: 170px;
  border: 5px solid #46b8a826;
  border-radius: 8px;
  overflow: hidden;
  display: inline-block;
}

.t3-settings-ep-edit-btn {
  position: absolute;
  background-color: rgba(49, 52, 60, 0.6);
  border-radius: 0.125rem;
  color: #45ff8b;
  padding: 10px;
  margin-top: -20px;
  bottom: -15px;
  left: 50%;
  transform: translate(-50%, 0);
  cursor: pointer;
}

.t3-settings-ep-username-box {
  margin-top: 20px;
}

.t3-settings-ep-username-box label {
  color: #bbc1c799;
  font-size: 14px;
  font-weight: 300;
}

.t3-settings-ep-username-box input {
  background-color: transparent;
  border: 1px solid var(--base-input);
  border-radius: 2px;
  box-sizing: border-box;
  color: hsla(0, 0%, 100%, 0.85);
  display: inline-block;
  padding: 0px 11px;
  width: 100%;
  height: 40px;
}

.t3-settings-ep-username-box p {
  color: #bbc1c799;
  font-size: 10px;
  text-align: center;
  margin-top: 10px;
}

.t3-settings-ep-username-box .item {
  background: var(--modal-container);
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  margin-top: 10px;
}

.t3-custom-gl-title {
  color: var(--breadcrumb-highlight);
  background: var(--breadcrumb-bg);
  font-size: 14px;
  padding: 3px 30px;
  border-radius: 8px;
  display: inline-block;
  margin: 0px;
}

.t3-custom-gl-count-box {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.t3-custom-gl-count-box > .first {
  width: 20px;
}

.t3-custom-gl-count-box > .second {
  font-size: 16px;
  font-weight: bold;
}

.t3-custom-gl-count-box > .second span {
  color: var(--base-highlight);
  margin-left: 10px;
}

.t3-custom-game-list-box .t3-game-list-box {
  grid-template-columns: repeat(6, 1fr);
}

.t3-header > .first .t3-logo .img2 {
  display: none;
  max-width: 50px;
  height: 100%;
}

.t3-small-container {
  max-width: 1328px;
  margin: 0 auto;
}

.t3-team-banner-container {
  background: var(--referral-bg);
  /* height: 464px; */
  border-radius: 20px;
  position: relative;
  /* padding: 20px;
  display: flex;
  align-items: center; */
  height: 100%;
  /*background-image: url(../img/new_icons/referral-bg.png) !important;*/
  /* background-position: calc(100% + 20px) center;
  background-repeat: no-repeat;
  background-size: contain; */
  min-height: 330px;
  overflow: hidden;
}
.referral-login-btn {
  background: var(--base-sub-button);
  border-radius: 0.75rem;
  color: var(--base-sub-button-color);
  font-size: 1.125rem;
  font-weight: 700;
  height: 50px;
  margin-top: 10px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 190px;
  margin: auto;
  border: 0px;
}
.not-login {
  max-width: 340px;
  font-size: 22px;
  font-weight: 700;
}

.t3-team-banner-code-box {
  background: var(--referral-home-container-bg);
  border-radius: 0.75rem;
  padding: 30px 20px 30px;
  max-width: 340px;
  width: 100%;
  position: absolute;
  left: 18%;
  top: 20px;
}
.t3-team-banner-code-box.not-login {
  background: transparent;
  text-align: center;
}
#team-section .t3-team-banner-code-box {
  position: relative;
  left: 0px;
  top: 0px;
  max-width: 315px;
}

.t3-team-banner-code-box .t3-team-banner-title {
  color: var(--referral-home-title);
  /* margin-left: 20px; */
}

.t3-team-banner-code-box .t3-team-banner-details {
  align-items: center;
  background: var(--referral-home-input-box);
  border-radius: 0.75rem;
  color: var(--referral-home-input-color);
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  font-size: 12px;
  column-gap: 8px;
}
.t3-team-banner-code-box .t3-team-banner-details .first {
  word-break: break-all;
}
.t3-team-banner-code-box .t3-team-banner-details .second {
    color: var(--referral-input-svg);
}

.t3-team-banner-code-box .t3-team-banner-details .vicon {
    width: 20px;
    height: 18px;
    color: var(--referral-home-input-svg);
}

.t3-team-banner-code-box .t3-team-copy-btn {
  background: var(--base-sub-button);
  border-radius: 0.75rem;
  color: var(--base-sub-button-color);
  font-size: 1.125rem;
  font-weight: 700;
  height: 55px;
  margin-top: 16px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.t3-team-banner-coin {
  position: absolute;
  left: 16%;
  bottom: 95px;
}

.t3-team-banner-img-bg {
  min-height: 100%;
  height: 330px;
  width: 100%;
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  padding: 20px;

}

.t3-team-downline-img {
  width: 80%;
}
.position-relative {
	position: relative;
}

.label-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.label {
	position: absolute;
	padding: 0.7em;
	border-radius: 20px;
  min-width:20%;
  max-width:20%;
	background: linear-gradient(to top, #282828 2%, #515151 98%);
	color: white;
	text-align: center;
	white-space: normal;
	word-wrap: break-word;
	overflow-wrap: break-word;
	word-break: break-word;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	transform: translate(-50%, -50%);
  font-size: 12px;
}

@media (max-width: 768px) {
  .label {
    font-size: 12px; /* Smaller screens */
  }
}

@media (max-width: 480px) { 
  .label {
    font-size: 8px; /* Very small screens */
  }
}


.t3-team-second-section {
  display: flex;
  column-gap: 20px;
}

.t3-team-second-section > div {
  flex: 1 1;
  background: var(--base-container-bg);
  border-radius: 8px;
  padding: 20px 20px 23px;
}

.t3-team-second-section > .first .image {
  max-width: 200px;
  margin: auto;
}

.t3-team-second-section > .first .details {
  font-size: 20px;
}

.t3-team-second-section > .first .t3-team-second-reward-details {
  font-size: 15px;
  font-weight: 500;
}

.t3-team-second-section > .first .t3-team-second-reward-details span {
  color: #45ff8b;
}

.t3-team-second-percent-box {
  display: flex;
  gap: 20px;
  margin-top: 15px;
}

.t3-team-second-percent-box > div {
  flex: 1 1;
  background-color: var(--base-container-body-bg);
  border-radius: 0.75rem;
  padding: 20px 15px;
  text-align: center;
}

.t3-team-second-percent-box > div > div {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 8px;
}

.t3-team-second-percent-box > div > .first {
  color: #f0bf1d;
  white-space: nowrap;
}

.t3-team-second-percent-box > div > .second {
  color: #45ff8b;
}

.t3-team-second-salary-box {
  grid-gap: 30px;
  display: grid;
  grid-template-columns: 30% 1fr;
  margin-top: 10px;
}

.t3-team-second-salary-box > .second .details {
    font-size: 24px;
    color: #f6c722;
    text-align: center;
    margin-bottom: 30px;
}

.t3-team-second-salary-box > .second .details span {
    color: #29ae5f;
}

.t3-team-second-salary-box > .second .buttons {
    display: flex;
    column-gap: 10px;
}

.t3-team-second-salary-box > .second .buttons > button {
  flex: 1 1;
}
.t3-team-third-section > div {
  flex: 1 1;
  /* background: #282828; */
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

@media (max-width: 900px) {
    .t3-team-third-section > div {
        flex-direction: column;
    }
}

.t3-team-third-section > div > .image {
  max-width: 169px;
}
.t3-team-second-salary-box > .second .buttons > button {
    flex: 1 1;
}

.t3-custom-btn-big {
  background-color: #282828;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  border-radius: 0.45rem;
  border: 0px;
  font-weight: 500;
  width: 100%;
  color: #626262;
}

.t3-team-second-total-box {
  background-color: var(--base-container-body-bg);
  border-radius: 10px;
  padding: 20px 15px;
}

.t3-team-second-total-box > .first {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3px;
}

.t3-team-second-total-box > .first .details {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  color: var(--base-link);
  font-weight: 500;
  flex-wrap: wrap;
  gap: 10px;
}

.t3-team-second-total-box > .first .details span {
  color: var(--base-color);
  font-weight: 400;
}

.t3-team-second-total-box > .second {
  font-size: 20px;
  color: #f6c722;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: 500;
}

.t3-team-second-total-box > .second span {
  color: var(--theme-color-text-third);
}

.t3-team-second-income-box {
  grid-gap: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.t3-team-second-income-box > div {
  background-color: var(--base-container-body-bg);
  border-radius: 13px;
  padding: 20px 10px;
  font-size: 1.125rem;
  text-align: center;
}

.t3-team-second-income-box > div > .title {
  color: var(--theme-color-text-third);
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.t3-team-second-income-box > div > .details {
  color: #f0bf1d;
}

.t3-team-second-buttons-box {
  display: flex;
  align-items: center;
  column-gap: 30px;
}

.t3-team-second-buttons-box > button {
  flex: 1 1;
  display: flex;
  column-gap: 5px;
  align-items: center;
  line-height: 1;
}

.t3-team-second-buttons-box > button .vicon {
  color: #000;
}

.t3-team-third-section {
  background: var(--base-container-bg);
  border-radius: 20px;
  padding: 20px;
}

.t3-team-third-section .t3-team-third-details {
  border-bottom: 1px solid #105a48;
  color: #29ae5f;
  display: grid;
  grid-template-columns: 140px 1fr;
  padding: 10px;
}

.t3-team-third-section .t3-team-third-details > .first {
  color: #45ff8b;
  font-size: 24px;
  text-align: center;
  font-weight: 600;
}

.t3-team-third-section .t3-team-third-details > .second {
  display: grid;
  font-size: 16px;
  font-weight: 500;
  grid-template-columns: repeat(2, 1fr);
}

.t3-team-third-section .t3-team-third-details > .second > div {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f0bf1d;
  column-gap: 15px;
}

.t3-team-third-section .t3-team-third-details > .second > div span {
  color: #29ae5f;
}

.t3-team-forth-section {
  background: #282828;
  border-radius: 20px;
  padding: 20px;
}

.t3-team-treasure-box {
  grid-gap: 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 20px auto;
  width: 100%;
}

.t3-team-treasure-box > .item {
  background: linear-gradient(to bottom right, #303d39, #353535);
  border-radius: 0.5rem;
  color: #adb6c4;
  text-align: center;
  padding-top: 10px;
  position: relative;
}

.t3-team-treasure-box > .item > .text {
  font-weight: 600;
}

.t3-team-treasure-box > .item > .status {
  background: #3cbc6c;
  border-radius: 0 0 0.5rem 0.5rem;
  color: #333;
  padding: 3px 0 0;
  font-weight: 600;
  font-size: 14px;
  position: relative;
}

.t3-team-treasure-box > .item > .status > div {
  position: relative;
  z-index: 1;
}

.t3-team-treasure-box > .item > .status:after {
  background: #3cbc6c;
  bottom: -5px;
  content: "";
  height: 12px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 12px;
  /* z-index: -1; */
}

.t3-team-forth-section > .second {
  color: rgba(153, 164, 176, 0.8);
}

.t3-team-fifth-section {
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 20px;
}

.t3-team-fifth-section > div {
  background: var(--base-container-body-bg);
  border-radius: 0.75rem;
  display: grid;
  padding: 10px;
}

.t3-accordion-item {
  border-radius: 12px;
  overflow: hidden;
}
.t3-accordion-item > .head {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  /* background: linear-gradient(90deg, #26583B 0%, #2C352D 100%); */
  background: var(--accordion-header-bg);
  color: var(--accordion-header-color);
  height: 55px;
  padding: 0px 20px;
  cursor: pointer;
}
.t3-accordion-item > .head > .first {
  font-size: 18px;
  font-weight: 700;
  display: flex;
  gap: 10px;
  align-items: center;
}
.t3-accordion-item.active > .head {
  background: var(--accordion-header-bg-active);
}
.t3-accordion-item.active > .head > .second {
  transform: rotate(180deg);
}
.t3-custom-accordion > div:not(:last-child) {
  margin-bottom: 10px;
}
.t3-accordion-item > .details {
  display: none;
}
.t3-accordion-item.active > .details {
  display: block;
}
.t3-accordion-item.active > .details > div {
  display: grid;
  grid-template-columns: repeat(var(--referral-commission-tier, 3), 1fr) !important;  
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  font-size: 16px;
}

.t3-accordion-item.active > .details > .title-box {
  font-size: 16px;
  font-weight: 700;
  color: var(--base-highlight);
}
.t3-accordion-item.active > .details {
  background: var(--accordion-body-bg);
  padding: 10px;
}
.t3-accordion-item.active > .details > div:not(:last-child) {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--accordion-body-border);
  margin-bottom: 10px;
}
.rebate-accordion .t3-accordion-item.active > .details {
  background: var(--accordion-body-bg);
  padding: 10px;
}
.rebate-accordion .t3-accordion-item.active > .details > div {
  grid-template-columns: repeat(2, 1fr);
}
.t3-h2 {
  color: #45ff8b;
  font-size: 22px;
  margin-bottom: 0px;
  line-height: 1;
  margin: 20px auto;
  text-align: center;
}
.t3-h2-white {
  color: #fff;
  font-size: 22px;
  margin-bottom: 0px;
  line-height: 1;
  margin: 0px auto 20px;
  /* text-align: center; */
}
.t3-h3 {
  color: var(--base-highlight);
  font-size: 18px;
  margin-bottom: 0px;
  line-height: 1;
  margin: 20px 0;
  text-align: center;
}

.t3-h2-20 {
  color: var(--base-highlight);
  font-size: 20px;
  margin-bottom: 0px;
  line-height: 1;
  margin: 0px auto 20px;
}

.t3-team-fifth-details-box {
  background: linear-gradient(180deg, #85e6a1, #12442b);
  border-radius: 0.75rem;
  display: grid;
  grid-template-columns: 30% 40% 30%;
  margin: 0 auto;
  min-height: 280px;
  width: 100%;
  text-align: center;
}

.t3-team-fifth-details-box .image {
  width: 75px;
  border: 5px solid #f39d42;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  display: inline-block;
  margin: 0 auto;
}

@keyframes changeImg {
  from {
    left: -100%;
  }

  to {
    left: 150%;
  }
}

.t3-team-fifth-shine {
  -webkit-animation: changeImg 3s ease 0s;
  animation: changeImg 3s ease 0s;
  animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0) 0,
    hsla(0, 0%, 100%, 0.4) 50%,
    hsla(0, 0%, 100%, 0)
  );
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  -webkit-transform: skewX(-45deg);
  transform: skewX(-45deg);
  width: 30%;
}

.t3-team-fifth-details-box > div > .place {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.t3-team-fifth-details-box .item0 .place {
  color: #05501f;
}

.t3-team-fifth-details-box .item1 .place {
  color: #ffdf1b;
}

.t3-team-fifth-details-box .item2 .place {
  color: #ff9b50;
}

.t3-team-fifth-details-box .id {
  font-size: 10px;
  font-weight: bold;
}

.t3-team-fifth-details-box .amount {
  font-size: 10px;
  font-weight: bold;
  color: #ffaa17;
}

.t3-team-fifth-details-box .item0 {
  padding-top: 100px;
}

.t3-team-fifth-details-box .item1 {
  padding-top: 40px;
}

.t3-team-fifth-details-box .item2 {
  padding-top: 100px;
}

.t3-team-fifth-section > .second > .t3-team-who-won-box {
  grid-gap: 10px;
  display: grid;
  height: 280px;
  margin: 0 auto;
  overflow-y: auto;
  padding: 0 8px;
  width: 100%;
}

.t3-team-fifth-section > .second > .t3-team-who-won-box .item {
  background: linear-gradient(to bottom right, #303d39, #353535);
  border-radius: 0.25rem;
  color: #fff;
  color: #409a1f;
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 100px 1fr 85px;
  margin: 0 auto;
  min-height: 50px;
  padding: 0 10px;
  position: relative;
  width: 100%;
  font-size: 10px;
  font-weight: 600;
}

.t3-team-fifth-section
  > .second
  > .t3-team-who-won-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}

.t3-team-fifth-section > .second > .t3-team-who-won-box::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}

.t3-team-fifth-section
  > .second
  > .t3-team-who-won-box::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #45ff8b;
}

.t3-team-fifth-section > .second > .t3-team-who-won-box > .item > div {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.t3-team-fifth-section > .second > .t3-team-who-won-box .item .id {
  color: #adb6c4;
}

.t3-team-fifth-section > .second > .t3-team-who-won-box .item .amount {
  color: #ffdf1b;
}

#t3-custom-modal-withdraw {
  max-width: 700px;
}

#t3-custom-modal-withdraw .modal-body {
  background: transparent;
  padding: 0px !important;
}

#t3-custom-modal-withdraw .modal-content {
  background: transparent;
  padding: 0px;
  border: 0px;
}

.t3-custom-modal-withdraw-container {
  background-color: #1b1b1b !important;
  border-radius: 1.25rem !important;
}

.t3-custom-modal-withdraw-container .t3-cms-header {
  padding: 20px;
}

.t3-custom-modal-withdraw-container .t3-cms-header > .first {
  font-size: 18px;
  line-height: 1;
}

.t3-custom-modal-withdraw-container .t3-close-custom-modal .vicon {
  color: #fff;
}

.t3-withdraw-modal-body,
.t3-deposit-modal-body,
.t3-downline-modal-body {
    background-color: var(--modal-body);
    border-radius: 8px;
    /* border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem; */
    padding: 16px 20px 12px;
}

@media (max-width: 900px){
.t3-downline-modal-body {
    padding: 16px 20px 40px;
}
}

.t3-withdraw-form-style-box label,
.t3-deposit-form-style-box label {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 20px;
}

.t3-wthdraw-input-style-box,
.t3-deposit-input-style-box {
  display: flex;
  align-items: center;
  background: var(--amount-input-group) !important;
  border: none !important;
  border-radius: 0.5rem;
  box-shadow: none !important;
  min-height: 40px;
  color: var(--amount-input-group-color)
}

.t3-wthdraw-input-style-box > .first,
.t3-deposit-input-style-box > .first {
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  padding: 0px 15px;
}

.t3-wthdraw-input-style-box > .second,
.t3-deposit-input-style-box > .second {
  width: 100%;
}

.t3-wthdraw-input-style-box > .second input,
.t3-deposit-input-style-box > .second input {
  background: var(--amount-input-box) !important;
  border: 0px !important;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  height: 100%;
  width: 100%;
  min-height: 40px;
  text-align: right;
  font-size: 14px;
  padding: 0px 20px;
  color: var(--amount-input-box-color) !important;
  outline: unset !important;
}

.t3-wthdraw-input-style-box > .second input::-webkit-input-placeholder,
.t3-deposit-input-style-box > .second input::-webkit-input-placeholder {
  /* Edge */
  color: var(--base-input-placeholder);
}

.t3-wthdraw-input-style-box > .second input:-ms-input-placeholder,
.t3-deposit-input-style-box > .second input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--base-input-placeholder);
}

.t3-wthdraw-input-style-box > .second input::placeholder,
.t3-deposit-input-style-box > .second input::placeholder {
  color: var(--base-input-placeholder);
}

.t3-wthdraw-input-button-style-box,
.t3-deposit-input-button-style-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1) !important;
  border: none !important;
  border-radius: 8px;
  box-shadow: none !important;
  cursor: pointer;
  height: 40px;
}

.t3-wthdraw-input-button-style-box > .first,
.t3-deposit-input-button-style-box > .first {
  padding-left: 15px;
}

.t3-wthdraw-input-button-style-box > .second,
.t3-deposit-input-button-style-box > .second {
  background: #45ff8b;
  border: none;
  border-radius: 0 0.5rem 0.5rem 0;
  color: rgba(135, 135, 135, 1) !important;
  height: 40px;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.t3-custom-checkbox-container {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0px;
}

/* Hide the browser's default checkbox */
.t3-custom-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.t3-custom-checkbox-container .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid var(--base-input);
}

/* On mouse-over, add a grey background color */
.t3-custom-checkbox-container:hover input ~ .checkmark {
  border: 1px solid #2196f3;
}

/* When the checkbox is checked, add a blue background */
.t3-custom-checkbox-container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.t3-custom-checkbox-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.t3-custom-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.t3-custom-checkbox-container .checkmark:after {
  left: 5px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.t3-withdraw-buttons > button,
.t3-deposit-buttons > button {
  padding: 0px 60px;
}

.t3-withdraw-modal-body .t3-input-container label,
.t3-deposit-modal-body .t3-input-container label {
  font-weight: 400;
}

.t3-two-custom-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  padding: 0 15px;
  margin-bottom: 30px;
}

.t3-two-custom-tabs > div {
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.45rem;
  cursor: pointer;
  background: var(--deposit-tab-button);
  height: 50px;
  font-weight: bold;
  column-gap: 10px;
  font-size: 16px;
  line-height: 1;
}

.t3-two-custom-tabs > div .vicon {
  display: inline-block !important;
  width: 22px;
  height: 22px;
}

.t3-two-custom-tabs > .active .vicon {
  display: none !important;
}

.t3-two-custom-tabs > .active {
  background: var(--deposit-tab-button-active) !important;
  color: var(--deposit-tab-button-active-color);
}

.t3-table-body {
  min-height: 60vh;
  border-radius: 0 0 1.25rem 1.25rem;
}

.t3-downline-table-body {
  margin: 20px 0;
  background-color: #222;
  border-radius: 0 0 1.25rem 1.25rem;
}

.t3-downline-table-body .t3-custom-table tbody > tr > td.downline-table-username{
  color: #ffd10c;
}

.t3-custom-table {
    width: 100%;
}

.t3-custom-table thead > tr > th {
  background-color: var(--modal-table-header);
  border-bottom: 1px solid var(--modal-table-header-boder);
  color: var(--modal-table-header-color);
  font-size: 14px;
  font-weight: 600;
  padding: 8px 8px;
  text-align: center !important;
  position: relative;
}

.t3-custom-table thead > tr > th:after {
  content: " ";
  height: 1.6em;
  width: 1px;
  position: absolute;
  right: 0;
  top: 50%;
  background: var(--modal-table-header-boder);
  transform: translateY(-50%);
  z-index: 1;
}

.t3-custom-table thead > tr > th:last-child:after {
  display: none;
}

.t3-custom-table tbody > tr > td {
  border-bottom: 1px solid var(--modal-table-border);
  color: var(--base-color);
  font-size: 12px;
  /* font-weight: 600; */
  padding: 8px 5px;
  text-align: center;
}

.t3-custom-table tbody {
  background: var(--modal-table-body);
}

.t3-custom-table .t3-table-img {
  max-width: 220px;
  margin: 0px auto;
}

.t3-table-img {
  text-align: center;
}

.t3-custom-table .t3-table-msg {
  /* margin-top: -30px; */
  margin-bottom: 100px;
}

.t3-table-tab {
  display: flex;
  align-items: center;
  border-top: 1px solid #3a3a3a;
}

.t3-table-tab > button {
  flex: 1 1;
  border: 0px;
  background: #222;
  font-size: 15px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #99a4b0cc;
}

.t3-table-tab > button.active {
  background: #383838;
  color: var(--base-highlight);
  font-weight: bold;
}

@media (max-width: 900px) {
    .t3-downline-status-table-tab > button {
        font-size: 14px !important;
    }

    .t3-downline-table-tab > button {
        font-size: 14px !important;
    }

    .sortable-header{
        min-height: 45px;
    }

    .sortable-header .arrows{
        margin-left:10px !important;
    }

    .t3-downline-table-body .t3-custom-table thead > tr > th {
        padding: 0 5px !important; 
    }
}

.t3-downline-table-tab {
    padding: 16px 20px 12px;
    display: flex;
    align-items: center;
}

.t3-downline-status-table-tab {
    padding: 16px 0px 12px;
    display: flex;
    align-items: center;
}

.t3-downline-table-tab > button,
.t3-downline-status-table-tab > button {
    flex: 1 1;
    border: 0px;
    background: var(--modal-tab);
    font-size: 15px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--modal-tab-color);
    border-top: var(--modal-tab-border);
    border-bottom: var(--modal-tab-border);
}

.t3-downline-table-tab > button.active,
.t3-downline-status-table-tab > button.active {
  background: var(--modal-tab-active);
  color: var(--modal-tab-active-color);
  font-weight: bold;
}

.sortable-header {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sortable-header .text {
    flex-grow: 1;
    text-align: center;
}

.sortable-header .arrows {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    justify-content: center;
}

.sortable-header .arrow {
    font-size: 15px;
    height: 10px;
    display: inline-block;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    visibility: hidden;
}

.sortable-header .arrow.active {
    visibility: visible;
}

.t3-withdraw-balance-box,
.t3-deposit-balance-box {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.t3-withdraw-balance-box > .first,
.t3-deposit-balance-box > .first {
  color: #45ff8b;
  font-size: 20px;
  font-weight: bold;
}

.t3-deposit-first-recharge-msg {
  color: #7c7676;
  font-size: 16px;
}

.t3-deposit-first-recharge-msg > span {
  color: #ffe029;
  font-size: 20px;
  font-style: italic;
  font-weight: bold;
}

.t3-deposit-amount-list-box {
  grid-gap: 20px;
  color: #fff;
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(3, 1fr);
}

.t3-deposit-amount-list-box > .item {
  background: var(--numberpad-button);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  height: 66px;
  outline: none;
  position: relative;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.t3-deposit-amount-list-box > .item.disabled {
  background: var(--numberpad-button-disabled);
  cursor: not-allowed;
}

.t3-deposit-amount-list-box > .item.disabled > .first {
  color: var(--numberpad-button-disabled-color);
}

.t3-deposit-amount-list-box > .item > .first {
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  column-gap: 5px;
  display: flex;
  align-items: center;
  /* color: rgba(153, 164, 176, 1); */
  color: var(--base-color);
  position: relative;
  z-index: 1;
}

.t3-deposit-amount-list-box > .item > .second {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 3px;
  color: #ffe029;
  font-size: 11px;
  font-weight: 500;
}

.t3-deposit-amount-list-box > .item > .first span {
  font-size: 24px;
  font-weight: bold;
  font-style: italic;
  line-height: 1;
  color: #fff;
}

.t3-deposit-amount-list-box > .item:hover > .first span,
.t3-deposit-amount-list-box > .item.active > .first span {
  color: #222;
}

.t3-deposit-amount-list-box > .item > .bg {
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 30;
  z-index: 0;
}

.t3-deposit-amount-list-box > .item.disabled:hover {
  background: #2e2f33;
}

.t3-deposit-amount-list-box > .item:hover {
  background: var(--numberpad-button-active);
  color: #222;
}

.t3-deposit-amount-list-box > .item.active {
  background: var(--numberpad-button-active);
  color: #fff;
}

.t3-deposit-amount-list-box > .active > .first,
.t3-deposit-amount-list-box > .item:hover > .first {
  color: #222;
}

.t3-deposit-amount-list-box > .item.disabled:hover > .first {
  color: rgba(153, 164, 176, 1);
}

.t3-deposit-amount-list-box > .item.disabled:hover > .bg img {
  animation: none;
}

.t3-deposit-amount-list-box > .item:hover > .bg img {
  animation: roli_rotate 5s linear infinite;
}

.t3-deposit-amount-list-box > .active > .bg img {
  animation: roli_rotate 5s linear infinite;
}

@keyframes roli_rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.t3-hot-tag {
  background-color: #b42c33;
  border-radius: 0 0.5rem 0 0.5rem;
  color: #fff;
  font-size: 10px;
  height: 14px;
  line-height: 1.2;
  position: absolute;
  right: 0px;
  top: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(1px);
  width: 40px;
}

.t3-deposit-bonus-box {
  color: #fff;
  display: grid;
  font-size: 14px;
  font-weight: 500;
  grid-template-columns: 1fr 20% 30%;
  min-height: 40px;
  padding: 0px 16px;
  width: 100%;
  align-items: center;
  background-color: rgba(43, 45, 51, 0.5);
  border-radius: 0.75rem;
}

.t3-deposit-bonus-box > .second {
  color: #ffe029;
  text-align: center;
}

.t3-deposit-bonus-box > .third {
  color: #ffe029;
  text-align: right;
}

.t3-deposit-pix-selection-box {
  grid-gap: 10px;
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(4, 1fr);
}

.t3-deposit-pix-selection-box > div {
  border: 1px solid #2fdee4;
  border-radius: 0.5rem;
  position: relative;
  overflow: hidden;
}

.t3-deposit-pix-selection-box > div .t3-pix-not-active-coat {
  background-color: rgba(160, 230, 212, 0.5);
  border-radius: 0.5rem;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.t3-deposit-pix-selection-box > .active .t3-pix-not-active-coat {
  display: none;
}

.t3-deposit-pix-selection-box > div .t3-pix-active-check {
  display: none;
}

.t3-deposit-pix-selection-box > .active .t3-pix-active-check {
  display: block;
  position: absolute;
  right: 5px;
  bottom: 3px;
  z-index: 1;
}

.t3-deposit-pix-selection-box > .active .t3-pix-active-check .vicon {
  width: 30px;
  height: 30px;
  color: #0fda5e;
}

.t3-statistics-dropdown-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
}

.t3-statistics-dropdown-box > .first {
  color: var(--base-highlight);
  font-size: 16px;
  font-weight: 600;
  word-break: keep-all;
}

.t3-statistics-dropdown-box > .second {
  width: 100%;
}

.t3-statistics-dropdown-box > .second select {
  background: var(--base-input)!important;
  height: 38px;
  width: 100%;
  border-radius: 0.5rem;
  outline: unset;
  color: var(--base-input-color);
  border: 0;
  padding: 0 10px;
  -webkit-appearance: none;
  position: relative;
  background-image: url(../img/new_icons/angle-down-gray.png) !important; 
  background-position: calc( 100% - 14px) center !important; 
  background-repeat: no-repeat !important;
}
.t3-statistics-dropdown-box > .second select>option{
    background: #fff;
    color: #000;
}

.form-select select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    }

    .form-select {
    position: relative;
    }

    .form-select:before {
    position: absolute;
    top: 36%;
    right: 15px;
    content: ' ';
    pointer-events: none;
    border: solid #878787;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    pointer-events: none;
}

.t3-statistics-dropdown-box > .third {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}

.t3-statistics-dropdown-box > .third > .profile {
  width: 37px;
  border-radius: 8px;
  overflow: hidden;
}

.t3-statistics-dropdown-box > .third > .id {
  font-weight: 600;
  font-size: 14px;
  color: #99ffdf;
}

.t3-statistics-body-box {
  padding: 15px;
  /* background-color: #222; */
  background: transparent;
  border-radius: 0px 0px 1.25rem 1.25rem !important;
}

.t3-custom-promotion-title {
  color: var(--base-highlight);
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  margin: 12px auto;
  text-align: left;
}

.t3-promotion-list-text-box h6 {
  color: #45ff8b;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 10px;
  overflow: hidden;
  text-align: left;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
}

.t3-category-promotion-list-container .t3-promotion-list-box {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  text-align: left;
}

.t3-promotion-list-box {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(15%, 1fr));
  grid-gap: 20px;
  text-align: left;
}

.t3-promotion-list-box .t3-promotion-list-image-box {
  /* border: 1px solid #45ff8b; */
  /* border-radius: 12px; */
  /* overflow: hidden; */
}

.t3-promotion-list-box .t3-promotion-list-image-box .image {
  position: relative;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
}

.t3-promotion-list-box .t3-promotion-list-image-box .image .second {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  flex-direction: column;
}

.t3-promotion-list-box .t3-promotion-list-image-box:hover .image .first {
  transform: scale(1.05);
}

.t3-promotion-list-box .t3-promotion-list-image-box:hover .image .second {
  opacity: 1;
}

.t3-promotion-list-box .t3-promotion-list-image-box .image .second svg {
  width: 52px;
  transform: scale(1.3);
}

.t3-promotion-list-box .t3-promotion-list-image-box:hover .image .second svg {
  transform: scale(1);
}

.t3-custom-promotion-list-box .t3-promotion-list-box {
  grid-template-columns: repeat(4, 1fr);
}

.t3-promotion-list-wrapper-box {
  word-wrap: break-word;
  background-clip: border-box;
  display: inline-block;
  margin: 0 auto;
  padding: 15px;
  text-align: center;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.t3-promotion-list-description-box {
  color: #dfe3e6;
  font-size: 10px;
  /* height: 76px; */
  margin-bottom: 5px;
  overflow: hidden;
  text-align: left;
}

.t3-promotion-list-more-info-box {
  color: #45ff8b;
  cursor: pointer;
  font-size: 12px;
  text-transform: uppercase;
}

.t3-promotion-list-more-info-box {
  font-weight: 600;
  font-size: 14px;
}

.promotion-item-box{
    width: fit-content;
    margin: auto;
}

.promotion-container.banner-layout .promotion-item-box {
  width: 100%;
}

.promotion-container.banner-layout .promoinfo-banner {
  width: 100%;
}

.t3-default-lobby .default-lobby {
  background: transparent;
}

.t3-default-lobby .home-header {
  max-width: unset;
}

#game-lobby-new .t3-game-list-box {
  grid-template-columns: repeat(6, 1fr);
}

.t3-game-loading {
  /* grid-column: 5/1; */
  grid-column: 1 / -1 !important;
  max-height: 150px;
  /* position: absolute;
  bottom: -100px;
  left: 50%;
  transform: translate(-50%, 0); */
  width: 100%;
  max-width: 100%;
  z-index: 2;
}

.t3-default-lobby .home-balance-com-box > .hbc-box {
  background: var(--game-wallet-info-item-bg);
  box-shadow: unset;
}

.t3-default-lobby .home-balance-com-box > .convert-btn {
  background: goldenrod;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.43);
  -webkit-box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.43);
}

.t3-default-lobby .second-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
}

.t3-default-lobby .second-wrapper > .second {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.t3-default-lobby .theme-icon-blue-size-20 {
  color: #45ff8b !important;
}

.t3-default-lobby .theme-icon-blue-size-15 {
  color: #45ff8b;
}

.t3-default-lobby .search-with-icon-box input {
  background: var(--game-list-search-box);
  border: 0px !important;
}

#t3-custom-modal-withdraw .urr-amount-box {
  background: rgba(43, 45, 51, 0.5);
}

#t3-custom-modal-withdraw .urr-amount-box .input-with-balance {
  color: #45ff8b;
}

#t3-custom-modal-withdraw .all-box-with-icon {
  background: rgba(43, 45, 51, 0.5);
}

#t3-custom-modal-withdraw .all-box-with-icon .vicon {
  width: 20px;
  height: 20px;
  color: #45ff8b;
}

.t3-default-lobby .text-lblue1 {
  color: var(--base-highlight) !important;
}

.t3-search-with-icon-box {
  position: relative;
  width: 100%;
}

.t3-search-with-icon-box .search-icon {
  width: 17px;
  position: absolute;
  left: 15px;
  top: 12px;
}

.t3-search-with-icon-box input {
  background: #282828;
  border-radius: 8px;
  border: 1px solid #282828 !important;
  font-weight: 400;
  font-size: 14px;
  padding: 12px 16px 12px 45px;
  width: 100%;
  height: 45px;
  color: #ffffff;
  outline: none;
}

.t3-search-with-icon-box input::-webkit-input-placeholder {
  /* Edge */
  color: #64748b;
}

.t3-search-with-icon-box input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #64748b;
}

.t3-search-with-icon-box input::placeholder {
  color: #64748b;
}

.t3-vip-container {
  max-width: 1350px;
  padding-top: 20px;
  margin: auto;
}

.t3-vip-top-box {
  grid-gap: 20px;
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 340px 1fr;
}

.t3-vip-top-box > div {
  background: #282828;
  border-radius: 0.75rem;
  padding: 20px;
}

.t3-vip-user-light {
  border-radius: 50%;
  box-shadow: 0 0 50px 20px #fed791;
  height: 0;
  position: absolute;
  width: 0;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.t3-vip-user-image-box {
  position: relative;
  text-align: center;
}

.t3-vip-user-image-box > .t3-vip-user-image {
  position: relative;
  display: inline-block;
  padding: 5px 0px;
}

.t3-vip-user-image-box > .t3-vip-user-image img {
  position: relative;
  z-index: 1;
  max-width: 100px;
}

.t3-vip-user-image-box > .t3-vip-user-image:after {
  content: " ";
  height: 90%;
  width: 90%;
  border: 3px solid var(--setting-wallet-frame);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.t3-vip-user-image-box > .t3-vip-user-image:before {
  content: " ";
  height: 100%;
  width: 100%;
  border: 1px solid var(--setting-wallet-frame);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.t3-vip-user-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #99a4b0cc;
  font-size: 14px;
}

.t3-vip-user-label span {
  color: #d5f193;
}

.t3-blue {
  color: #1f96e6;
}

.t3-yellow {
  color: #f1bb5e;
}

.t3-vip-progress-bar {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.t3-vip-progress-bar > .first {
  width: 100%;
  position: relative;
}
.t3-vip-progress-bar > .second {
  color: #878e95;
}
.t3-vip-progress-bar > .first > .bar {
  background-color: hsla(0, 0%, 100%, 0.08);
  border-radius: 8px;
  height: 10px;
}
.t3-vip-progress-bar > .first > .progress {
  position: absolute;
  top: 0px;
  left: 0px;
  background: #45ff8b;
  height: 10px;
  max-width: 100%;
}
.t3-vip-upgrade-text {
  font-size: 12px;
  font-weight: 600;
  color: #99a4b0cc;
  text-align: center;
}

.t3-vip-user-score {
  grid-gap: 10px;
  display: grid;
  /*grid-template-columns: 1fr 1fr;*/
}
.t3-vip-user-score > div {
  background: var(--base-container-body-bg);
  border-radius: 0.5rem;
  min-height: 60px;
  padding: 15px 5px;
  text-align: center;
}
.t3-vip-user-score .title {
  color: #878e95;
  font-size: 12px;
  font-weight: 600;
}
.t3-vip-user-score.right-side .title {
  color: #878e95;
  font-size: 18px;
  font-weight: 600;
}
.t3-vip-user-score .details {
  color: #f1bb5e;
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
}
.t3-vip-top-box .t3-team-treasure-box {
  grid-gap: 20px 10px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 10px auto 55px;
}

.t3-vip-bonus-text {
  font-size: 15px;
}

.t3-vip-top-box > .second > .t3-vip-user-score > div {
  padding: 10px 5px;
}

.t3-vip-top-box > .second > .t3-vip-user-score > div .details {
  font-size: 20px;
}

.t3-vip-list-box {
  margin: 0px -8px;
  margin-top: 1.2em;
  position: relative;
}

.t3-vip-list-box .slick-slide {
  padding: 0px 8px;
  padding-top: 58px;
  padding-bottom: 15px;
}

.t3-vip-list-box .parent-item {
  background: linear-gradient(180deg, #1e9c4d, #0e3a24);
  margin: 10px auto;
  border-radius: 0.75rem;
  position: relative;
  /* border: 3px solid transparent; */
  overflow: unset;
  padding: 3px;
}

.t3-vip-list-box .item:before {
  background: linear-gradient(180deg, #12442b, #1e9c4d);
  border: none;
  -webkit-clip-path: polygon(50% 0, 100% 100%, 0 100%);
  /* clip-path: polygon(50% 0,100% 100%,0 100%); */
  content: "";
  height: 30px;
  left: 0;
  position: absolute;
  right: 0;
  top: -28px;
  width: 100%;
}

.t3-vip-list-box .item {
  background: linear-gradient(180deg, #0a622b, #082014);
  border-radius: 0.75rem;
  padding: 20px;
  padding-top: 60px;
  position: relative;
}

.t3-vip-list-box .image {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: -70px;
  max-width: 150px;
}

.t3-vip-list-box .item > h4 {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

.t3-vip-list-box .item > h3 {
  color: #67c667;
  font-size: 16px;
  font-weight: 600;
}

.t3-vip-list-box .item > .deposit-bet {
  grid-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.t3-vip-list-box .item > .deposit-bet > div {
  background: #033424;
  border-radius: 0.5rem;
  padding: 8px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.t3-vip-list-box .item > .deposit-bet > div .title {
  color: #dfe3e6;
  font-size: 14px;
  font-weight: 600;
}

.t3-vip-list-box .item > .deposit-bet > div .details {
  color: #f1bb5e;
  font-size: 18px;
  font-weight: 700;
  margin-top: 8px;
  line-height: 1;
}

.t3-vip-list-box .item > .privilege-title {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin-top: 10px;
}

.t3-vip-list-box .item > .privilege-details {
  margin-top: 10px;
}

.t3-vip-list-box .item > .privilege-details span {
  color: #f1bb5e;
}

.t3-vip-list-box .t3-vip-withdraw-privilege-box {
  text-align: center;
  margin: 20px 0;
}

.t3-vip-list-box hr {
  background-color: #126e51;
  border: none;
  height: 1px;
  margin: 10px auto;
  width: 70%;
}

.t3-vip-withdraw-privilege-box > .title {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

.t3-vip-withdraw-privilege-box > div {
  line-height: 2;
}

.t3-vip-list-box .item.active {
  background: linear-gradient(180deg, #45ff8b, #3eaa52);
}
.vip-circle {
  background: #676767;
  width: 14px;
  height: 14px;
  border-radius: 8px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: -35px;
  box-shadow: 0 0 10px #676767;
  z-index: 1;
}
.vip-circle.active {
  background: #45FF8B;
  box-shadow: 0 0 10px #45ff8b;
}
.t3-team-treasure-box > .item:not(:last-child) > .vip-line {
  background: #303030;
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: -31px;
  left: 53%;
}
.t3-team-treasure-box > .item:not(:last-child) > .vip-line.active {
  background: #2a4b36;
}
.vip-tab-buttons {
  display: flex;
  align-items: stretch;
  gap: 10px;
}
.vip-tab-buttons > div {
  flex: 1 1;
  text-align: center;
  background: var(--membership-tab-bg);
  border-radius: 12px;
  padding: 10px 0px;
  border: var(--membership-tab-border);
  cursor: pointer;
}
.vip-tab-buttons > .item.active {
  border: 1px solid var(--membership-tab-active-border);
  background: var(--membership-tab-active) !important;
  box-shadow: var(--membership-tab-active-box-shadow);
}
.vip-tab-buttons > .item.active h4 {
  color: var(--membership-tab-active-color)
}

.vip-tab-buttons > div > .image {
  max-width: 80px;
  margin: auto;
}

.vip-tab-buttons button{
  background: var(--membership-tab-button-active);
  border-radius: 8px;
  color:var(--membership-tab-button-active-color);
  font-weight: 700;
  border: none;
  padding: 4px 20px;
  font-size: 18px;
  box-shadow: 0px 0px 12px 3px rgb(239 215 126 / 78%);
  animation: glow 1s infinite alternate
}

@keyframes glow {
  from {
    box-shadow: 0px 0px 12px 3px rgb(239 215 126 / 0%);;
  }
  to {
    box-shadow: 0px 0px 12px 3px rgb(239 215 126 / 78%);
  }
}
.vip-tab-buttons button:disabled{
  background: var(--membership-tab-button);
  color:  var(--membership-tab-button-color);
  pointer-events:none;
  box-shadow: unset;
  animation: unset;
  box-shadow:var(--membership-tap-button-box-shadow)
}
.vip-tab-buttons button:hover{
  background: #e2a319;
  color: #000000;
}
.vip-bottom-content-box {
  background: var(--base-container-bg);
  border-radius: 0.75rem;
  padding: 20px;
}
.vip-promotion-banner-box {
  display: flex;
  align-items: center;
  gap: 20px;
}
.vip-promotion-banner-box > div {
  flex: 1 1;
  border-radius: 12px;
  height: 150px;
}
.vip-promotion-banner-box > .first {
  background: linear-gradient(90deg, #3f2e24 0%, #644128 100%);
}
.vip-promotion-banner-box > .second {
  background: #233128;
}
.vip-level-upgrade-box {
  height: 100%;
  background: url(../img/new_icons/member-vip.png);
  background-position: left bottom;
  background-size: 45%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 30px;
}
.vip-level-upgrade-box > div {
  text-align: center;
}
.vip-lifetime-renewal-box {
  height: 100%;
  background: url(../img/new_icons/member-vip2.png);
  background-position: left bottom;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 30px;
  text-align: center;
}
.vip-promotion-banner-box .vip-lifetime-renewal-box > h4 {
  line-height: 1.5;
}
.vip-promotion-banner-box h4 {
  color: #f6c722;
  font-size: 25px;
  margin: 0px;
  text-shadow: 1px 2px 2px #363636;
}
.vip-promotion-banner-box button {
  background: #f6c722;
  box-shadow: 0 4px 8px rgba(29, 34, 37, 0.1);
  color: #000000 !important;
  height: 45px;
  padding: 0px 25px;
  border-radius: 8px;
  outline: unset;
  border: 0px;
  font-weight: 700;
  text-align: center;
  line-height: 15px;
}
.vip-col-2 {
  display: flex;
  gap: 20px;
}
.vip-col-2 > div {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.vip-details-list-box {
  background: var(--base-container-body-bg);
  padding: 0 15px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  margin-bottom: 20px;
}
.vip-details-list-box > div {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
}
.vip-details-list-box > div > .head {
  font-size: 16px;
  font-weight: 600;
}
.vip-details-list-box > div > .details {
  font-size: 16px;
  font-weight: 300;
  padding: 0 5px;
  line-height: 35px;
  min-width: 40px;
  text-align: center;
}
.vip-details-list-box > div > .details.active {
  background: var(--membership-tab-active);
  border-left: 1px solid var(--membership-tab-active-border);
  border-right: 1px solid var(--membership-tab-active-border);
}
.vip-details-list-box > div:not(:last-child) {
  line-height: 35px;
  border-bottom: 1px solid #424242;
}
.vip-rebate-details-list-box {
    background: var(--base-container-body-bg);
    padding: 0 15px;
    border-radius: 12px;
}
.vip-details-list-table {
    /* background: #2d2d2d; */
    padding: 0 15px;
    border-radius: 12px;
    width: 100%;
    table-layout: fixed;
}
.vip-details-list-table>tbody > tr > td.head {
  font-size: 16px;
  font-weight: 600;
  width: 25%;
  padding: 0 10px;
}
.vip-details-list-table>tbody > tr > td.details {
  font-size: 16px;
  font-weight: 300;
  min-width: 40px;
  line-height: 35px;
  text-align: center;
  word-wrap: break-word;
}
.vip-details-list-table>tbody > tr > td.details.active {
  background: var(--membership-tab-active);
  border-left: 1px solid var(--membership-tab-active-border);
  border-right: 1px solid var(--membership-tab-active-border);
  color: var(--membership-tab-active-color);
}
.vip-details-list-table>tbody > tr:not(:last-child) {
  line-height: 35px;
  border-bottom: 1px solid #424242;
}
.t3-vip-my {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: -20px;
  background: #85e6a1;
  color: #000;
  border: 3px solid #fff;
  border-radius: 8px;
  height: 40px;
  width: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
}

.t3-vip-slick-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 80px;
}

.t3-vip-slick-arrow .vicon {
  width: 25px;
  height: 25px;
  color: #f1bb5e;
  cursor: pointer;
}

.t3-custom-light-btn.disabled {
  opacity: 0.5;
}

.t3-reload-bank-list-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--modal-container);
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 20px;
}
.t3-reload-bank-list-box .border-top{
    border-top: 1px solid #5D5D5D;
}

.t3-upload-file-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  height: 80px;
  column-gap: 10px;
  border: 1px dashed #334155;
  border-radius: 8px;
  color: #29d5d9;
  cursor: pointer;
}

.t3-upload-file-box .t3-upload-img {
  width: 15px;
}

.t3-upload-file-box .t3-upload-file-box-content {
  border: 1px solid #29d5d9;
  border-radius: 4px;
  padding: 0 0.75rem;
  display: flex;
  flex-direction: row;
  min-width: 150px;
  width: 100%;
  height: 100%;
  min-height: 55px;
}

.t3-default-lobby .progress-identifier {
  background: #45ff8b;
}

.t3-default-lobby .home-balance-com-box.rollover .progress-identifier {
    background: #fff2a8;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.8);
}

.t3-default-lobby .rollover-balance-box {
  background: #282828;
}

.rollover-accordion .accordion-button {
  background: #282828;
  border-radius: 8px;
  color: #898f97;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 0px;
  padding: 16px;
  font-size: 14px;
}

.rollover-accordion .accordion-button[aria-expanded="true"] {
  background: #282828;
  box-shadow: unset;
  border-radius: 8px 8px 0px 0px;
}

.rollover-accordion .accordion-item {
  border-radius: 8px;
  background: rgba(43, 45, 51, 0.5);
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
}

.rollover-accordion h2 {
  margin-bottom: 0px;
}

.rollover-accordion .accordion-body {
  padding: 16px;
}

.t3-progress-bar-box {
  background: #060a12;
  border-radius: 8px;
  height: 7px;
  width: 80px;
  margin-bottom: 10px;
  margin-top: 15px;
  overflow: hidden;
}

#t3-custom-modal-withdraw .sweet-alert {
  background: rgb(37 37 37) !important;
}

#t3-custom-modal-settings .search-with-icon-box input {
  background: rgba(45, 48, 53, 0.5);
}

#t3-custom-modal-settings .css-16hr6bx-control {
  background: rgba(45, 48, 53, 0.5);
  box-shadow: unset;
  border: 0px !important;
  height: 40px;
}

#t3-custom-modal-settings .css-bbcqiw-control {
  background: rgba(45, 48, 53, 0.5);
  box-shadow: unset;
  border: 0px !important;
  height: 40px;
}

#t3-custom-modal-settings .css-16hr6bx-control div {
  font-size: 14px;
}

#t3-custom-modal-settings .css-bbcqiw-control div {
  font-size: 14px;
}

#t3-custom-modal-settings .css-n84pdy-menu span {
  background: rgba(45, 48, 53, 1) !important;
}

#t3-custom-modal-settings .css-n84pdy-menu div {
  background: rgba(45, 48, 53, 1) !important;
}

#t3-custom-modal-settings .css-1ftc9ec-singleValue {
  color: #fff !important;
}

.t3-sweet-alert-container .sweet-alert {
  background: var(--modal-body) !important;
  border-radius: 15px !important;
  color: var(--base-color) !important;
  opacity: 1 !important;
}

.t3-sweet-alert-container .sweet-alert .btn {
  border: unset;
  background: #454545;
  min-width: 185px;
}

.t3-sweet-alert-container .sweet-alert .btn.btn-primary {
  border: unset;
  /* background: linear-gradient(90deg, #039940 0%, #00491E 100%); */
  background: var(--base-main-button) !important;
  min-width: 185px;
  font-weight: bold;
  color: var(--base-main-button-color);
  /* display: block !important; */
}

.t3-sweet-alert-container .sweet-alert .btn.btn-secondary {
  border: unset;
  /* background: linear-gradient(90deg, #039940 0%, #00491E 100%); */
  background: #F6C722 !important;
  min-width: 185px;
  font-weight: bold;
  color: black;
  /* display: block !important; */
}

#t3-custom-modal-withdraw .search-with-icon-box input {
  background: rgba(45, 48, 53, 0.5);
  border: 0px !important;
}

#t3-custom-modal-withdraw .all-flex-box-gray {
  background: rgba(45, 48, 53, 0.5) !important;
}

.t3-successful .btn-custom {
  background: #45ff8b;
  color: #000 !important;
}

.t3-pin-modal-body {
  min-height: 550px;
  overflow: hidden;
  justify-content: center;
}

.t3-pin-modal-body .react-simple-keyboard {
  border-radius: 0.75rem;
  background: #17181b !important;
}

.t3-pin-modal-body #Verify-qr .keyboard.keyboard-numpad .hg-button {
  cursor: pointer;
}

#t3-custom-modal-withdraw .t3-pin-modal-body .custom-form input {
  border-radius: 0.5rem;
  background: rgba(45, 48, 53, 0.5) !important;
  border: 2px none;
  outline: none;
  height: 40px;
  padding: 0px 20px;
  font-size: 14px;
  width: 100%;
  color: #fff !important;
  font-weight: 300;
}

#t3-custom-modal-withdraw
  .t3-pin-modal-body
  .custom-form
  input::-webkit-input-placeholder {
  /* Edge */
  color: rgb(109, 109, 109);
}

#t3-custom-modal-withdraw
  .t3-pin-modal-body
  .custom-form
  input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(109, 109, 109);
}

#t3-custom-modal-withdraw .t3-pin-modal-body .custom-form input::placeholder {
  color: rgb(109, 109, 109);
}

.t3-withdraw-success,
.t3-deposit-success {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.t3-scroll-row {
  position: relative;
  max-height: 400px;
  float: left;
  width: 100%;
  height: 400px;
  overflow: hidden;
  overflow-y: auto;
}

.t3-scroll-container {
  position: absolute;
  width: 100%;
  top: 0;
  /* animation: scroll 18s linear 1s infinite; */
  /* animation: scroll 18s linear; */
}

.t3-scroll-item {
  min-width: 100%;
  min-height: 40px;
  display: block;
}

.t3-scroll-item-child {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.bonus-slick {
  margin: 20px -11px 0px;
  height: 107px;
  display: flex;
  justify-content: start;
  align-items: start;
}

.bonus-slick .slick-list {
  height: 100%;
  width: 100%;
  overflow-x: auto;
}

.bonus-slick .slick-track {
  display: grid;
  grid-auto-flow: column;
  column-gap: 10px;
  height: 100%;
}

.bonus-slick .slick-track > div {
  width: 160px !important;
  height: 94px !important;
  height: 100%;
}

.bonus-items {
  margin: 8px;
}

.bonus-items .bonus-img {
  border-radius: 8px;
  position: relative;
  cursor: pointer;
}

.bonus-items .bonus-img img {
  border-radius: 8px;
}

.bonus-items .bonus-img:after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #0000001e;
  top: 0;
  left: 0;
}

.bonus-items .info-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 99;
}

.bonus-items .bonus-img.active {
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3),
    inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  border: 3px solid #45ff8b;
  position: relative;
}

.bonus-items:hover .bonus-img:after {
  display: none;
}

.w-100 {
  width: 100%;
}

@keyframes scroll {
  100% {
    top: -2100px;
  }
}

.show-767 {
  display: none;
}

.t3-new-promotion-list {
  margin: 20px 0px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .t3-new-promotion-list {
    gap: 20px;
  }
}

@media screen and (max-width: 400px) {
  .t3-new-promotion-list {
    gap: 10px;
  }
}

.t3-new-promotion-list .promo-item {
  display: inline-block;
  /* padding: 0px 10px; */
  height: 210px;
  max-width: 100%;
  position: relative;
  /* overflow: hidden; */
}

.t3-new-promotion-list .promo-item img {
  max-height: 100%;
}

.t3-new-promotion-list a {
  width: fit-content;
  display: block;
  height: 100%;

  /* Chris: Adding this to make sure promotions that are loading don't look broken */
  min-width: 150px;
  background: rgba(0, 0, 0, .2);
  text-align: center;
}

.promo-container {
  position: relative;
  display: flex;
  justify-content: center;
}
.promo-container.no-banner {
  min-height: 300px;
}

.promo-container .promo-content {
  position: absolute;
  top: 10%;
  width: 100%;
  text-align: center;
  right: 0;
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
}

.promo-content {
  position: absolute;
  top: 15%;
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  padding: 0px 5px;
  overflow: hidden;
}

.promo-image {
  height: 100%;
  position: relative;
  width: fit-content;
}
 
.t3-promotion-content.banner-layout .promo-content .description,
.promotion-container.banner-layout .promo-content .description,
.t3-promotion-content.thumbnail-layout .promo-content .title,
.promotion-container.thumbnail-layout .promo-content .title {
  color: #ffffff;
  font-weight: bold;
  margin: 0;
  line-height: 1.4em;
}

.t3-promotion-content.banner-layout .promo-content .title,
.promotion-container.banner-layout .promo-content .title,
.t3-promotion-content.thumbnail-layout .promo-content .description,
.promotion-container.thumbnail-layout .promo-content .description {
  color: #fce977;
  font-weight: bold;
  margin: 0;
  line-height: 1.4em;
}

.t3-promotion-content.thumbnail-layout .promo-content .title,
.promotion-container.thumbnail-layout .promo-content .title {
  font-size: 18px;
  max-height: 200px;
}

.t3-promotion-content.thumbnail-layout .promo-content .description,
.promo-container.thumbnail-layout .promo-content .description {
  font-size: 24px;
  max-height: 100px;
  line-height: 1.1em;
  padding-top: 10px;
}

.t3-promotion-content.banner-layout .promo-content .title {
  font-size: 60px;
  max-height: 90px;
  white-space: nowrap;
}

.t3-promotion-content.banner-layout .promo-content .description {
  font-size: 30px;
  max-height: 100px;
}

.promotion-container.banner-layout .promo-content .title {
  font-size: 120px;
  max-height: 180px;
  white-space: nowrap;
}

.promotion-container.banner-layout .promo-content .description {
  font-size: 60px;
  max-height: 200px;
}

/* Promo banner layout */
.t3-promotion-content.banner-layout .t3-custom-promotion-title {
  /* max-width: 700px; */
}

.t3-promotion-content.banner-layout .t3-new-promotion-list {
  flex-direction: column;
  /* align-items: center; */
}

.t3-promotion-content.banner-layout .promo-item {
  display: flex;
  height: auto;
  border-radius: 12px;
  overflow: hidden;
  height: 215px;
}

.t3-promotion-content.banner-layout .promo-image {
  flex: 0 0 40%;
  text-align: center;
  height: auto;
}

.t3-promotion-content.banner-layout .promo-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: left top;
}

.t3-promotion-content.banner-layout .promo-info {
  flex: 0 0 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 17px 17px;
  background: var(--promotion-bg);
}

.t3-promotion-content.banner-layout .promo-image .promo-status {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 4px 16px;
  border-radius: 5px;
  font-weight: 700;
}
.t3-promotion-content.banner-layout .promo-image .promo-status.active-now{
  background: var(--promotion-status-active);
  color: var(--promotion-status-active-color);

}

.t3-promotion-content.banner-layout .promo-image .promo-status.end-soon{
  background: var(--promotion-status-end);
  color: var(--promotion-status-end-color);
}

.t3-promotion-content.banner-layout .promo-info .promo-info-title {
  color: var(--theme-promotion-color);
  font-size: 18px;
  font-weight: 700;
}
.t3-promotion-content.banner-layout .promo-info .promo-info-title {
  color: var(--theme-promotion-color);
  font-size: 18px;
  font-weight: 700;
}

.t3-promotion-content.banner-layout .promo-info .promo-info-description {
  color: #383838;
  font-size: 12px;
  max-height: 60px;
  overflow: hidden;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
}

.t3-promotion-content.banner-layout .promo-info .promo-info-date {
  color: var(--promotion-highlight);
  font-size: 12px;
  background: var(--promotion-date);
  padding: 4px 10px;
  border-radius: 3px;
}

.t3-promotion-content.banner-layout .promo-info .promo-info-date span{
  font-weight: 700;

}
.t3-promotion-content.banner-layout .promo-info .btn-more {
  padding: 6px 30px;
  background: var(--promotion-button);
  box-shadow: none;
  border-radius: 6px;
  color: var(--promotion-button-color);
  font-size: 14px;
  font-weight: bold;
  border: unset;
}

.t3-promotion-content.banner-layout .promo-content {
  text-align: left;
  left: 5%;
  top: 15px;
  max-width: 50%;
  padding: 0;
}

.promotion-container.banner-layout .promo-container .promo-content {
  right: unset;
  left: 5%;
  text-align: left;
  width: 50%;
  padding: 0;
}
@media (max-width: 1400px) {
  .promotion-container.banner-layout .promo-content .title{
    font-size: 80px;
  }
  .promotion-container.banner-layout .promo-content .description{
    font-size: 40px;
  }
  .t3-promotion-content.banner-layout .promo-content .title{
    font-size: 40px;
  }
  .t3-promotion-content.banner-layout .promo-content .description{
    font-size: 20px;
  }
}
@media (max-width: 1100px) {
  .promotion-container.banner-layout .promo-content .title{
    font-size: 60px;
  }
  .promotion-container.banner-layout .promo-content .description{
    font-size: 30px;
  }
  .t3-promotion-content.banner-layout .promo-content .title{
    font-size: 30px;
  }
  .t3-promotion-content.banner-layout .promo-content .description{
    font-size: 15px;
  }
}

@media (max-width: 900px) {
  .t3-promotion-content.banner-layout .promo-item{
    height: 148px;
  }
  .t3-promotion-content.banner-layout .promo-info{
    display: none;
  }
  .t3-promotion-content.banner-layout .promo-image{
    flex: 0 0 100%;
    /* min-height: 160px; */
  }

  .t3-promotion-content.banner-layout .promo-image .promo-status {
    top: unset;
    bottom: 10px;
  }

  .t3-promotion-content.banner-layout .promo-image .promo-content .title,
  .promotion-container.banner-layout .promo-content .title{
    font-size: 36px;
    max-height: 50px;
  }

  .t3-promotion-content.banner-layout .promo-image .promo-content .description,
  .promotion-container.banner-layout .promo-content .description{
    font-size: 20px;
    max-height: 70px;
  }
}


.home-login-register-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.home-login-register-box > div {
  height: 45px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0px 2px 5px 0px #ffffff40 inset;
  color: #000000;
  text-transform: uppercase;
}

    .home-login-register-box > div:first-child {
      background: linear-gradient(90deg, #FFD963 0%, #EBB614 100%);
    }

.home-login-register-box > div:last-child {
  background: linear-gradient(90deg, #45ff8b 0%, #01b545 100%);
}

.mobile-home-balance-box {
  border-radius: 8px;
  padding: 15px 15px;
  font-size: 12px;
  background: var(--mobile-profile-bg);
}

.mobile-home-balance-box > .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #447156;
  padding-bottom: 6px;
  margin-bottom: 10px;
  gap: 8px;
}

.mobile-home-balance-box > .body {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
}

.mobile-home-balance-box > .head .title {
  color: var(--base-link);
  font-size: 18px;
  font-weight: bold;
}

.mobile-home-balance-box > .head .level {
  display: flex;
  align-items: center;
  gap: 5px;
}

.mobile-home-balance-box > .head img {
  height: 30px;
}

.mobile-home-balance-box > .body .first {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobile-home-balance-box > .body .first > .amount {
  color: var(--base-highlight);
  font-size: 20px;
  font-weight: bold;
}

.mobile-home-balance-box > .body .first > span {
  font-weight: bold;
}

.mobile-home-balance-box > .body .second {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-end;
}
.mobile-home-balance-box > .body .second>div{
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.mobile-home-balance-box > .body .second button {
  height: 32px;
  font-size: 16px;
  color: var(--base-main-button-third-color);
  border: 0px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
  background: var(--base-main-button-third);
  border-radius: 8px;
  padding: 0px 8px;
}

.mobile-home-balance-box > .body .second .theme-icon-size-20 {
  color: var(--base-main-button-third-color);
}

.mobile-latest-deposit-withdraw-box {
  display: flex;
  border-radius: 12px;
  overflow: hidden;
  flex-wrap: wrap;
}

.mobile-latest-deposit-withdraw-box > div {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.mobile-latest-deposit-withdraw-box h4 {
  font-size: 14px;
}

.mobile-latest-deposit-withdraw-box > div:first-child > .mobile-home-latest-transactions-box {
  /* border-radius: 8px 0px 0px 8px; */
  border-right: 1px solid var(--dashboard-table-border);
  background: var(--dashboard-table-row-odd);
}

.mobile-latest-deposit-withdraw-box > div:last-child > .mobile-home-latest-transactions-box {
  /* border-radius: 0px 8px 8px 0px; */
  background:var(--dashboard-table-row-odd);

}

.mobile-home-latest-transactions-box {
  /* border-radius: 8px; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: stretch;
}

.mobile-home-latest-transactions-box > .head {
  background: var(--dashboard-table-th);
  color: var(--dashboard-table-th-color);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.mobile-home-latest-transactions-box > .body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  font-size: 14px;
  text-align: center;
  color: var(--base-color);
  padding: 0px 10px;
  height: 30px;
  background: var(--theme-color-bg-table-row);
  border-bottom: 1px solid var(--theme-color-bg-table-header);
}

.mobile-home-latest-transactions-box > .body.empty-data {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile-home-latest-transactions-box > .odd {
  background: var(--dashboard-table-row-odd);
}

.mobile-home-latest-transactions-box > .even {
  background: var(--dashboard-table-row-even);
}

.mobile-home-latest-transactions-box > .body > .latest-label {
  height: 100%;
  display: flex;
  align-items: center;
}

.mobile-home-latest-transactions-box > .body img {
  max-width: 30px;
  width: 30px;
  max-height: 30px;
  margin-right: 5px;
  object-fit: contain;
}
.home-referral-container {
  background-image: var(--referral-home-bg);
  height: 410px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}
.home-referral-container .img-teamBanner{
  object-position: right;
}

.home-referral-container .t3-team-banner-img-bg{
  padding: 0px;
  display: unset;
}
/* Custom Modal */
.t3-custom-modal {
  background: var(--modal-body) !important;
  padding: 0;
  border-radius: 20px;
}

#t3-custom-modal.modal-dialog {
  max-width: 700px;
}

.t3-custom-modal .modal-body {
  border-radius: 8px;
  border: unset;
  padding: 0 !important;
  min-height: 550px;
}

.t3-custom-modal .modal-header {
  padding: 20px;
  background: var(--modal-header);
  border-bottom: unset;
  flex: none;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.t3-custom-modal .modal-header .modal-title {
  font-size: 1.375rem;
  color: var(--modal-header-color);
}

.t3-custom-modal .modal-header .close {
  padding: 0;
  margin: 0;
}

.t3-custom-modal .modal-header .close svg {
  width: 25px;
  color: var(--modal-header-close);
}

.t3-custom-modal-container .modal-header {
  background: #1b1b1b;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: none;
}

.t3-custom-modal-container .modal-header .title {
  color: #45ff8b;
  font-size: 1.375rem;
  font-weight: bold;
}

.t3-custom-modal-container .modal-header .close {
  color: #fff;
}

.t3-custom-modal-container .modal-header .close svg {
  width: 25px;
}

.t3-custom-modal-container .custom-body {
  padding: 30px 20px 20px;
}

.t3-custom-modal-container .custom-body .edit-profile {
  padding: 30px 20px 0px 20px;
}

.t3-custom-modal-container .custom-button-row {
  padding-top: 40px;
  display: flex;
  justify-content: center;
}

.t3-custom-modal-with-back-btn .modal-header .modal-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.t3-custom-modal-with-back-btn .modal-header .modal-title>.first{
    display: flex;
    align-items: center;
    gap: 10px;
}
.t3-custom-modal-with-back-btn .modal-header .modal-title>.first .vicon{
    color: #ffffff73;
    cursor: pointer;
}
.min-height-600px{
    min-height: 600px;
}
.home-announcement-modal-content-box > div:not(:last-child) {
  margin-bottom: 20px;
}

.home-announcement-modal-content-box .details {
  font-size: 18px;
}
.home-announcement-modal-content-box img{
  max-width: 100%;
}

#spin-wheel-modal, 
#t3-login-register-modal.home-announcement-modal {
  max-width: 600px !important;
}

#t3-login-register-modal.home-announcement-modal .t3-lr-modal-body {
  display: block;
}

#date-range-filter label {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}

#date-range-filter .filter-grid-wrapper {
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  display: grid;
}

#date-range-filter .date-selection-wrapper .selection-item {
  width: max-content;
  background: #28292c;
  border: 0px;
  font-size: 14px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  margin: 0px;
  line-height: 1;
  border-radius: 8px;
  white-space: nowrap;
}

#date-range-filter .date-selection-wrapper .selection-item.active {
  background: #895F2D;
  color: #fff;
}

#date-range-filter .date-selection-wrapper {
  display: flex;
  overflow: auto;
  align-items: center;
  gap: 10px;
  padding-bottom: 5px;
}

#date-range-filter input {
  height: 40px;
  border-radius: 8px;
  font-size: 16px;
  color: #878787;
}

.downline-gen-note {
  color: #f6c722;
  font-size: 14px;
}


.earn-stat-title .first-title {
  font-size: 14px;
  color: var(--modal-container-title);
}

.earn-stat-number {
  font-size: 14px;
  margin-bottom: 0px !important;
}

.ul-news-list {
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  display: grid;
}

.downline-details-list {
  font-size: 14px;
  font-weight: 300;
}

.downline-title-list {
  font-size: 16px;
}

#deposit-id .all-flex-box-gray {
  background: linear-gradient(90deg, #26583b 0%, #2c352d 100%);
  border-radius: 8px;
  cursor: pointer;
}

.reports-stats-box {
  gap: 10px;
}

.home-referral-button-group-box {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  display: grid;
}

.home-referral-button-group-box > div {
  margin-top: 0px !important;
}

.home-referral-container .t3-team-banner-code-box {
  padding: 20px;
  left: 12%;
}

.team-referral-button-group {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  display: grid;
}

#t3-custom-modal.share-referral-modal {
  max-width: 500px;
}
#t3-custom-modal.share-referral-modal .t3-custom-modal-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.share-referral-media-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #878e95;
  font-size: 14px;
  text-align: center;
}
.share-referral-media-box .image {
  width: 70px;
  height: 70px;
  border-radius: 8px;
  background: #28292c;
  align-items: center;
  justify-content: center;
  padding: 5px;
  display: flex;
}

.t3-team-banner-container {
  height: auto;
}
.sweet-alert > div {
  background: transparent !important;
}

.sweet-alert > h2 {
  color: var(--base-color) !important;
  margin-top: 8px !important;
}


.sweet-alert.has-background > div {
  background: white !important; /* Change to the solid color you need */
}

.sweet-alert > div div {
  background: transparent !important;
}

.sweet-alert .reminder-red {
  background: var(--warning-info-bg) !important;
}
.show-1250{
    display: none;
}
.provider-category-container{
  display: flex;
  align-items: center;
  gap: 10px;
  color: #45FF8B;
  font-size: 14px;
  font-weight: 700;
  background: var(--game-list-tab-bg);
  border-radius: 12px;
  padding: 10px;
  overflow: auto;
}
.provider-category-container>div{
  height: 60px;
  max-width: 143px;
  min-width: 143px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--game-list-tab-btn);
  border-radius: 8px;
  cursor: pointer;
  position: relative;
}
.provider-category-container>div.all-item{
  width: 90px;
}
.provider-category-container>div.active{
  background: var(--game-list-tab-btn-active);
  border: var(--game-list-tab-btn-active-border);
}

.provider-category-container > div > .v2-um-tag.game-category-label {
    left: 0;
    top: 0;
    border-radius: 8px;
    background-color: black;
    opacity: 0.6;
}

.provider-category-container > div > .v2-um-tag-icon {
    position: absolute;
    /* top: 20px; */
    /* left: 15px; */
    width: 40px;
    height: 40px;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}


input#add-account-number::-webkit-outer-spin-button,
input#add-account-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* ////////media query//////// */
@media (max-width: 1650px) {
    .home-referral-container .t3-team-banner-code-box{
        left: 15%;
    }
}
@media (max-width: 1600px) {
    .home-referral-container .t3-team-banner-code-box{
        left: 5%;
    }
}
@media (max-width: 1450px) {
    .home-referral-container .t3-team-banner-code-box{
        left: 15px;
    }
}
@media (max-width: 1400px) {
    /* .home-referral-container {
        height: auto;
        border-radius: 8px;
    }
    .home-referral-container .t3-team-banner-img-bg {
        height: 50%;
    }
    .home-referral-container .t3-team-banner-code-box {
        max-width: unset;
        left: 50%;
        transform: translate(-50%, 0);
        top: auto;
        bottom: 00px;
    } */
    .home-referral-container .img-teamBanner{
        width: 112%;
    }
    .home-referral-container .t3-team-banner-code-box{
        left: 2%;
    }
}
@media (max-width: 1300px) {
    .home-referral-container .t3-team-banner-code-box{
        left: 1%;
    }
}
@media (max-width: 1250px) {
    .show-1250{
        display: block;
    }
    .hide-1250{
        display: none;
    }
  .t3-team-banner-code-box {
    max-width: unset;
    left: 50%;
    transform: translate(-50%, 0);
    top: auto;
    bottom: 00px;
  }
  .home-referral-container .t3-team-banner-code-box {
    max-width: unset;
    left: 50%;
    transform: translate(-50%, 0);
    top: auto;
    bottom: 00px;
  }

  .t3-team-banner-img-bg {
    height: 50%;
  }

  .t3-team-banner-container {
    height: auto;
  }

  .home-referral-container {
    height: auto;
    border-radius: 20px;
  }

  .t3-team-banner-code-box {
    position: relative;
    /* margin-top: -10px; */
    border-radius: 8px;
    padding: 20px;
  }

  .t3-team-banner-coin {
    display: none;
  }
  .t3-team-banner-code-box .t3-team-copy-btn {
    border-radius: 8px;
  }
  .t3-team-banner-container {
    border-radius: 8px;
    margin-top: 30px !important;
  }
  .t3-team-banner-code-box .t3-team-banner-details {
    border-radius: 8px;
  }
  #team-section .t3-team-banner-img-bg {
    height: unset;
  }
  #team-section .t3-team-banner-code-box {
    transform: unset;
    max-width: unset;
    width: 100%;
    padding: 0px;
    margin-top: 0px;
  }
  #team-section .t3-team-banner-container {
    margin-top: 0px !important;
    background: var(--referral-container-bg);
    background-image: unset !important;
    min-height: unset;
  }
  #team-section .t3-team-banner-img-bg{
    background-image: unset !important;
  }
  /* .t3-team-second-total-box > .first{
    grid-template-columns: 1fr 120px;
  } */
}

@media (max-width: 1199px) {
  .t3-team-second-section {
    flex-direction: column;
    row-gap: 20px;
  }
  .t3-custom-promotion-list-box .t3-promotion-list-box {
    grid-template-columns: repeat(3, 1fr);
  }
  .t3-team-first-section {
    flex-direction: column;
  }
  .t3-team-second-total-box {
    margin-top: 0px !important;
  }
  .t3-team-first-section > .second {
    margin-top: 20px;
  }
  #team-section .t3-team-banner-container {
    min-height: 200px;
  }
  .vip-promotion-banner-box {
    flex-direction: column;
  }
  .vip-promotion-banner-box > div {
    width: 100%;
  }
  .vip-promotion-banner-box > div > div {
    min-height: 150px;
    background-size: contain;
  }
  .t3-game-category-list {
    overflow-x: auto;
  }
}

@media (max-width: 1050px) {
  /* .t3-providers-parent-box {
    display: block;
  } */

  /* .t3-providers-box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
    grid-gap: 2px;
    width: 100%;
  } */
  .t3-vip-top-box {
    gap: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: unset;
  }
}

@media (max-width: 991px) {
  .t3-category-game-list-container {
    padding: 0px 5px;
  }

  .t3-game-list-box {
    grid-template-columns: repeat(4, minmax(20%, 1fr));
  }

  /* .t3-game-list-box .t3-game-list-image-box {
    height: 75%;
  } */

  .t3-footer .t3-top-footer {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  .t3-footer .t3-top-footer > .first {
    grid-row: 2;
    border-top: 1px solid #383838;
    padding-top: 20px;
    margin-top: 20px;
    gap: 16px;
    justify-content: center;
  }

  .t3-footer .second {
    text-align: center;
    margin: auto;
    max-width: 400px;
    grid-row: 1;
  }

  .t3-custom-game-list-box .t3-game-list-box {
    grid-template-columns: repeat(4, 1fr);
  }

  .t3-header .drop-down-language button img {
    margin-right: 0;
  }

  .t3-header .drop-down-language button svg {
    display: none;
  }

  .t3-header .drop-down-language button {
    border-radius: 50%;
    height: 36px;
  }

  .t3-footer .t3-top-footer .second .t3-footer-sm-box {
    justify-content: center;
  }

  .t3-team-fifth-section {
    grid-template-columns: 100%;
    row-gap: 20px;
  }

  .t3-custom-game-list-box .t3-game-list-box {
    grid-template-columns: repeat(4, 1fr);
  }

  .t3-custom-promotion-list-box .t3-promotion-list-box {
    grid-template-columns: repeat(1, 1fr);
  }

  #game-lobby-new .t3-game-list-box {
    grid-template-columns: repeat(4, 1fr);
  }

  .t3-team-downline-img {
    width: 100%;
  }
  .t3-h2-white {
    font-size: 16px;
  }
  .vip-tab-buttons {
    gap: 10px;
    justify-content: space-between;
    overflow: auto;
    padding-bottom: 10px;
  }
  .vip-tab-buttons > div {
    /* max-width: 80px; */
    min-width: 105px;
    border-radius: 8px;
  }
  .vip-tab-buttons h4 {
    font-size: 16px !important;
  }
  .vip-tab-buttons button{
    font-size: 16px;
    width: 90%;
    padding: 4px;
  }
  .vip-tab-buttons > div > .image {
    max-width: 50px;
  }
  .vip-promotion-banner-box > div > div {
    min-height: 100px;
    background-size: 148px;
    padding-right: 20px;
  }
  .vip-promotion-banner-box h4 {
    font-size: 16px;
  }
  .vip-promotion-banner-box button {
    height: 35px;
    font-size: 14px;
    margin-top: 10px !important;
  }
  .vip-col-2 {
    flex-direction: column;
    gap: 0px;
  }
  .rebate-accordion-box > .second {
    display: none !important;
  }
  .rebate-accordion-box > .first {
    width: 100% !important;
  }
}

@media (max-width: 900px) {
  .t3-header-profile-box {
    display: none;
  }

  .t3-header-apk-banner{
    display: none;
  }

  .home-announcement-modal-content-box .details {
    font-size: 14px;
  }

  #t3-login-register-modal.home-announcement-modal .t3-lr-modal-body {
    padding: 20px;
  }

  .t3-header-balance-deposit-box {
    /*display: none; KT: better show because now we going to show Wallet / Coins only*/
  }

  .t3-header-profile-box {
    max-height: 36px;
  }

  .t3-game-category-list {
    overflow-x: auto;
  }

  #game-lobby-new .t3-game-list-box {
    grid-template-columns: repeat(3, 1fr);
  }

  .t3-game-loading {
    grid-column: 4/1;
  }

  .t3-custom-game-list-box .t3-game-list-box {
    grid-template-columns: repeat(3, 1fr);
  }

  .t3-scroll-item b {
    display: none;
  }

  .t3-side-menu {
    display: none;
  }

  .t3-content-container .left {
    display: none;
  }

  .t3-content-container .right {
    width: 100%;
    /* padding: 20px; */
  }

  .t3-content-container .right.active {
    width: 100%;
    padding: 15px 15px;
  }

  .t3-footer {
    padding: 20px 5px 0;
  }

  .t3-header > .first .burger {
    display: none !important;
  }

  .t3-header > .first .t3-logo {
    display: block !important;
    width: auto !important;
  }

  #t3-login-register-modal .t3-lr-modal-body .left {
    display: none;
  }

  #t3-login-register-modal .t3-lr-modal-body {
    grid-template-columns: unset;
  }

  #t3-login-register-modal .fail-alert {
    bottom: unset;
    width: 100%;
    top: 60px;
    left: 0;
  }

  .modal-backdrop.show {
    width: 100%;
    height: 100%;
  }

  .t3-header > .first .t3-logo .img2 {
    display: block;
  }

  .t3-header > .first .t3-logo .img1 {
    display: none;
  }

  .t3-team-third-section .t3-team-third-details {
    grid-template-columns: 70px 1fr;
  }

  .t3-team-third-section .t3-team-third-details > .second {
    font-size: 14px;
  }
  .t3-team-second-income-box {
    grid-template-columns: 1fr;
  }
  /* .t3-team-second-total-box > .first {
    grid-template-columns: 1fr 1fr;
  } */

  /* .t3-team-banner-container {
    margin: 0px -20px;
  } */

  .t3-custom-input-text {
    font-size: 1rem;
  }

  .t3-settings-withdrawal-box > div {
    flex-direction: column;
    height: 100%;
  }

  .t3-settings-withdrawal-box .press {
    width: 100%;
    margin-top: 10px;
  }

  .t3-settings-withdrawal-box > div .t3-custom-light-btn {
    width: 100%;
    /* height: 30px; */
  }

  .t3-settings-withdrawal-box > div .t3-custom-gold-btn {
    width: 100%;
    /* height: 30px; */
  }

  .t3-custom-title {
    font-size: 1rem;
    line-height: 1.5;
  }

  .t3-game-item-count-box {
    font-size: 0.875rem;
  }

  .t3-header > .first.active {
    width: auto;
  }

  .t3-team-second-percent-box {
    flex-direction: column;
  }

  .home-referral-container .t3-team-banner-code-box .t3-team-copy-btn {
    height: 50px;
    /* margin-top: 0px; */
  }

  .ul-news-list {
    grid-template-columns: 1fr;
  }

  .home-referral-button-group-box {
    margin-top: 0px !important;
  }

  .share-referral-media-box .image {
    width: 55px;
    height: 55px;
    padding: 12px;
  }

  .t3-new-promotion-list .item {
    height: 130px;
  }

  .promo-content {
    top: 12%;
    font-size: 14px;
  }

  .promo-container .promo-content {
    font-size: 14px;
  }

  #t3-custom-modal.modal-custom-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  #t3-custom-modal.modal-custom-fullscreen .modal-content {
    min-height: 100%;
    border: 0;
    border-radius: 0;
  }
  #t3-custom-modal.modal-custom-fullscreen .modal-content .modal-header{
    border-radius: 0;
  }

  .t3-custom-modal .modal-header {
    padding: 15px;
  }
  .t3-custom-modal .modal-header .close svg {
    width: 16px;
  }
  .t3-custom-modal .modal-header .modal-title {
    font-size: 1.1rem;
  }
  .t3-team-btn {
    margin-top: 15px !important;
  }
  .t3-team-second-percent-box > div > div {
    font-size: 15px;
  }
  .t3-accordion-item > .head > .first {
    font-size: 15px;
    align-items: center;
  }
  .t3-accordion-item > .head {
    height: 50px;
  }
  .t3-accordion-item.active > .details > .title-box {
    font-size: 14px;
  }
  .t3-accordion-item.active > .details > div {
    font-size: 14px;
    gap: 5px;
  }
  .t3-team-second-total-box > .first .details span {
    text-align: center;
  }
  .t3-team-banner-container {
    padding: 15px;
    border-radius: 8px;
  }
  .t3-team-first-section > .second {
    padding: 15px !important;
    border-radius: 8px !important;
  }
  .t3-team-second-section > div {
    padding: 15px;
    border-radius: 8px;
  }
  .t3-team-second-section > div {
    padding: 15px;
    border-radius: 8px;
  }
  .t3-team-bottom-box {
    padding: 20px 15px;
    border-radius: 8px;
  }
  .t3-team-bottom-box > div {
    flex-direction: column;
    text-align: center;
    padding: 0px;
  }
  .t3-team-second-total-box {
    border-radius: 8px;
  }
  .t3-team-second-income-box > div {
    border-radius: 8px;
  }
  .t3-team-btn {
    border-radius: 8px;
  }
  .t3-team-second-percent-box > div {
    border-radius: 8px;
  }

  .t3-vip-top-box .t3-team-treasure-box {
    display: flex;
    justify-content: center;
  }
  .t3-vip-top-box .t3-team-treasure-box > div {
    flex: 1 1 auto;
    /* min-width: 75px; */
    /* max-width: 75px; */
  }
  .t3-vip-top-box > div {
    padding: 20px 15px;
  }
  .vip-bottom-content-box {
    padding: 20px 15px;
  }
  .t3-h3 {
    font-size: 16px;
  }
  .vip-details-list-box > div > .head {
    font-size: 14px;
  }
  .vip-details-list-box > div > .details {
    font-size: 14px;
  }
  .vip-details-list-box > div {
    gap: 5px;
  }
  .custom-gray-box {
    padding: 20px 15px !important;
  }

  .t3-custom-modal-container .custom-body{
    padding: 30px 20px 120px !important;
  }

  .t3-content-container {
    margin-bottom: 0px;
    padding-top: 56px;
  }
  
  .t3-game-list-item {
    /* cursor: pointer; */
    min-height: 100px;
  }

  .t3-vip-label-box > .second{
    max-width: 50%;
  }

  .t3-settings-vip-box .t3-vip-user-image-box > .t3-vip-user-image img{
    max-width: 63px;
    min-width: 63px;
  }
  .t3-vip-bar{
    height: 8px;
  }
 
  .vip-tab-buttons button{
    width: 90%;
    padding: 4px;
  }
  .vip-tab-buttons{
    gap: 10px
  }
  #team-section .t3-team-banner-container {
    padding: 0px;
    border-radius: 8px;
  }
}

@media (max-width: 900px) {
  .show-767 {
    display: block;
  }

  .hide-767 {
    display: none !important;
  }

  html {
    min-width: var(--html-min-width);
    background-image: var(--theme-img-bg-base) !important;
    background-color: var(--base-bg) !important;
  }

  .t3-header {
    font-size: 1rem;
    padding: 10px;
    height: 56px;
  }

  .t3-header > .second {
    column-gap: 10px;
    font-size: 0.875rem;
  }

  .t3-header-balance-deposit-box .t3-header-deposit {
    padding: 8px 10px;
  }

  /*.t3-bottom-nav {
    position: fixed;
    bottom: -2px;
    left: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    background: #1b1b1b;
    z-index: 2000;
  }*/

  .page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .content {
    flex: 1;
    padding-bottom: 68px; 
  }

  .t3-bottom-nav {
    position: fixed;
    bottom: 0; 
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    background: var(--nav-footer-bg);
    z-index: 2000;
    height: 68px;
    color: var(--nav-footer-color);
  }

  .t3-bottom-nav .t3-bn-icon {
    width: 25px;
    height: 25px;
    margin-bottom: 0;
  }

  .t3-bottom-nav > div {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .t3-withdraw-modal-body,
  .t3-deposit-modal-body {
    margin-bottom: 10%;
    padding: 16px 10px 12px !important;
  }

  .t3-header .dropdown-menu {
    transform: translate3d(-99px, 45px, 0px) !important;
  }
  .provider-category-container>div{
    height: 40px;
    max-width: 90px;
    min-width: 90px;
    padding: 0px 15px;
  }
  .vip-details-list-box {
    overflow: auto;
  }
  .vip-rebate-details-list-box {
    overflow: auto;
  }
/*  .vip-details-list-box > div{
    width: 400px;
  }*/

  .t3-sweet-alert-container .sweet-alert {
    margin-top: 0 !important;
  }

  .t3-sweet-alert-container .sweet-alert > p {
      padding-bottom: 10px;
  }
}

@media (max-width: 600px) {
  .t3-vip-container .custom-gray-box {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  /* .vip-details-list-box > div > .details {
    display: none;
  } */
  /* .vip-details-list-box > div > .details.active {
    display: block;
    border: 0;
    background: none;
  } */

  .vip-details-list-box {
    padding: 15px 8px;
    overflow: auto;
  }

  .vip-rebate-details-list-box {
    padding: 1px 5px;
    overflow: auto;
  }

  .vip-details-list-table{
    max-width: 600px;
    table-layout: auto;
    padding: 0 5px;
  }
}

@media (max-width: 500px) {
  .t3-team-treasure-container {
    overflow: auto;
    margin: auto;
  }
  .t3-vip-top-box .t3-team-treasure-box {
    justify-content: flex-start;
  }
  .bonus-claimed-gif-box {
    width: 70px !important;
    z-index: 1;
  }
  #t3-login-register-modal.modal-dialog-centered{
    align-items: flex-start !important;
  }
}

.glow {
  background-color: #45ff8b;
  border-radius: 50%;
  box-shadow: 0 0 5px #45ff8b; /* Initial box shadow, representing the glow color */
  animation: glowAnimation 1s infinite cubic-bezier(0.25, 0.46, 0.45, 0.94); /* Use cubic-bezier for smoothness */
  border: 1px solid #45ff8b;
}

@keyframes glowAnimation {
  0% {
    box-shadow: 0 0 5px #45ff8b;
  }

  50% {
    box-shadow: 0 0 8px #45ff8b; /* Adjust the glow size as needed */
  }

  100% {
    box-shadow: 0 0 5px #45ff8b;
  }
}

/**LIVE CHAT START**/
#chatroom {
  z-index: 1;
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  top: 71px;
  bottom: 43px;
  /* background-color: #252525; */
  background-color: var(--chat-bg);
  background-size: cover;
  background-position: center;
  color: var(--base-color);
  padding-top: 10px;
}

.t3-custom-livechat-list-box {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 55px);
  padding-bottom: 35px;
}

.t3-custom-livechat-title {
  color: #45ff8b;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  margin: 12px auto;
  text-align: left;
}

.t3-livechat-list-box {
  text-align: left;
}

.t3-livechat-message-row {
  margin-bottom: 2px;
}

.t3-livechat-message-container {
  padding-left: 64px;
  padding-right: 60px;
  padding-top: 20px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  -webkit-user-select: text;
}

.t3-livechat-message-container.out {
  align-items: flex-end;
}

.t3-livechat-message-wrapper {
  min-width: 40%;
  max-width: 65%;
  position: relative;
  flex: none;
  font-size: 13px;
  line-height: 19px;
  color: var(--chat-message-color);
  border-radius: 7.5px;
}

.t3-live-chat-message-tail {
  left: 5px;
  position: absolute;
  top: 15px;
  z-index: 100;
  display: block;
  width: 15px;
  height: 10px;
  background: var(--chat-message-box);
  transform: rotate(45deg);
}

.t3-live-chat-message-tail.out {
  right: -5px !important;
  left: unset;
}

.t3-live-chat-message-profile-wrapper {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  left: -50px;
  position: absolute;
}

.t3-live-chat-message-profile {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #000;
  color: #ffffff;
  overflow: hidden;
  margin-left: 10px;
}

.t3-live-chat-message-alphabet {
  font-size: 1.5rem;
  line-height: 1rem;
  display: flex;
  justify-content: center;
}

.t3-live-chat-message-alphabet > svg {
  position: relative;
	top: 4px;
	opacity: 0.5;
}

.t3-live-chat-message-alphabet > img {
	position: relative;
  width: 120%;
  top: 12px;
}

.t3-livechat-message {
  /* background: #3c3c3c; */
  background: var(--chat-message-box);
  color: var(--chat-message-color);
  box-shadow: 0 1px 0.5px rgba(11, 20, 26, 0.13);
  position: relative;
  z-index: 200;
  border-radius: 7.5px;
  margin-left: 10px;
}
.t3-team-first-section {
  display: flex;
  column-gap: 20px;
}
.t3-team-first-section > div > .first {
  background: #282828;
  border-radius: 20px;
  padding: 20px 20px 23px;
  height: auto !important;
}
.t3-team-first-section > div {
  /* flex: 1 1; */
  flex: 50%;
  overflow: hidden;
}
.t3-team-first-section > .second {
  background: var(--base-container-bg);
  border-radius: 20px;
  padding: 20px 20px 23px;
}
.t3-team-btn {
  background: var(--base-sub-button);
  border-radius: 0.75rem;
  color: var(--base-sub-button-color);
  font-size: 1.125rem;
  font-weight: 700;
  height: 55px;
  margin-top: 16px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.custom-gray-box {
  background: var(--base-container-bg);
  border-radius: 0.75rem;
  padding: 20px;
}
.rebate-accordion-box {
  display: flex;
  gap: 20px;
}
.rebate-accordion-box > .first {
  flex: 1 1;
  min-width: 60%;
}
.rebate-accordion-box > .second {
  flex: 1 1;
  display: flex;
  align-items: start;
}
@media (max-width: 1300px) {
  .t3-livechat-message-wrapper {
    max-width: 75%;
  }
}

@media (max-width: 900px) {
  .t3-livechat-message-wrapper {
    max-width: 95%;
  }

  .t3-livechat-message-container {
    padding-left: 53px;
    padding-right: 19px;
  }

  .footer-container {
    margin-bottom: 47px;
  }

  .content.content-full-width {
    padding-bottom: 20px;
  }
}

.t3-livechat-message-container.out {
  padding-left: 19px;
}

.t3-livechat-content {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 10px;
  padding: 8px 10px 8px 12px;
  box-sizing: border-box;
  -webkit-user-select: text;
}

.t3-livechat-username {
  display: inline-flex;
  max-width: 100%;
  font-size: 12.8px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 4px;
}

.t3-livechat-username > span {
  min-height: 0px;
  flex: 0 1 auto;
  padding-left: 2px;
  margin-left: -2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  visibility: visible;
  color: var(--base-highlight);
}

.t3-livechat-username.out > span {
  color: var(--base-link);
}

.t3-livechat-text-content > div {
  position: relative;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.t3-livechat-text-content img {
  max-width: 200px;
  max-height: 200px;
  cursor: pointer;
}

.t3-livechat-text-content > div > span {
  min-height: 0px;
  visibility: visible;
  -webkit-user-select: text;
}

.t3-livechat-text-time {
  display: inline-flex;
  font-size: 0.6875rem;
  padding-right: 0px;
  line-height: 15px;
  padding-left: 4px;
  vertical-align: middle;
  padding-bottom: 0;
  padding-top: 0;
  float: right;
}

.t3-livechat-text-time > span {
  flex-shrink: 0;
  flex-grow: 0;
}

.t3-livechat-message-end {
  width: fit-content;
  bottom: 4px;
  align-self: flex-start;
  left: 6px;
  z-index: 200;
  display: flex;
  position: relative;
}

.t3-livechat-input-container {
  background: var(--chat-input-container);
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  flex: none;
  order: 3;
  width: 100%;
  min-height: 55px;
  padding: 0;
  bottom: 0;
  position: absolute;
  z-index: 300;
}

.t3-livechat-input-wrapper {
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  max-width: 100%;
  min-height: 55px;
  padding: 2px 10px;
}

.t3-livechat-input {
  position: relative;
  flex: 1;
  width: 100%;
  min-width: 0;
  min-height: 60px;
}

.t3-livechat-input-full {
  display: flex;
  align-items: center;
}

.t3-livechat-input-left {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 0;
  color: yellow;
  will-change: width;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

.t3-livechat-input-attachment-container {
  display: flex;
  align-items: center;
}

.t3-livechat-input-attachment-wrapper {
  margin: 0;
  position: relative;
  flex: none;
  height: 100%;
  border-radius: 50%;
}

.t3-livechat-input-attachment-button {
  display: flex;
  align-items: center;
}

.t3-livechat-input-attachment-button circle{
  fill: var(--chat-attach)
}

.t3-livechat-input-attachment-button.disabled {
  opacity: 0.3;
}

/*.t3-livechat-input-attachment-button-icon {
    width: 24px;
    height: 24px;
    background: #abc;
    position: relative;
}

.t3-livechat-input-attachment-button-icon:before {
    content:'';
    display: block;
    position: absolute;
    width: 3px;
    height: 20px;
    background: red;
    top: 2px; 
    left:10px;
}

.t3-livechat-input-attachment-button-icon:after {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 3px;
    background: red;
    top: 10px;
    left: 2px;
}*/

.t3-livechat-input-right {
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 0;
}

.t3-livechat-input-textarea-container {
  box-sizing: border-box;
  flex: 1 1 auto;
  width: inherit;
  min-width: 0;
  min-height: 46px;
  padding: 5px 12px;
  margin: 5px 8px;
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
  background-color: var(--chat-input-box);
  border-color: var(--chat-input-border);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  outline: none;
  will-change: width;
  display: flex;
  /* height: 36px; */
}

.t3-livechat-input-textarea-wrapper {
  width: 100%;
  position: relative;
  display: flex;
}

.t3-livechat-input-textarea {
  min-height: 36px;
  width: 100%;
  user-select: text;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 1rem;
  line-height: 1.4em;
  background: transparent;
  border: none;
  resize: none;
  overflow: hidden;
  color: var(--base-color);
  height: 100%;
}

.t3-livechat-input-textarea::placeholder {
  color: #747474;
}

.t3-livechat-input-textarea:focus-visible {
  outline: none;
}

.t3-livechat-input-send,
.t3-livechat-input-voice {
  width: 40px;
  min-width: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 0;
  margin-bottom: 9px;
}

.t3-livechat-input-voice,
.t3-livechat-input-send {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s;
}

.t3-livechat-input-right.send-voice .t3-livechat-input-voice,
.t3-livechat-input-right.send-message .t3-livechat-input-send {
  opacity: 1;
  visibility: visible;
  position: absolute;
  right: 0;
  bottom: 0;
  width: fit-content;
}

.t3-livechat-input-send circle{
  fill: var(--chat-send);
}
.t3-livechat-input-voice circle{
  fill: var(--chat-voice);
}
.t3-livechat-input-send.disabled {
  opacity: 0.3;
}

.t3-livechat-input-voice {
  /* margin-right: 8px; */
}

.t3-livechat-input-voice .disabled {
  opacity: 0.3;
}

.active-tag {
  background: linear-gradient(
    260deg,
    rgba(159, 0, 0, 1) 0%,
    rgba(245, 45, 105, 1) 100%
  );
  color: #fff;
  border: 1px #e95959 solid;
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  position: absolute;
  padding: 10px 20px;
  border-radius: 25px;
  top: -20px;
  left: 10px;
  font-weight: bold;
  display: none;
}

.active-tag.actived {
  display: block !important;
}
@media (min-width: 901px) {
  #chatroom.active{
    left: 220px;
    width: calc(100% - 220px)
  }

  #chatroom,
  .t3-loading-logo {
    left: 85px;
    width: calc(100% - 85px);
    bottom: 0;
    top: 58px;
  }
  .t3-custom-livechat-list-box {
    padding-bottom: 20px;
  }
}
@media (min-width: 800px) {
  /* .home-referral-button-group-box .show-767{
    display: none !important;
  } */
  .provider-category-container::-webkit-scrollbar{
    height:7px;
  }
  .provider-category-container::-webkit-scrollbar-thumb{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: var(--game-list-tab-scroll)
  }
}

@media (max-width: 900px) {
  .mt-5 {
    margin-top: 30px !important;
  }

  .t3-game-category-box {
    justify-content: center;
  }

  .t3-livechat-input-container {
    bottom: 23px;
  }

  .active-tag.actived {
    font-size: 10px;
    padding: 3px 10px;
    top: -11px;
  }
}

@media (max-width: 375px) {
  /* .t3-header-profile-box {
    display: none;
  } */
}

.t3-livechat-input-voice.recording {
  width: 100%;
}

.t3-livechat-input-voice.recording > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-direction: row;
  width: 100%;
}

@keyframes wavesAni {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}

.t3-audio-wave {
  width: 100%;
  overflow: hidden;
  height: 40px;
  display: block;
  position: relative;
  margin: 2px 10px 0;
}

.t3-audio-inner-wave {
  height: 35px;
  width: 500%;
  background: url(../img/icon/wave.png) repeat-x transparent !important;
  animation-name: wavesAni;
  animation-iteration-count: infinite;
  animation-duration: 30s;
  animation-timing-function: linear;
}

.t3-audio-cancel {
  margin-right: 8px;
}

.t3-recording-countdown {
  font-size: 18px;
  font-weight: bold;
}

.red-note {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 10px;
  column-gap: 10px;
  color: rgba(255, 255, 255, 0.9);
}

.red-note > .first {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.red-note > .first > .first {
  min-width: 30px;
  text-align: center;
}

.red-note .font14 {
  color: #ff493f;
}

.img-teamBanner {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .t3-bottom-nav-item img {
  max-height: 60px;
  width: 100%;
} */

.t3-bottom-nav-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 68px;
}

.t3-bottom-nav-item.active{
  background-color: var(--nav-footer-active);
  color: var(--nav-footer-active-color);
}

.t3-bottom-nav-item .icon-container{
position:relative;
display:inline-block;
padding-top:15px;
padding-bottom:5px;
}

.bottom-badge{
  position:absolute;
  top:5px;
  left:20px;
  border-radius: 50%;
  background-color: #ff0000;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  line-height: 17px;
  width: 17px;
  height: 17px;
  text-align: center;
  padding: 0;
  /* margin: 5px 0 0 4450px; */
}


/* .t3-bottom-nav-item .icon-container{
  width: 100%;
  height: 68px; 
  background-repeat: no-repeat;
  background-position: center;
  /*background-size:30px;*/

/* .t3-bottom-nav-item .icon-container.active{
  background-color: var(--theme-color-nav-menu-active);
} */

.badge {
  border-radius: 50%;
  background-color: #ff0000;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  line-height: 17px;
  width: 17px;
  height: 17px;
  text-align: center;
  padding: 0;
  margin: 5px 0 0 30px;
}

.main-pulse {
  animation: pulse 0.6s infinite alternate;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

.sidenav-unread-count {
  position: absolute;
  top: -8px;
  right: 30px;
  margin: 0;
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.sidenav-unread-count.badge{
  border-radius: 50%;
  background-color: #ff0000;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  line-height: 17px;
  width: 17px;
  height: 17px;
  text-align: center;
  padding: 0;
  margin: 5px 0 0 30px;
}


.left .sidenav-unread-count.live-chat {
  top: -39%;
  right: 10px;
}
.left.active .sidenav-unread-count.live-chat {
  top: -15px;
  right: 0;
}
.t3-sidemenu-item .sidenav-unread-count {
  top: 24%;
  right: 10px;
  /* transform: translateY(-50%); */
}

@media (min-width: 1251px) {
  .home-referral-container .t3-team-banner-code-box {
    top: 50%;
    transform: translate(0, -50%);
  }
}

.unavailable-image {
  -webkit-filter: grayscale(100%) brightness(63%);
  filter: grayscale(100%) brightness(63%);
  opacity: 0.8;
}

.t3-deposit-box-wrapper {
  background: var(--numberpad-bg);
  padding: 20px 15px 20px 15px;
  border-radius: 8px;
}

.t3-deposit-box-wrapper label, .bank-selection label  {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: var(--base-color);
}

.t3-input-withdrawal label {
  font-size: 14px;
  font-weight: 400;
}

/* .t3-wthdraw-input-style-box > .second input::placeholder {
  color: rgba(170, 170, 170, 1);
} */

.yellow-note-title {
  color: var(--base-link);
  font-size: 14px;
  font-weight: 700;
}

.t3-withdrawal-input-text,
.t3-deposit-input-text {
  border-radius: 8px;
  background: var(--amount-input-box) !important;
  border: 2px none;
  outline: none;
  height: 40px;
  padding: 0px 20px;
  font-size: 16px;
  width: 100%;
  color: var(--amount-input-box-color) !important;
  font-weight: 400;
}

.t3-withdrawal-input-text.disabled,
.t3-deposit-input-text.disabled {
  pointer-events: none;
}

.t3-withdrawal-input-text::-webkit-input-placeholder,
.t3-deposit-input-text::-webkit-input-placeholder {
  /* Edge */
  color: rgba(135, 135, 135, 1);
}

.t3-withdrawal-input-text:-ms-input-placeholder,
.t3-deposit-input-text:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(135, 135, 135, 1);
}

.t3-withdrawal-input-text::placeholder,
.t3-deposit-input-text::placeholder {
  color: rgba(135, 135, 135, 1) !important;
}

.t3-withdrawal-input-text[readonly],
.t3-withdrawal-input-text:disabled,
.t3-withdrawal-option-select[readonly],
.t3-withdrawal-option-select:disabled,
.t3-deposit-input-text[readonly],
.t3-deposit-input-text:disabled,
.t3-deposit-option-select[readonly],
.t3-deposit-option-select:disabled {
  background: #aaaaaa !important;
  color: #262626 !important;
  opacity: 1 !important;
}

.t3-withdrawal-option-select,
.t3-deposit-option-select {
  border-radius: 8px;
  background: var(--base-input) !important;
  border: 2px none;
  outline: none;
  height: 40px;
  padding: 0px 20px;
  font-size: 16px;
  width: 100%;
  color: var(--base-input-placeholder);
  font-weight: 400;
}

/* Used to set the color of the primary text after select has value */
.t3-withdrawal-option-select:not(:invalid),
.t3-deposit-option-select:not(:invalid) {
  color: var(--base-input-color);
  opacity: 1; /* Ensure full visibility (default is 0.54) */
}

.t3-withdrawal-option-value,
.t3-deposit-option-value {
  color: var(--base-input-color);
}

.t3-withdrawal-option-value,
.t3-deposit-option-value {
  color: var(--base-input-color);
}

.t3-withdrawal-option-select.disabled::-ms-expand,
.t3-withdrawal-option-select.disabled::-webkit-inner-spin-button,
.t3-withdrawal-option-select.disabled::-webkit-outer-spin-button,
.t3-deposit-option-select.disabled::-ms-expand,
.t3-deposit-option-select.disabled::-webkit-inner-spin-button,
.t3-deposit-option-select.disabled::-webkit-outer-spin-button {
  display: none !important;
}

.t3-withdrawal-option-select:disabled,
.t3-deposit-option-select:disabled {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: none; /* Remove default arrow */
}

.mobile-home-latest-transactions-box > .body.empty-data {
  display: block !important;
  height: fit-content;
  padding: 10px;
}

.img-announcement {
  max-width: 100%;
  border-radius: 8px;
  display: inline-block;
}

.t3-withdraw-modal-body.bank-info,
.t3-deposit-modal-body.bank-info {
  background: var(--modal-container);
  margin: 20px 0;
  padding: 0 !important;
}

.bank-info-separator-line {
  border: none;
  height: 1px;
  background: #5d5d5d;
  margin: 0 16px;
}

.t3-withdraw-modal-body.bank-info .t3-reload-bank-list-box,
.t3-deposit-modal-body.bank-info .t3-reload-bank-list-box {
  margin-bottom: 0;
}

.t3-withdraw-modal-body.upload-receipt,
.t3-deposit-modal-body.upload-receipt {
  margin: 20px 0 !important;
  padding: 16px 0 12px !important;
}

.t3-rollover-sweet-alert-header .first {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: var(--base-color);
}

.t3-rollover-sweet-alert-body .title {
  font-size: 14px;
  font-weight: 700;
  color: var(--base-color);;
}

.t3-rollover-sweet-alert-body .amount {
  font-size: 14px;
  font-weight: 700;
  color: var(--theme-color-text-special);
}

.t3-rollover-sweet-alert-body .date {
  font-size: 12px;
  font-weight: 400;
  color: var(--base-color);
}

.t3-rollover-sweet-alert-body .highlight-info {
  font-size: 12px;
  font-weight: 400;
  color: var(--base-highlight);
}

.t3-rollover-sweet-alert-info {
  display: flex;
  align-items: center;
  text-align: left;
  gap: 20px;
}

/* ************* */
.t3-progress {
  width: 82px;
  height: 82px;
  line-height: 82px;
  background: none;
  box-shadow: none;
  position: relative;
}

.t3-progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid var(--setting-progress-bg);
  position: absolute;
  top: 0;
  left: 0;
}

.t3-progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.t3-progress .t3-progress-left {
  left: 0;
}

.t3-progress .t3-progress-bar {
  width: 100%;
  height: 100%;
  background: #333333;
  border-width: 5px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.t3-progress .t3-progress-left .t3-progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.t3-progress .t3-progress-right {
  right: 0;
}

.t3-progress .t3-progress-right .t3-progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}

.t3-progress .t3-progress-value {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #44484b;
  font-size: 16px;
  text-align: center;
  font-weight: 700;
  position: absolute;
}

.t3-progress .t3-progress-value span {
  font-size: 12px;
  font-weight: 400;
}

.t3-progress.green .t3-progress-bar {
  border-color: var(--setting-progress-bar);
  background-color: transparent;
}

.sweet-alert .t3-rollover-box {
  background: var(--modal-container) !important;
  padding: 20px;
  border-radius: 5px;
}

.t3-register-container {
  border-top: 1px solid rgba(93, 93, 93, 1);
  padding: 15px 0px;
  margin: 0;
}

.t3-register-container h5 {
  font-size: 14px;
  font-weight: 400;
  width: 0px;
  opacity: 0;
  height: 0px;
}

.t3-register-container.active h5 {
  width: 100%;
  opacity: 1;
  height: auto;
  margin-bottom: 15px;
}

.t3-register-container.active .t3-sm-box {
  opacity: 0;
  width: 0px;
  height: 0px;
}

.t3-register-container .t3-sm-box {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding-left: 25px;
  opacity: 1;
  width: 100%;
  height: auto;
}

.t3-register-container .t3-sm-box-active {
  opacity: 0;
  width: 0px;
  height: 0px;
}

.t3-register-container.active .t3-sm-box-active {
  display: flex;
  column-gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  flex-direction: unset;
  opacity: 1;
  width: 100%;
  height: auto;
  /* -webkit-animation: fadeIn 12s;
  animation: fadeIn 12s; */
}

.t3-register-container > div .first {
  width: 22px;
  min-width: 22px;
  max-width: 22px;
}

.t3-register-container.active > div .first {
  width: 28px;
  min-width: 28px;
  max-width: 28px;
}

.t3-register-container > div .t3-sm-item {
  cursor: pointer;
  margin: 0 2px;
}

@media (max-width: 991px) {
  .t3-lr-modal-body-social-mdedia {
    margin-bottom: 15%;
  }
}

#t3-login-register-modal .t3-lr-modal-body-social-mdedia {
  grid-gap: 10px;
  /* background-color: var(--modal-body); */
  display: flex;
  grid-template-columns: 40% 1fr;
  padding: 0px 20px 30px;
  border: 0;
  border-radius: 0px 0px 10px 10px;
  justify-content: center;
}

#t3-login-register-modal .t3-lr-modal-body.register {
  border-radius: 0px 0px 0px 0px !important;
}

.t3-team-treasure-box > .item.glow {
  cursor: pointer;
}
.tool-tip-with-text {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  width: auto
}
.bonus-claimed-gif-box {
  position: absolute;
  top: -30px;
  width: 135px;
  left: 50%;
  transform: translate(-50%, 0);
  display: none;
}
.bonus-claimed-gif-box.active {
  display: block;
}

@media (max-width: 991px) {
  .bonus-claimed-gif-box {
    top: -15px;
    width: 93px !important;
  }
}

.t3-custom-input-text-profile {
  border-radius: 0.5rem !important;
  background: var(--base-input) !important;
  border: 2px none !important;
  outline: none !important;
  padding: 0px 20px !important;
  font-size: 14px !important;
  width: 100% !important;
  color: var(--base-input-color) !important;
  font-weight: 300 !important;
}

.t3-custom-gold-btn {
  background: var(--setting-wallet-end-promo);
  box-shadow: 0 4px 8px rgba(29, 34, 37, 0.1);
  color: var(--setting-wallet-end-promo-color) !important;
  height: 45px;
  padding: 0px 25px;
  border-radius: 8px;
  outline: unset;
  border: 0px;
  font-weight: 700;
  text-align: center;
  line-height: 15px;
}

.t3-custom-gold-btn:disabled {
  opacity: 0.6;
}

.t3-settings-logout .t3-custom-gold-btn {
  display: flex;
  align-items: center;
  column-gap: 15px;
  height: 46px;
  margin: auto;
}

.t3-input-withdrawal .form-group{
  margin: 0;
}
/* .t3-input-withdrawal .error-msg{
  position: absolute;
} */

.t3-alink{
  color: var(--base-link);
  text-decoration: underline;

}

@media (min-width: 767px) {
  .t3-settings-withdrawal-box > div {
    align-items:center;
  }
}

.play-icon{
  color: var(--base-highlight);
}
.game-count{
  width: 20px;
  height: 20px;
  color: var(--base-highlight);
}

.payout-container{
  /* box-shadow: 0px 4px 10px 0px #00000040; */
  padding: 20px;
  border: var(--payout-border);
  box-shadow: var(--payout-box-shadow);
  border-radius: 12px;
  background: var(--payout-bg);
}
.payout-container .payout-list .item{
  background: var(--payout-item-bg);
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  margin: 0 15px 0 0;
  min-width: 165px;
  max-width: 200px;
}

.payout-container .payout-list div:focus-visible{
  outline: unset !important;

}
/* .payout-list .item img{
  width: 80px;
  height: 80px;
  margin: 0 auto;
} */


.payout-list .item .title {
  font-size: 14px;
  font-weight: 700;
  color: var(--payout-item-color);
  margin-top: 15px;
}
.payout-list .item .username{
  font-size: 14px;
  font-weight: 400;
  color: var(--payout-item-color);
}
.payout-list .item .amount{
  font-size: 20px;
  font-weight: 700;
  color: var(--payout-item-highlight);
}

.payout-container .gif{
  width: 50px;
  height: 50px;
}

.payout-container .slick-slider .slick-track{
  height: auto !important;
}

@media (max-width: 900px) {
  .payout-container{
    padding: 8px 10px;
    padding-bottom: 0;
  }
  .payout-container .payout-list .item{
    display: flex;
    margin: 0 0px 15px 0;
    gap: 10px;
    text-align: left;
    padding: 10px;
    min-width: unset;
    max-width: unset;
    min-height: 95px;
    max-height: 95px;
  }
  .payout-list .item img{
    width: 60px;
    height: 60px;
    margin: 0;
  }

  .payout-list .item .title {
    margin-top: 0px;
  }
  .payout-list .item .amount{
    font-size: 14px;
  }
}

.sweet-alert > div .info-box{
  background: var(--warning-info-bg) !important;
}
.info-box-text{
  color: var(--warning-info-color) !important;
  font-size: 12px;
}
.sweetalert-custom-buttons{
  display: flex;
  z-index: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* margin-top: 5px; */
  gap: 8px;
}
.t3-sweet-alert-container .sweet-alert .btn.btn-primary.promotion-confirm-alert-btn{
  background: rgba(107, 113, 128, 1) !important;
  color: #fff !important;
}
@media (max-width: 1100px) {
  /* .play-button{
    display: none;
  }
  .slider-item:hover .play-button{
    max-width: 50px;
    min-width: 50px;
    width: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
  } */
  
  .vip-details-list-box > div > .details {
      text-align: end;
  }
}
@media (max-width: 900px) {
  #chatroom{
    top: 55px;
  }
}
/* ///game image fix  */
.t3-game-list-box .t3-game-list-image-box .image .first .img-100,
.game-detail-description-container .game-detail-image .image .first .img-100,
.game-mobile-view-info-container .game-detail-image .image .first .img-100 {
  height: auto;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: unset; 
  object-position: unset; 
}

.t3-game-list-box .t3-game-list-image-box .image .first,
.game-detail-description-container .game-detail-image .image .first,
.game-mobile-view-info-container .game-detail-image .image .first {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 100%;
  position: relative;
  /* background-color: rgba(0,0,0,.2); */
}
.t3-game-list-box .t3-game-list-image-box .image,
.game-detail-description-container .game-detail-image .image,
.game-mobile-view-info-container .game-detail-image .image {
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: relative; */
}

@media (max-width: 900px) {
  .t3-game-list-box .t3-game-list-image-box .image{
    align-items: unset;
  }
  .t3-game-list-box .t3-game-list-image-box .image .first{
    width: fit-content;
  }
}

.payout-list-image{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
}
.payout-list-image img{
  max-width: 80px;
}
@media (max-width: 900px) {
  .payout-list-image{
    height: auto !important;
  }
  .payout-list .item .payout-list-image img{
    height: auto !important;
    width: 100% !important;
  }
  .payout-list-details{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .vip-details-list-table>tbody > tr > td.head {
    font-size: 14px;
    font-weight: 600;
    width: 75px;
    padding: 0px 0px 0px 0px;
  }
  .vip-details-list-table>tbody > tr > td.details {
    font-size: 14px;
    min-width: 30px;
  }
}
.game-grid-adapt-full-width{
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  min-height: 250px;
}
@media (max-width: 500px) {
  .sweet-alert .t3-rollover-box {
    padding: 10px;
  }
  .t3-rollover-sweet-alert-info{
    gap: 10px;
  }
  .t3-progress {
    width: 70px;
    height: 70px;
    line-height: 72px;
  }
}
.sweet-alert  {
  animation: none !important;
}

.breadcrumb-container {
    max-width: fit-content;
    padding: 3px 30px;
    background: var(--breadcrumb-bg);
    line-height: 36px;
    align-items: center;
    display: flex;
    border-radius: 8px;
}

.breadcrumb-container > nav span:last-child {
    font-size: 14px;
    font-weight: 700;
    color: var(--breadcrumb-highlight);
}

.back-button {
    max-width: fit-content;
    padding: 3px 20px;
    background: var(--game-wallet-info-bg);
    line-height: 36px;
    display: none;
    border-radius: 8px;
}

.back-button .back-button-text{
    color: var(--base-color);
    font-size: 12px;
    margin: 0;
}
.breadcrumb-list{
  display: flex;
  gap: 5px
}
.breadcrumb-list .vicon {
  width: 16px;
  margin-left: 5px;
}
@media (max-width: 900px) {
    .back-button {
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .breadcrumb-container{
        display: none;
    }
}

.theme-menu-icon {
  color: var(--base-highlight);
  fill: var(--base-highlight);
}

.gradient-stop-1 {
  stop-color: var(--nav-side-chat-btn-gradient);
}

.gradient-stop-2 {
  stop-color: var(--nav-side-chat-btn-gradient2);
}

.game-content-container {
    padding: 0;
    background: var(--base-container-bg);
    border: 1px solid rgb(0, 0, 0, .2);
    border-radius: 8px;
    width: 100%;
    min-height: 560px;
    outline: 0;
    -webkit-overflow-scrolling: touch;
}

.t3-custom-game-iframe-modal {
    background: #000;
}

.t3-custom-game-iframe-modal .modal-header {
    height: 54px;
}

.t3-custom-game-container {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    border: 1px solid rgb(0, 0, 0, .2);
    width: 100%;
    height: calc(100vh - 120px);
    outline: 0;
}

.ios-view-game-container {
    height: calc(100vh - 150px);
}

.game-container-header {
    background: var(--base-container-bg);
    padding: 20px;
    height: 60px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
    align-items: center;
}

.game-container-header .game-title {
    color: var(--base-color);
    font-size: 18px;
    font-weight: 600;
}

.game-content-container .game-iframe-content-container {
    background: black;
    padding-top: 46%;
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.t3-custom-game-container .game-iframe-container,
.t3-custom-game-container .game-iframe-container .game-iframe,
.game-iframe-content-container .game-iframe-container,
.game-iframe-content-container .game-iframe-container .game-iframe {
    width: 100%;
    height: 100%;
    border: 0;
}

.game-iframe-content-container .game-iframe-container,
.game-iframe-content-container .game-iframe-container .game-iframe{
    position: absolute;
    top: 0;
}

.game-iframe-content-container .sweet-alert-container{
    position: absolute;
    top: 36%;
}

.t3-custom-game-container .sweet-alert-container > div > div,
.game-iframe-content-container .sweet-alert-container > div > div {
    position: relative !important;
    z-index: 800 !important;
}

.t3-custom-game-container .sweet-alert-container .sweet-alert-custom-text,
.game-iframe-content-container .sweet-alert-container .sweet-alert-custom-text {
    bottom: unset;
}

.t3-custom-game-container .sweet-alert-container {
    width: 90vw;
}

.iframe-close-modal-btn {
    display: none;
}

.ios-view-game-container .game-iframe-container .game-iframe {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    border: none;
    opacity: 1;
}

@media (orientation: landscape ) {
  .t3-custom-modal .modal-body{
    min-height: unset;
  }
/*  .t3-custom-game-iframe-modal .modal-header{
    display: none;
  }*/
  .t3-custom-game-container {
/*   height: 100vh;
   width: 100vw;*/
     height: calc(100vh - 60px); 
    
  }

   .t3-custom-game-iframe-modal .modal-header .modal-title {
     display: flex;
     justify-content: center;
     align-items: center
   }

    .t3-custom-game-iframe-modal .ios-view-modal-header {
        display: none;
    }

    .iframe-close-modal-btn {
        display: block !important;
        position: absolute;
        min-width: 30px;
        min-height: 30px;
        color: white;
        background: #99a4b0cc;
        border: 0px;
        text-align: center;
        line-height: 15px;
        border-radius: 10px;
        top: 20px;
        left: 20px;
    }

    .iframe-close-modal-btn svg {
        width: 1rem;
    }
}

.game-container-footer {
    background: var(--TR-2nd);
    padding: 10px;
    height: 40px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.game-container-footer > .first {
    color: var(--base-color);
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.game-container-footer > .first .vicon:hover,
.game-container-footer > .second .vicon:hover {
    color: #fff;
}

.game-container-footer .vicon {
    width: 20px;
    height: 20px;
    color: #ffffff73;
    cursor: pointer;
}

.game-container-footer > .first .star-full,
.game-info-box > .second .star-full {
    fill: var(--base-highlight) !important;
}

.game-detail-content,
.game-latest-bet-details,
.game-mobile-view-content-container {
    padding: 20px;
    background: var(--base-container-bg);
    border: 1px solid rgb(0, 0, 0, .2);
    border-radius: 12px;
    outline: 0;
}

.game-detail-content .game-detail-content-title > a {
    color: var(--base-color);
    text-decoration: underline;
}

.game-detail-content-table-tab,
.game-latest-bet-details-table-tab {
    background: var(--game-tab);
    display: flex;
    align-items: center;
    width: 400px;
    border-radius: 10px;
}

.single-tab {
    width: 200px;
}

.game-detail-content-table-tab > button,
.game-latest-bet-details-table-tab > button {
    flex: 1 1;
    border: 0px;
    background: var(--game-tab);
    font-size: 14px;
    height: 40px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--game-tab-color);
}

.game-detail-content-table-tab > button:first-child,
.game-latest-bet-details-table-tab > button:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px
}

.game-detail-content-table-tab > button:last-child,
.game-latest-bet-details-table-tab > button:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.game-detail-content-table-tab > button.active,
.game-latest-bet-details-table-tab > button.active {
    background: var(--game-tab-active);
    color: var(--game-tab-active-color) !important;
    font-weight: 700;
    border-radius: 8px;
}

.game-winners-table,
.game-latest-bet-details-table {
    width: 100%;
    background: #313131;
}

.game-latest-bet-details-table thead {
    position: sticky;
    top: 0;
}

.game-winners-table thead > tr > th,
.game-latest-bet-details-table thead > tr > th {
    /* border-bottom: 1px solid #3a3a3a; */
    background: var(--game-table-header);
    font-size: 14px;
    font-weight: 600;
    padding: 8px 8px;
    text-align: center !important;
    text-align: center !important;
    position: relative;
    height: 55px;
    color:var(--game-table-header-color);
}

.game-winners-table thead > tr > th:first-child,
.game-latest-bet-details-table thead > tr > th:first-child {
    text-align: start !important;
    padding: 10px 15px;
}

.game-winners-table thead > tr > th:last-child,
.game-latest-bet-details-table thead > tr > th:last-child {
    text-align: end !important;
    padding: 10px 15px;
}

.game-winners-table tbody > tr.game-winners-table-row {
    background: var(--game-table-body);
}

.game-latest-bet-details-table tbody {
    max-height: 450px;
    overflow-y: auto;
}

.game-latest-bet-details-table tbody > tr {
    height: 50px;
}

.game-winners-table tbody > tr > td,
.game-latest-bet-details-table tbody > tr > td {
    font-size: 13px;
    /* font-weight: 600; */
    padding: 8px 0px;
    text-align: center;
}

.game-winners-table tbody > tr:not(:last-child) > td,
.game-latest-bet-details-table tbody > tr:not(:last-child) > td {
    border-bottom: 4px solid var( --game-table-border);
}

.game-winners-table tbody > tr > td.username-column-data,
.game-latest-bet-details-table tbody > tr > td.username-column-data {
    color: #ffd10c;
}

.game-winners-table tbody > tr > td:first-child,
.game-latest-bet-details-table tbody > tr > td:first-child {
    text-align: start;
    padding: 10px;
}

.game-winners-table tbody > tr > td.win-amount-data,
.game-latest-bet-details-table tbody > tr > td.win-amount-data {
    color: var(--game-win-amount);
    font-weight: 700;
    text-align: end;
    padding: 10px;
}

.game-winners-table tbody,
.game-latest-bet-details-table tbody{
    background: var(--game-table-body);
}

.recommended-game-title {
    font-size: 18px;
    color: #fff;
}

.t3-custom-show-more-btn {
    background: var(--theme-color-show-more-btn);
    box-shadow: 0 4px 8px rgba(29, 34, 37, 0.1);
    color: #fff;
    height: 45px;
    padding: 0px 40px;
    border-radius: 8px;
    outline: unset;
    border: 0px;
    font-weight: 700;
    text-align: center;
    line-height: 15px;
    cursor: pointer;
}

.t3-custom-show-more-btn:disabled{
    cursor: not-allowed;
}

.game-latest-bet-detail-footer .t3-custom-show-more-btn {
    display: flex;
    align-items: center;
    column-gap: 15px;
    height: 46px;
    margin: auto;
}

.game-latest-bet-detail-footer .record-limit-text {
  text-align: center;
  font-size: 14px;
}

.game-latest-bet-details-title{
    font-size: 15px;
    font-weight: 700;
    color: var(--base-color);
}

.game-detail-description-container {
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.game-detail-description-container .game-detail-image {
    border-radius:15px;
    overflow: hidden;
    height: 200px;
    width: 200px;
}

.game-detail-description-box {
    align-self: start;
    padding: 10px 0 10px 15px;
    max-width: 82%;
}

.game-detail-description-title {
    display: flex;
    align-items: center;
}

.game-detail-description-title .game-name {
    font-size: 16px;
    font-weight: 700;
    color: var(--base-color);
    text-align: unset;
    font-family: unset;
}

.game-detail-description-title .game-provider {
    font-size: 14px;
    margin-left: 20px;
}

.game-detail-description-title .game-provider > a,
.game-info-box .game-title-2 > a {
    color:var(--base-color);
    text-decoration: underline;
}

.game-detail-description-info {
    margin-top: 30px;
    font-size: 13px;
}

.content-box {
    max-height: 560px;
    overflow: auto;
    border-radius: 6px;
    border: unset;
    /* border: 1px solid rgb(0, 0, 0, .2); */
}

.game-mobile-view-info-container {
    display: flex;
}

.game-mobile-view-info-container .game-detail-image {
    max-height: 45%;
    max-width: 45%;
    min-height: 140px;
    min-width: 140px;
    display: flex;
    border-radius: 15px;
    overflow: hidden;
    aspect-ratio: 1/1;
    align-self: start;
}

.game-mobile-view-info-container .game-title{
    font-size: 16px;
    font-weight: 700;
}

.game-mobile-view-info-container .game-title-2 {
    font-size: 14px;
}
    

.game-info-box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 20px;
}

.game-info-box > .second {
    color: var(--base-color);
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.game-selection-button{
    display: flex;
    justify-content: space-evenly;
}

.t3-custom-play-game-btn,
.t3-custom-free-play-btn {
    color: var(--base-main-button-color);
    box-shadow: 0 4px 8px rgba(29, 34, 37, 0.1);
    height: 45px;
    padding: 0px 30px;
    border-radius: 8px;
    outline: unset;
    border: 0px;
    font-weight: 700;
    text-align: center;
    line-height: 15px;
    width: 150px;
}

.t3-custom-free-play-btn {
    background: #F6C722;
}

.t3-custom-play-game-btn {
    background: var(--base-main-button);
}

.game-mobile-view-content-container {
    display: none;
}

.iframe-exit-fullscreen-btn {
    display: block !important;
    position: absolute;
    min-width: 30px;
    min-height: 30px;
    color: white;
    background: #99a4b0cc;
    border: 0px;
    text-align: center;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    top: 20px;
    left: 20px;
}

.home-balance-com-box.rollover.promotion>.hbc-box {
  gap: 8px;
}

@media (max-width: 900px) {
    .game-detail-content-table-tab, 
    .game-latest-bet-details-table-tab {
        width: auto;
    }

    .single-tab {
        width: 50%
    }

    .game-detail-description-container .game-detail-image {
        display: none;
        min-width: unset;
        padding: 0;
    }

    .game-detail-description-box {
        max-width: unset;
    }

    .game-winners-table thead,
    .game-winners-table tbody,
    .game-latest-bet-details-table thead,
    .game-latest-bet-details-table tbody {
        white-space: nowrap;
    }

    .game-detail-description-title {
        flex-direction: column;
        align-items: unset;
    }

    .game-mobile-view-content-container {
        display: block;
    }

    .game-content-container {
        display: none;
    }

    .game-detail-description-title .game-provider {
        margin-left: unset;
        margin-top: 5px;
    }
}

@media (max-width: 400px) {
 .hbc-box .second>.theme-icon-size-20{
  width: 15px;
  height: 15px;
 }
 .home-balance-com-box .hbc-label{
  font-size: 10px;
 }
}
@media (max-width: 374px) {
  .home-balance-com-box .hbc-label{
    white-space: unset;
  }

  .t3-header > .second,
  .t3-settings-withdrawal-box > div .t3-custom-gold-btn,
  .t3-settings-withdrawal-box > div .t3-custom-light-btn {
    font-size: 10px;
  }
}


.upload{
  color: #fff;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-bottom: 0;
  margin-left: 0.5rem;
}

.upload .sub-label{
  color: #94a3b8
}

.cursor-unset{
  cursor: unset !important;
}

.t3-table-container {
  position: relative;
  overflow: auto;
}

@media (max-width: 360px) {
  .t3-header {
    height: 50px; /* Reduce header height */
  }

  .t3-header > .first {
    column-gap: 10px; /* Decrease gap between items */
  }

  .t3-header > .second {
    column-gap: 5px; /* Reduce spacing between items */
  }

  .t3-header .drop-down-language button {
    padding: 4px 6px; /* Make language dropdown button smaller */
  }

  .t3-header .drop-down-language button img {
    width: 16px; /* Scale down flag icon size */
    height: auto;
  }

  .t3-promotion-content.banner-layout .promo-image .promo-content .title,
  .promotion-container.banner-layout .promo-content .title{
    font-size: 16px;
  }
  .t3-promotion-content.banner-layout .promo-image .promo-content .description,
  .promotion-container.banner-layout .promo-content .description{
    font-size: 14px;
  }

  .mobile-latest-deposit-withdraw-box > div {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0; /* Allows for equal width */
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 350px) {
  .t3-lr-button-box span, .t3-lr-button-box b {
    font-size: 11px !important;
  }
}

.t3-side-menu-container.active .white-svg-chat-icon {
  position: absolute;
  left: 20px;
  top: 5px;
}

.t3-side-menu-container .white-svg-chat-icon {
  position: absolute;
  left: 8px;
  top: 1px;
}
.promo-thumbnail-claim-btn {
  width: 100%;
  position: absolute;
  bottom: 20px;
  padding: 0px 5px;
}
.promo-thumbnail-claim-btn button{
  background: #F6C722;
  border-radius: 8px;
  color: #000000;
  font-weight: 700;
  border: none;
  padding: 4px 20px;
  font-size: 18px;
  box-shadow: 0px 0px 12px 3px rgb(239 215 126 / 78%);
  animation: glow 1s infinite alternate;
  width: 100%;
  max-width: 100px;
}
@media (max-width: 991px) {
  .promo-thumbnail-claim-btn button{
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .t3-livechat-text-content audio{
    max-width: 235px;
  }
  .t3-input-withdrawal{
    flex-direction: column;
    gap: 5px;
  }
  .t3-input-withdrawal .col-3,
  .t3-input-withdrawal .col-9{
    width: 100%;
    max-width: 100%;
    padding: 0px;
  }
}
@media (max-width: 374px) {
  #t3-login-register-modal .t3-lr-modal-body{
    padding: 20px 10px;
    margin: 0 0 70px;
  }
}
@media (max-width: 360px) {
  #chatroom {
    top: 50px;
  }
  .t3-lr-button-box>div{
    flex: 1 1;
  }
  .t3-lr-button-box .t3-custom-light-btn{
    padding: 0px 15px;
    font-size: 14px;
    width: 100%;
  }
  .t3-lr-button-box .t3-custom-gray-btn{
    padding: 0px 15px;
    font-size: 14px;
    width: 100%;
  }
  .t3-custom-slick-arrow > div{
    width: 26px;
  }
  .t3-game-list-title-with-arrows-box .second{
    gap: 5px;
  }
  .t3-custom-title{
    font-size: 12px;
  }
  .t3-game-item-count-box{
    font-size: 12px;
    padding: 8px 8px;
  }
  .t3-deposit-amount-list-box > .item > .first{
    font-size: 14px;
  }
  .t3-deposit-amount-list-box > .item{
    height: 50px;
  }
  .t3-accordion-item.active > .details > .title-box{
    font-size: 11px;
    font-weight: 500;
  }
  .t3-accordion-item.active > .details > div{
    font-size: 12px;
  }
  .t3-vip-detail{
    justify-content: center;
    /* margin-bottom: 12px; */
  }
  .t3-rollover-sweet-alert-body .title{
    font-size: 12px;
    font-weight: 500;
  }
  .t3-rollover-sweet-alert-body .amount{
    font-size: 12px;
    font-weight: 500;
  }
  .t3-settings-vip-box{
    flex-direction: column;
  }
  .t3-vip-label-box{
    margin-top: 12px;
  }
  .t3-input-withdrawal{
    flex-direction: column;
  }
  .t3-withdrawal-input-text, .t3-deposit-input-text{
    font-size: 14px;
  }
  .mobile-home-balance-box > .head .title{
    font-size: 16px;
  }
  .mobile-home-balance-box > .body .first > .amount{
    font-size: 18px;
  }
  .mobile-home-balance-box > .body .second button{
    font-size: 14px;
  }
}
.provider-category-container>.item>.first>img{
  max-height: 55px;
}
@media (max-width: 900px) {
  .provider-category-container>.item>.first>img {
    max-height: 35px;
  }
  .provider-category-container > div > .v2-um-tag-icon{
    width: 30px;
    height: 30px;
  }
}

.banner-layout .active-tag{
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 4px 16px;
  border-radius: 5px;
  /* display: inline-block !important; */
  left: unset;
  width: fit-content;
}
.promotion-container.banner-layout .promotion-item-box img{
  border-radius: 8px;
}


.daily-checkin-button{
  display: flex;
  align-items: center;
  gap: 6px;
  background: var(--daily-checkin-button);
  padding: 10px 0px 10px 6px;
  margin: 10px 5px;
  border-radius: 5px;
  height: 55px;
  cursor: pointer;
}

.daily-checkin-button.mobile{
  margin:0;
  height: 52px;
  padding: 10px 0px 10px 20px;

}

.daily-checkin-button img{
  width: 45px;
}

.daily-checkin-button .title{
  color: #F8D840;
  margin-bottom: 0;
}

.daily-checkin-button.mobile .title{
  font-size: 1.25rem;
}


.daily-checkin-button .subtitle{
  font-size: 9px;
  margin-top: 5px;
}

.daily-checkin-button.mobile .subtitle{
  font-size: 12px
}
.daily-checkin-announce{
  display: flex;
  align-items: center;
  color: var(--base-highlight);
  margin-bottom: 10px;
}
.daily-checkin-announce svg path{
  fill:var(--base-highlight);
}
.daily-checkin-remaining span{
  color: var(--base-highlight);
}
.daily-checkin-row{
  display: grid;
  grid-template-columns: repeat(7,1fr);
  margin-top: 30px;
  gap: 10px;
}


.daily-checkin-item .vicon.daily{
  width:35px;
}
.daily-checkin-announce .vicon.refresh-time{
  width:17px;
  margin-right: 8px;
}
.daily-checkin-item .daily-checkin-amount{
  font-weight: 700;
  color: var(--daily-checkin-amount-color);
  font-size: 12px;
}
.daily-checkin-item .daily-checkin-label{
  padding: 3px 13px;
  background: var(--daily-checkin-day-label);
  border-radius: 50px;
  color: var(--daily-checkin-day-label-color);
  margin-bottom: 0px;
  font-size: 11px;
  font-weight: 700;
}


.daily-checkin-item{
 background: var(--daily-checkin-bg);
 border: 1px solid var(--daily-checkin-border);
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 min-width: 87px;
 min-height: 87px;
 border-radius: 5px;
}

.vicon.daily-success{
  width: 80px;
  margin: 15px auto;
}

.daily-checkin-item.active{
  background: var(--daily-checkin-active-bg);
  border: 1px solid var(--daily-checkin-active-border);
  box-shadow: var(--daily-checkin-avtive-box-shadow)
 }

 .daily-checkin-item.active .vicon.daily path{
  fill: var(--daily-checkin-active-icon);
}

.daily-checkin-item.active .daily-checkin-amount{
  color: var(--daily-checkin-active-amount-color);

}

.daily-checkin-item.active .daily-checkin-label{
  background: var(--daily-checkin-active-day-label);
  color: var(--daily-checkin-active-day-label-color);
}

.daily-checkin-item.claimed{
  background: var(--daily-checkin-claimed-bg);
  border: 1px solid var(--daily-checkin-claimed-border);
  position: relative;
 }

 .daily-checkin-item.claimed .vicon.daily path{
  fill: var(--daily-checkin-claimed-icon);
}
.daily-checkin-item.claimed .daily-checkin-amount{
  color: var(--daily-checkin-claimed-amount-color);

}
.daily-checkin-item.claimed .daily-checkin-label{
  background: var(--daily-checkin-claimed-day-label);
  color: var(--daily-checkin-active-day-label-color);
}


.daily-checkin-item.claimed .daily-tick{
  position: absolute;
  right: 0;
  width: 80px;
}

.daily-month{
  text-align: center;
  margin-top: 25px;
  font-weight: 700;
  color: inherit;
}

@media (max-width: 760px) {
  .daily-checkin-row{
    grid-template-columns: repeat(4,1fr);
  }
  .daily-checkin-item{
    min-width: 42px;
    min-height: 70px;
  }
  .daily-checkin-item.claimed .daily-tick{
    position: absolute;
    right: 0;
    width: 70px;
  }
}
.t3-btn-link{
  background: transparent;
  border: none;
  font-weight: 700;
  text-decoration: underline;
  color: inherit;
}
.t3-side-menu .left .t3-sidemenu-box .daily-checkin-title {
  width: 0px;
  /* height:0px; */
  opacity: 0;
}
.t3-side-menu .left.active .t3-sidemenu-box .daily-checkin-title {
  width: 135px;
  /* height: 36px; */
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
.t3-spinwheel-list {
  background: #282828;
  border-radius: 20px;
  padding: 20px 20px 7px;
  margin-top: 30px;
}
.t3-spinwheel-token-container {

}
.t3-spinwheel-token-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #363636;
  padding: 10px 0;
}
.t3-spinwheel-token-item:last-child {
  border-bottom: none;
}
.t3-spinwheel-token-item.empty,
.t3-spinwheel-token-item.loading {
  border-top: 1px solid #363636;
  padding: 20px 0;
  justify-content: center;
}

.t3-spinwheel-token-item.empty img{
  width: 300px;
  height: 230px;
}
.t3-spinwheel-token-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.t3-spinwheel-token-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.t3-spinwheel-token-item svg {
  margin-right: 6px;
}
.t3-spinwheel-token-item svg path {
  fill: #FFFFFF;
}
.t3-spinwheel-token-spin {
  align-items: center;
  background: var(--base-main-button);
  /* box-shadow: 0 4px 8px rgba(29, 34, 37, 0.1); */
  color: var(--base-main-button-color);
  border-radius: 0.5rem;
  /* padding: 8px 24px; */
  border:unset;
  width: 100%;
  height: 40px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.t3-spinwheel-token-spin.completed {
  background: none;
  padding: 8px 0;
  color: var(--base-sub-button);
  user-select: none;
}
.t3-spinwheel-token-code {
  font-size: 12px;
  color: #99a4b0cc;
}
.t3-spinwheel-campaign-name {
  color: #99a4b0cc;
}

.notify-sweetalert .sweet-alert{
  min-width:43em !important;
  min-height: 27em !important;
}

.vicon.warning{
  width: 80px;
  color: #ff9e10;
  margin: 15px auto;
}

.spin-wallet-container {
  padding: 16px;
  background: var(--modal-container);
  border-radius: 8px;
}

.spin-wallet-container .wallet-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.spin-wallet-container .wallet-info {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.record-button {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;

  background: var(--base-main-button-third);
  box-shadow: 0 4px 8px rgba(29, 34, 37, 0.1);
  color: var(--base-main-button-third-color) !important;
  height: 45px;
  padding: 0px 20px;
  border-radius: 8px;
  outline: unset;
  border: 0px;
  font-weight: 700;
  text-align: center;
  line-height: 15px;
}
.record-button .theme-icon-size-20{
  color:var(--base-main-button-third-color)
}

.spin-wheel-card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.spin-wheel-card {
  background:var(--spin-wheel-bg);
  border-radius: 8px;
  font-size: 14px;
  padding: 16px;
  display:grid;
  row-gap: 0.7rem;
}
.spin-wheel-card .spin-wheel-title{
  color: var(--spin-wheel-title);
  font-weight: bold;
}
.spin-wheel-card .time-container{
  background: var(--spin-wheel-time);
  padding: 2px 10px;
  border-radius: 12px;
  display:flex;
  align-items: center;
  gap: 0.5em;
  color:var(--spin-wheel-time-color);
}

.spin-wheel-card .time-container .vicon{
  width: 15px;
  color:var(--spin-wheel-time-icon);
}

@media (max-width: 768px) {
  .spin-wallet-container,.spin-wheel-card-container {
    grid-template-columns: 1fr;
  }

  .wallet-row {
    flex-direction: column; /* Stack items vertically */
    align-items: stretch; /* Make button full width */
  }

  .record-button {
    width: 100%; /* Full width for the button */
  }

}

#t3-ewallet-accounts .wallet-info {
  display: flex;
  flex-direction: column;
}

#t3-ewallet-accounts .wallet-info .phone-number {
  font-weight: 600;
  display: flex;
}

#t3-ewallet-accounts .wallet-info .phone-number .verify-otp {
  font-weight: normal;
}

#t3-ewallet-accounts .wallet-info .account-name {
  font-size: 12px;
  color: #888;
  line-height: 1em;
}

.t3-side-menu .left.active .t3-sidemenu-box .music-player {
    height: 90px;
    justify-content: center;
    padding-left: 0;
    cursor: auto;
}

.t3-mobile-side-menu-content .t3-sidemenu-box .music-player {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    cursor: auto;
    border-radius: 5px;
    background: var(--music-player-bg);
}

.t3-side-menu .left .t3-sidemenu-box .music-player {
    display: flex;
    align-items: center;
    min-height: 40px;
    cursor: pointer;
    position: relative;
    height: 45px;
    padding-left: 15px;
    margin: 5px;
    transition: all 0.2s;
    border-radius: 5px;
    background: var(--music-player-bg);
    justify-content: unset;
}

.t3-mobile-side-menu-content .t3-sidemenu-box .music-player .music-player-container,
.t3-side-menu .left.active .music-player-container {
    width: 100%;
    display: block;
}

.t3-side-menu .left .music-player-container {
    display: none;
}

.music-player-controls-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 10px 0px 10px;
}

.t3-mobile-side-menu-content .t3-sidemenu-box .music-player .music-player-controls-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 0 10px 0px 10px;
}

.control-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--music-player-control-btn-color);
    width: 30px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
}

.control-button:hover {
    background: var(--music-player-control-btn-hover);
}

.custom-body .music-player .control-button.active,
.control-button.active {
    background: var(--music-player-icon-color);
}

.custom-body .music-player .control-button.active:hover,
.control-button.active:hover {
    background: var(--music-player-control-active-btn-hover);
}

.music-player-contorl-btn-icon {
    width: 20px;
    height: 20px;
    fill: var(--music-player-icon-color) !important;
}

.t3-mobile-side-menu-content .t3-sidemenu-box .music-player .music-player-contorl-btn-icon {
    width: 17px;
    height: 17px;
}

.control-button.active .music-player-contorl-btn-icon {
    fill: var(--music-player-control-btn-color) !important;
}

.music-track-info {
    background: #222222;
    height: 25px;
    margin: 10px 10px 0px 10px;
    font-size: 12px;
    color: var(--music-player-info-text-color);
    display: flex;
    align-items: center;
    border-radius: 3px;
}

.music-track-info > svg {
    fill: var(--music-player-info-icon-color) !important;
    height: 10px;
    width: 10px;
    margin-left: 5px;
    margin-right: 5px;
}

.music-track-info .track-title {
    width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.t3-mobile-side-menu-content .t3-sidemenu-box .music-player .music-track-info > svg {
    height: 12px;
    width: 12px;
    margin-left: 7px;
    margin-right: 10px;
}

.audio-time {
    margin-left: auto;
    padding-right: 5px;
}

.t3-mobile-side-menu-content .t3-sidemenu-box .music-player .first,
.t3-side-menu .left.active .t3-sidemenu-box .music-player .first {
    display: none;
}
.t3-side-menu .left .t3-sidemenu-box .music-player .first {
    width: 32px;
    transition: all 0.2s;
}

.t3-side-menu .left .t3-sidemenu-box .music-player .first > svg {
    height: 25px;
    width: 25px;
    fill: var(--music-player-icon-color) !important;
}

.custom-body .music-player {
    background: var(--music-player-custom-modal-bg);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    padding: 10px;
}

.custom-body .music-player-container {
    width: 100%;
    display: block;
    padding: 5px;
}

.custom-body .music-player .music-track-info {
    background: unset;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-family: Poppins; 
    color: var(--music-player-custom-modal-title-color);
    margin: 5px 0 10px 5px;
}

.custom-body .music-track-info > svg {
    height: 15px;
    width: 15px;
    margin-right: 10px;
}

.custom-body .music-player-seekbar-container{
    margin-top: 10px;
}

.custom-body .music-player-seekbar {
    display: flex;
    justify-content: center;
    padding: 10px 0;
}

.custom-body .custom-seekbar {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    width: 100%; /* Adjust the width as needed */
    height: 5px; /* Height of the seek bar */
    background: #434343; /* Default background color */
    border-radius: 5px; /* Rounded corners */
}

/* Style the progress track (colored part of the bar) */
.custom-seekbar::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px; /* Match the bar height */
    background: linear-gradient(to right, #2E905B calc(var(--progress, 0) * 100%), #434343 calc(var(--progress, 0) * 100%) );
    border-radius: 5px;
}

.custom-seekbar::-ms-fill-lower {
    background: #2E905B; /* Progress color for IE */
}

.custom-seekbar::-ms-fill-upper {
    background: #434343; /* No-progress color for IE */
}

/* Style the thumb (the draggable circle) */
.custom-seekbar::-webkit-slider-thumb {
    -webkit-appearance: none; /* Remove default styling */
    width: 15px; /* Thumb width */
    height: 15px; /* Thumb height */
    background: #45FF8B; /* Thumb color */
    border-radius: 50%; /* Make it circular */
    cursor: pointer; /* Change cursor to pointer on hover */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Optional shadow for thumb */
    position: relative; /* Position adjustment for smooth drag */
    margin-top: -4px; /* Offset to center the thumb */
    z-index: 2; /* Ensure the thumb is above the track */
}

/* Active/hover effect for thumb */
.custom-seekbar::-webkit-slider-thumb:hover {
    background: #45a049;  /*Darker green on hover */
}

.custom-body .audio-time-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: var(--music-player-info-text-color);
    line-height: 21px;
    font-weight: 400;
    font-family: Poppins;
}

.custom-body .music-player .control-button {
    width: 40px;
    height: 40px;
    background: var(--music-player-custom-modal-control-btn);
}

.custom-body .music-player .control-button:hover {
    background: var(--music-player-custom-modal-control-btn-hover)
}

.custom-body .music-player .music-player-controls-container {
    margin-top: 19px;
    justify-content: center;
    gap: 20px;
}

.music-playlist-container {
    margin-top: 15px;
}

.music-playlist-tab {
    background: #222;
    display: flex;
    align-items: center;
    border-radius: 10px;
}

.music-playlist-tab > button {
    flex: 1 1;
    border: 0px;
    border-top: 1px solid;
    border-bottom: 1px solid;
    background: var(--theme-color-bg-tab);
    height: 40px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3A3A3A;
}

.music-playlist-tab > button > div {
    color: var(--music-player-info-text-color);
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
}

.music-playlist-tab > button.active {
    background: var(--modal-tab-active);
}

.music-playlist-tab > button.active > div {
    color: var(--modal-tab-active-color);
    font-weight: 700;
}

.music-playlist {
    background: var(--music-player-custom-modal-bg);
    padding: 20px 16px;
    border-radius: 5px;
    height: 410px;
    max-height: 410px;
}

.music-playlist .music-track-container {
    max-height: 375px;
    overflow-y: auto;
}

.music-playlist .music-track-container::-webkit-scrollbar {
    width: 7px;
}

.music-playlist .music-track-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #484848
}

.music-playlist .music-track {
    background: var(--music-track-bg-color);
    height: 74px;
    padding: 15px;
}

.music-playlist .music-track:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.music-playlist .music-track:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.music-playlist .music-track .player-container {
    display: none;
}

.music-playlist .music-track .track-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.music-playlist .line {
    width: 100%;
    height: 0px;
    border-top: 1px solid var(--music-track-border-color);
}

.music-playlist .track-info .track-title {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
}

.music-playlist .track-info .track-title > svg {
    width: 15px;
    height: 15px;
    fill: var(--music-track-info-icon-color) !important;
}

.music-playlist .track-info .track-times {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: var(--music-track-info-time-color);
}

.music-playlist .track-info .track-times .track-played-time {
    color: var(--music-player-info-text-color);
}

.music-playlist .music-playlist-btn-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.music-playlist .music-track .music-playlist-btn-container .favorite-btn,
.music-playlist .music-track .music-playlist-btn-container .add-favorite-btn,
.music-playlist .music-track .music-playlist-btn-container .play-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background: var(--music-track-play-btn-bg-color);
}

.music-playlist .music-track .music-playlist-btn-container .add-favorite-btn {
    background: #434343;
}

.music-playlist .music-track .music-playlist-btn-container .favorite-btn {
    background: #45FF8B;
}

.music-playlist .music-track .music-playlist-btn-container .favorite-btn .add-track {
    width: 20px;
    height: 20px;
    fill: var(--music-track-bg-color) !important;
}

.music-playlist .music-track .music-playlist-btn-container .add-favorite-btn > svg {
    width: 25px;
    height: 25px;
    fill: var(--music-track-bg-color) !important;
    opacity: 1;
}

.music-playlist .music-track .music-playlist-btn-container .favorite-btn > svg {
    width: 25px;
    height: 25px;
    fill: var(--music-track-bg-color) !important;
}

.music-playlist .music-track .music-playlist-btn-container .play-btn > svg {
    width: 18px;
    height: 18px;
    fill: var(--music-player-icon-color) !important;
}

@media (max-width: 900px) {
    .custom-body .music-player .music-track-info {
        font-size: 13px;
        line-height: 20px;
    }

    .custom-body .music-player .music-player-controls-container {
        gap: 15px;
    }

    .custom-body .music-player .control-button {
        width: 30px;
        height: 30px;
    }

    .music-player-contorl-btn-icon {
        width: 15px;
        height: 15px;
    }

    .custom-body .audio-time-container{
        font-size: 12px;
        line-height: 15px;
    }

    .custom-body .music-player-container{
        padding: 0 5px;
    }

    .music-playlist {
        height: 425px;
        max-height: 425px;
        padding: 10px;
    }

    .music-playlist .music-track-container {
        max-height: 410px;
    }

    .music-playlist .music-track {
        height: 81px;
    }

    .music-playlist .track-info .track-title {
        font-size: 12px;
    }

    .music-playlist .track-info .track-title > svg {
        width: 13px;
        height: 13px;
    }

    .music-playlist .track-info .track-times {
        font-size: 11px;
    }

    .music-playlist .music-playlist-btn-container {
        align-self: flex-end;
    }

    .music-playlist .music-track .music-playlist-btn-container .favorite-btn,
    .music-playlist .music-track .music-playlist-btn-container .add-favorite-btn,
    .music-playlist .music-track .music-playlist-btn-container .play-btn {
        height: 25px;
        width: 25px;
    }

    .music-playlist .music-track .music-playlist-btn-container .play-btn > svg {
        width: 15px;
        height: 15px;
    }

    .music-playlist .music-track .music-playlist-btn-container .favorite-btn > svg {
       width: 20px;
       height: 20px;
    }
}

.referral-label-container{
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 15px;
  padding:10px;
  background-color: var(--base-container-body-bg);
  border-radius:8px;
  font-size: 14px;
  color:var(--base-color)
}

.referral-label-legend{
  width: 15px;
  height: 15px;
  margin-right:10px;
  border-radius:3px;

}

.t3-accordion-item table thead > tr > th {
  background: var(--accordion-body-bg) !important;
  color: var(--accordian-body-title) !important
}
.t3-accordion-item table thead > tr > th:after{
  background: transparent;
}
.t3-accordion-item table tbody{
  background: var(--accordion-body-bg) !important;
}


.t3-team-commission-section{
  background: var(--base-container-bg);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  column-gap:20px
}
.t3-rebate-section{
  display: flex;
  column-gap:20px
}
.t3-team-commission-total-box{
  background-color: var(--base-container-body-bg);
  border-radius:10px;
  padding:20px 15px;
  flex: 50% 1;
}

.t3-team-commission-total-box .title{
 font-size: 16px;
 color: var(--base-color);
 display: flex;
}


.t3-team-commission-total-box .title span{
  color: var(--base-link);
 }
.t3-rebate-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.t3-team-commission-row{
  display: flex;
  justify-content: space-between;
}
.t3-team-commission-total-box .details{
  font-size: 16px;
  color: var(--base-link);
  display: flex;
  font-weight: 700;
 }
 .t3-rebate-amount{
  font-size: 16px;
  color: var(--base-color);
  display: flex;
 }
 .t3-rebate-amount span{
  font-size: 16px;
  color: var(--base-link);
  font-weight: 700;
 }
 .t3-claim-comm-btn{
  background: var(--base-sub-button);
  color:var(--base-sub-button-color);
  border-radius:0.75rem;
  font-weight: 700;
  border: unset;
  font-size: 1.125rem;
  padding: 13px 24px;
 }
 button.t3-claim-comm-btn:disabled{
    opacity: 0.5;
 }
 @media (max-width: 900px){
  .t3-rebate-section,
  .t3-team-commission-section{
    flex-direction: column;
    gap: 15px;
  }
  .t3-rebate-amount
  .t3-rebate-row{
    flex-direction: column;
  }
  .t3-team-commission-row{
    flex-direction: column;
  }
  .t3-claim-comm-btn{
    margin-top: 10px;
  }
  .t3-team-commission-total-box .title,
  .t3-team-commission-total-box .details{
    justify-content: center;
  }


 }

 .reward-tab{
  background: var(--referral-tab);
  padding: 5px;
  border-radius: 8px;
  margin-top: 15px;
 }
 .reward-tab button{
    background: transparent;
    color: var(--referral-tab-color);
    padding: 12px 30px;
    border-radius: 8px;
    box-shadow: none;
    border: unset;
    font-weight: 700;
 }  

 .reward-tab button.active {
  background: var(--referral-tab-btn-active);
  color: var(--referral-tab-btn-active-color);
  padding: 12px 30px;
} 

.referral-login{
  background: var(--referral-login-box);
  border: 1px solid var(--referral-login-border);
  box-shadow: 0px 4px 30px 0px #38944380 inset;
  padding: 25px 0;
  border-radius: 8px;
  margin-top: 15px;
}

.referral-login-content{
  margin: 0 auto;
}
.referral-login-title{
  color:var(--referral-login-color);
  font-size: 22px;
  font-weight: 700;
  text-align: center;

}
.referral-login-btn{
  background: var(--base-main-button-four);
  box-shadow: 0px 4px 4px 0px #FFFFFF40 inset;
  border: unset;
  color: var(--base-main-button-four-color);
  margin-top:15px
}

.referral-banner{
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;
  margin-top: 15px;
}

.referral-banner-item{
  width: 100%;
  background: var(--referral-login-left);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  /* padding: 20px; */
  position: relative;
  overflow: hidden;
}
.referral-banner-item:before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  height: 100%;
  background: linear-gradient(to bottom right, var(--referral-login-left-before) 0%, transparent 70%); /* Adjust colors and angle */
  /* Create the angled shape */
  clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%); /* Adjust percentages for the angle */
}
.referral-banner-item:last-child{
  background: var(--referral-login-right);
}
.referral-banner-img{
  padding: 0px 20px 0px;
  z-index: 1;
}

.referral-banner-item:last-child:before{
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: unset;
  width: 40%;
  height: 100%;
  background: linear-gradient(to bottom right, var(--referral-login-right-before) 0%, transparent 70%); /* Adjust colors and angle */
  /* Create the angled shape */
  clip-path: polygon(0 0, 30% 100%, 100% 100%, 100% 0%) /* Adjust percentages for the angle */
}

.referral-banner-item img{
  width: 100%;
}
/* .referral-banner-item img.referral-mobile {
  display: none;
} */
.referral-banner-subtitle{
  color:var(--referral-login-color);
  font-size: 16px;
}
.referral-banner-title{
  color:var(--base-highlight);
  font-size: 22px;
  font-weight: 700;
}
.referral-banner-item:last-child .referral-banner-title{
  color:var(--referral-title)
}
.referral-banner-info{
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  text-align: right;
  padding-right: 20px;
  padding-left: 0px;

}

.referral-banner-item:last-child .referral-banner-info{
  text-align: left;
  padding-right: 0px;

  padding-left: 20px;

}
.referral-section{
  display: flex;
  gap: 10px;
  margin-top: 15px;
}
.referral-item{
  padding: 20px;
  border-radius: 8px;
  background: var(--referral-container-bg);
  flex: 1 1 50%;
}
.referral-msg{
  padding-bottom: 30px;
  border-bottom: 1px solid var(--referral-notice-color)
}
.referral-msg-title{
  font-size: 18px;
  font-weight: 700;
  color:var(--referral-container-title)
}
.referral-msg-descrip{
  margin-top: 20px;
  font-size: 14px;
  color:var(--referral-container-msg)
}
.referral-team{
  display: flex;
  gap: 10px;
  margin-top: 30px;
}
.referral-team .referral-team-item{
  flex: 1 1 50%;
  overflow: hidden;
}

.referral-team-input{
  padding: 13px 10px;
  background: var(--referral-input-box);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
}

.referral-team-input .first{
  max-width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.referral-team-input .second,
.referral-team-title{
  color:var(--referral-title);
}
.referral-team-input .vicon{
  width: 18px;
  height: 18px;
}

.referral-share{
  margin-top:30px;
  color:var(--referral-title);
  display: flex;
  align-items: center;
}
.referral-channel {
  cursor: pointer;
  width: 40px;
  background: var(--referral-channel);
  border-radius: 50%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.referral-channel img{
  width: 21px;
  height: 21px;
}

.referral-commission {
  background-color: var(--referral-bg);
  border-radius: 10px;
  padding: 20px 15px;
}
.referral-commission .title{
  color: var(--base-color);
  font-size: 16px;
  display: flex;
}
.referral-commission .details{
  font-size: 20px;
    color: var(--base-link);
    /* display: flex; */
    font-weight: 700;
}
.referral-row{
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px
}
.comm-notice{
  background:var(--referral-notice);
  border-radius: 8px;
  padding: 8px 10px;
}

.comm-notice svg path {
  fill: var(--game-title-highlight-color);
}
.comm-label{
  color: var(--base-color);
}
.comm-label span{
  color: var(--base-link);
}

.comm-table{
  margin: 20px 0 ;
  max-height: 500px;
  overflow: auto;
}

.comm-table .t3-custom-table thead > tr > th{
  background-color: var(--referral-table-header) !important;
}
.comm-table .t3-custom-table tbody{
  background: transparent;
}
.reward-section{
    background: var(--base-container-bg);
    border-radius: 8px;
    padding: 20px 20px 23px;
    /* max-height: 500px; */

}
.claim-comm-mobile{
  display: none;
}
@media(max-width:1024px){
  .referral-banner{
    
    grid-template-columns: auto;
    row-gap: 10px;
    
  }
  .referral-banner-title{
   
    font-size: 16px;
  }
  .referral-banner-subtitle{
   
    font-size: 14px;
  }
  .referral-section{
    flex-direction: column;

  }
  .reward-tab{
    font-size: 14px;
  }
  .referral-team{
    flex-direction: column;
  }
}

@media(max-width:900px){

  .referral-banner{
    
    grid-template-columns: auto;
    row-gap: 10px;
    
  }
  /* .referral-banner-item:first-child{
    flex-direction: column-reverse;
    
  }
  .referral-banner-item:last-child{
    flex-direction: column;
    
  } */
  .referral-banner-info,
  .referral-banner-info:last-child{
    padding: 30px  !important;
    text-align: center !important;
  }
  /* .referral-banner-item img.referral-dekstop {
    display: none;
  } */
  .referral-banner-item:before {
    display: none;

  }
  .referral-banner-item img {
    display: block;
    width: 80%;
    margin: 0 auto;
  }
  .referral-banner-title{
   
    font-size: 16px;
  }
  .referral-banner-subtitle{
   
    font-size: 14px;
  }
  .referral-section{
    flex-direction: column;

  }
  .referral-team{
   flex-direction: column;
  }
  .referral-msg-title{
    font-size: 16px;
  }
  .referral-share{
    flex-direction: column;
    align-items: start;
   }

   .referral-row{
    grid-template-columns: auto;
   }

   .claim-comm-dekstop{
    display: none;
  }
  .claim-comm-mobile{
    display: block;
  }
  .reward-section{
    padding: 20px 6px 23px;
  }
  .reward-tab button.active{
    padding: 10px;
  }
 
}

@media (max-width:760px){
  .reward-tab{
    display: flex;
    justify-content: space-between;
  }
  .reward-tab button{
    flex: 0 0 50%;
    padding:10px;
  }
  .referral-banner-item:first-child{
    flex-direction: column-reverse;
    
  }
  .referral-banner-item:last-child{
    flex-direction: column;
    
  }
  .referral-banner-item img {
    width: 60%;
  }

}

.bank-option-list{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.bank-selected{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bank-option-selected{
  display: flex;
  align-items: center;
  gap: 10px;
  background: transparent;
  min-width: 155px;
  cursor: pointer;
}
.bank-option-box{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 10px;
  background: var(--deposit-tab-button);
  border-radius: 8px;
  min-width: 155px;
  cursor: pointer;
  border: 1px solid transparent;
}

.bank-option-box.selected,
.bank-option-box:hover{
  border-color: var(--modal-header);
  background: var(--deposit-tab-button-active) !important;
}

.bank-option-img{
  height: 30px;
  width: 30px;
  background: #333333;
  border-radius: 5px;
  overflow: hidden;
}
.bank-option-img img{
  width: 100%;
  object-fit: contain;
  object-position: center;
}
.bank-option-info{
  font-size: 12px;
}
.bank-option-name{
  color: #fff;
  font-weight: 600;
}
.bank-option-minmax{
  color: #878787

}

.bank-selection{
  background: var(--modal-container);
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 20px;
}
