#banner-apk {
  position: fixed;
  left: 0;
  bottom: 50px;
  transform: translate(0, 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  cursor: pointer;
}

.banner-apk-icon {
  width: 485px;
  height: 95px;
}

@media only screen and (max-width: 900px) {
  #banner-apk  {
    /* right: 0; */
    bottom: 100px;
    /* left:auto; */
  }
  .banner-apk-icon {
    width: 300px;
    height: 60px;
  }
}

.banner-apk-icon svg {
  width: 100%;
}

.banner-apk-close {
  width:15px;
  height:15px;
  color: white;
  text-align: center;
  position: absolute;
  top: 1px;
  right: 5px;
  transform: translate(0, 0);
}

@media (max-width: 900px) {
  #banner-apk {
    bottom: 110px;
  }
}

.mobile-apk-banner {
  position: fixed;
  top: 56px;
  left: 0;
  width: 100%;
  background: var(--apk-banner-mobile-bg);
  color: var(--apk-banner-mobile-bg-color);
  padding: 5px 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 887;
  padding-right: 30px;
}

.mobile-apk-banner .banner-left {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}

.mobile-apk-banner .banner-left .img {
  width: 30px;
  height: 30px;
}

.mobile-apk-banner .banner-left .txt-top{
  font-weight: 700;
  font-size: 10px;
}


.mobile-apk-banner .banner-left .txt-btm{
  font-weight: 500;
  font-size: 10px;
}

.mobile-apk-banner .download-button {
  display: flex;
  column-gap: 5px;
  align-items: center;
  background: var(--apk-banner-button);
  color: var(--apk-banner-color);
  border-radius: 0.5rem;
  padding: 8px 12px;
  cursor:pointer;
  font-size: 12px;
}

.mobile-apk-banner .download-button .img {
  width: 14px;
  height: 14px;
}


.close-button {
  background: none;
  border: none;
  color: var(--apk-banner-mobile-close);
  font-size: 20px;
  cursor: pointer;
  padding:5px !important;
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.close-button .img{
width:15px;
height:15px;
}

